import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popInfo',
  templateUrl: './popInfo.component.html',
  styleUrls: ['./popInfo.component.scss']
})
export class PopInfoComponent implements OnInit {

   
  constructor(
    private dialogRef: MatDialogRef<PopInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {
    
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
