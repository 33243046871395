import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from 'app/features/integration/serviceIntegration/services/product.service';
import { DialogService } from 'app/_services/dialog.service';

@Component({
  selector: 'app-patientModal',
  templateUrl: './patientModal.component.html',
  styleUrls: ['./patientModal.component.scss']
})
export class PatientModalComponent implements OnInit {
 


   
  constructor(
    private productService: DialogService ,
    private dialogRef: MatDialogRef<PatientModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {
 
  }


  onNoClick(): void {
 

  }


}
