import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalService {


private locale$ = new BehaviorSubject<string>('');
selectedlocale$ = this.locale$.asObservable();

private info$ = new BehaviorSubject<string>('');
selectedInfo$ = this.info$.asObservable();
constructor() {}

setLocal(locale: any) {
  this.locale$.next(locale);
}

setInfo( info :any){
    this.info$.next(info);
 }


}
