import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popMeeting',
  templateUrl: './popMeeting.component.html',
  styleUrls: ['./popMeeting.component.scss']
})
export class PopMeetingComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  {mode: string; item : any;action:string ;type:string ;countries: any},
  ) {}

  ngOnInit(): void {    
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  
  
}
