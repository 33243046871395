import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productUrl = `${environment.apiUrl}back/product`;
  private packtUrl = `${environment.apiUrl}back/pack`;

  private backUrl = `${environment.apiUrl}back`;


  constructor(private http: HttpClient) {
  }

  getPrices(mode, code): Observable<any> {

    return this.http.get<any>(`${this.backUrl}/${mode}/${code}/prices`, {});
  }

  getTerms(mode, code): Observable<any> {

    return this.http.get<any>(`${this.backUrl}/${mode}/${code}/termconditions`, {});
  }


  postTerms(mode, code, data): Observable<any> {

    return this.http.post<any>(`${this.backUrl}/${mode}/${code}/termCondition`, data);
  }



  addPrice(mode, code, params): Observable<any> {

    return this.http.post<any>(`${this.backUrl}/${mode}/${code}/price`, params);
  }

  addService(mode, params) {
    return this.http.post<any>(`${this.backUrl}/${mode}`, params);

  }



  getService(code, mode): Observable<any> {
    if (mode === 'pack') {
      return this.http.get<any>(`${this.packtUrl}/${code}`, {});
    } else {
      return this.http.get<any>(`${this.productUrl}/${code}`);
    }
  }

  getProductList(params?): Observable<any> {
    return this.http.get<any>(`${this.productUrl}s`, { params });
  }

  getProductListChoice(): Observable<any> {
    return this.http.get<any>(`${this.productUrl}s/choice`);
  }

  getPackList(params?): Observable<any> {
    return this.http.get<any>(`${this.packtUrl}s`, { params });
  }

  updateService(mode, params , code) {
    return this.http.put<any>(`${this.backUrl}/${mode}/${code}`, params);
  }


}
