import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class JobOffersService {

    private jobOfferUrl = `${environment.apiUrl}back/joboffer`;
    private statusUrl = `${environment.apiUrl}back`;

    constructor(private http: HttpClient) {
    }

    getJobOffers(params): Observable<any> {
        return this.http.get<any>(`${this.jobOfferUrl}s`, {params});
    }

    // getPartnerList(): Observable<any> {
    //     return this.http.get<any>(`${this.statusUrl}/partners/choice`, {});
    // }

    
    deleteJobOffers(code): Observable<any> {
        return this.http.delete<any>(`${this.jobOfferUrl}/${code}`, {});

    }

    getDomains(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/domains`, {});
    }

    getDomainsGroup(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}domains/group`, {});
    }

    GetOneJobOffers(code , mode?): Observable<any> {
        if (mode === 'edit') {
            return this.http.get<any>(`${this.jobOfferUrl}/${code}?edit=1`, {});
        }
        else{
            return this.http.get<any>(`${this.jobOfferUrl}/${code}`, {});
        }
    }

    copyJobOffers(idOffre): Observable<any> {
        return this.http.patch<any>(`${this.jobOfferUrl}/${idOffre}/copy`, {} );

    }

    publishJobOffre(idOffre): Observable<any> {
        return this.http.patch<any>(`${this.jobOfferUrl}/${idOffre}/publish`, {} );

    }

    unpublishJobOffre(idOffre , payload): Observable<any> {
        return this.http.patch<any>(`${this.jobOfferUrl}/${idOffre}/unpublish`, payload );
    }

    makeExpiredJobOffre(idOffre ): Observable<any> {
        return this.http.put<any>(`${this.jobOfferUrl}/${idOffre}/expired` , {} );
    }


    GetJoboffersStatus(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/offerStatus`, {});
    }

    GetJobtype(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/jobtypes/choice`, {});
    }

    GetRecognition(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/recoginitionStatus`, {});
    }

    GetDegrees(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/degrees/choice`, {});
    }

    GetExperience(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/experiences/choice`, {});
    }

    GetLanguages(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/languages/choice`, {});
    }

    GetLanguagesLavel(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/languagelevels`, {});
    }

    GetWorktime(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/worktime`, {});
    }

    GetCities(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/cities/choice`, {});
    }

    GetCountries(): Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/countries/choice`, {});
    }

    AddNewOffre(object): Observable<any>  {
        
        return this.http.post<any>(`${this.jobOfferUrl}`, object);

    }

    editOffre(codeJobOffre , data): Observable<any>  {
        return this.http.put<any>(`${this.jobOfferUrl}/${codeJobOffre}`, data);
    }


    getCandidates( payload){        
        return this.http.get<any>(`${this.jobOfferUrl}/${payload.codeJob}/candidates/${payload.status}`);
    }

    proposeCandidate( jobOffre ,candidate) {
        return this.http.put<any>(`${this.jobOfferUrl}/${jobOffre}/candidate/${candidate}`,{});
 
      }

      cancelProposed(codeProp , commentary) {
            return this.http.delete<any>(`${this.statusUrl}/proposition/${codeProp}?commentary=${commentary}`);

      }

      getCandidatesListChoice(code): Observable<any> {        
        return this.http.get<any>(`${this.statusUrl}/candidates/search?joboffer=${code}`,{});
      }

      cancelJobOffre(code) {
        return this.http.delete<any>(`${this.jobOfferUrl}/${code}`);
      }
 

    getCandidateByType(status , params): Observable<any> {
        //console.log(params);
        
        return this.http.get<any>(`${this.statusUrl}/${status}`,{params});
    } 


    getPropositioChoice() : Observable<any> {
        return this.http.get<any>(`${this.statusUrl}/propositionsJob`,{});
    }


}
