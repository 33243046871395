<div class="card jobs m-8 p-24" fxLayout="row"  >
  <div class="logo mr-20">
    <div class="name flex-sm-sm">
      <img [src]="card?.photo" (error)="onImgError($event)" alt="">
    </div>
  </div>
  <div  class="details " fxLayout="column">
    <div *ngIf="!from" [routerLink]="['/recrutment/datasheet/', card.id]" class="fullName ">
      <div class="text-bold">{{card?.alias}} </div>
      {{card?.candidate}}
    </div>
    <div *ngIf="from" [routerLink]="['/recrutmentPreparing/datasheet/', card.id]" class="fullName ">
      <div class="text-bold">{{card?.alias}} </div>
      {{card?.candidate}}
    </div>



    <span class="creator" *ngIf="!from"> <span class="text-bold"> {{'PARTNERS.PARTNER' | translate}} : </span> {{ card?.partner }}
    </span>

    <span class="creator" *ngIf="!from"> <span class="text-bold">{{'JOBOFFERS.JOBOFFER' | translate}} : </span> {{card?.partner}}
    </span>

    <span class="creator" > <span class="text-bold">{{'COMMUN.JOBTYPE' | translate}} : </span> {{card?.jobtype_label}}
    </span>


    <span class="creator" > <span class="text-bold">{{'COMMUN.RECRUTMENT_NAME' | translate}} : </span> {{card?.tpl_label}}
</span>




    <span class="creator"*ngIf="from" > <span class="text-bold">{{'COMMUN.STATUS' | translate}} : </span> {{card?.status}}
  </span>


 </div>
  <div class="btn-actions  " fxLayoutAlign=" end end">

    <a *ngIf="!from"  [routerLink]="['/recrutment/datasheet', card.id ]"><img src="./assets/icons/jobzioo-icons/eye.svg"
        class=" mr-4 cursor-pointer" alt=""> </a>

        <a *ngIf="from"  [routerLink]="['/recrutmentPreparing/datasheet', card.id ]"><img src="./assets/icons/jobzioo-icons/eye.svg"
          class=" mr-4 cursor-pointer" alt=""> </a>

  </div>

</div>
