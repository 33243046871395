<div class="group p-16 block-sm between"  >
  <div class="left-part block-sm " >
      <!-- <img  class="icon " [src]="'./assets/integration/flagTunis.svg'"   /> -->
      <div class="details flex-sm" >
          <a [routerLink]="['/team/datasheet', group.code , 'read']">
            <span class="title">  {{group?.firstname}}  {{group?.lastname}}</span>    
          </a>
        <!-- <span class="roles"> Group : <span class="role"> {{group?.group}}</span> </span> -->
        <!-- <span class="roles"> Email : <span class="role"> {{group?.mail}}</span> </span> -->
        <span class="date"> Created At:  {{returnDate(group.created_at)}}</span>
      </div>
    
  </div>
  <div class="right-part only-flex ">
   
    <div class="icon-action" >
      <a [routerLink]="['/team/datasheet', group.code , 'edit']">
        <img class="cursor-pointer" [src]="'./assets/integration/Desktop01/edit.svg'" />

      </a>

    </div>
    <mat-slide-toggle     [ngClass]="{'active': group.active , 'inactive' : !group.active}" 
    [checked]="group.active" (click)="changeState(group)"></mat-slide-toggle>
  </div>
</div>