<div class=" maria-integration text-center">
  <div mat-dialog-title [ngClass]="{'waring' :data.mode === 'patient'  }">
       {{'COMMUN.UPLOAD_MEDIA' | translate}}
    </div>


  <div mat-dialog-content  [ngClass]="{'waring' :data.mode === 'patient'  }">

      <img src="./assets/integration/Desktop01/Spinner.gif" alt="" srcset="">

     <p>{{data.msg | translate}} </p>

    </div>

    <!-- <div mat-dialog-actions>
      <button   mat-raised-button class="btn-mat  btn-sm  mb-16" 
       [ngClass]="{'popError' :data.mode === 'inactive' ,'popActive' :data.mode === 'active' }"
         [mat-dialog-close]="data" cdkFocusInitial   >
        {{'COMMUN.OK' | translate}}
      </button>

     
    </div> -->
   