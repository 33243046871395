import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersService } from 'app/features/integration/ordersIntegration/service/orders.service';
import { RecrutmentService } from 'app/features/integration/recrutment/recrutmentIntegration/services/recrutment.service';
import { PopApiComponent } from '../popApi/popApi.component';

@Component({
  selector: 'app-upladDocument',
  templateUrl: './upladDocument.component.html',
  styleUrls: ['./upladDocument.component.scss']
})
export class UpladDocumentComponent implements OnInit {
  atachmentFormGroup : FormGroup
  files=[]
  srcResult
  constructor(
    private _formBuilder : FormBuilder ,
    private serviceRecrutement: OrdersService ,
    private dialogRef: MatDialogRef<UpladDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _matDialog : MatDialog
  ) {}



  ngOnInit() {


  this.atachmentFormGroup = this._formBuilder.group({
    fileUrl  : ['', Validators.required],
    fileName  : ['', Validators.required],
  }
  );
  }


  onNoClick(): void {
    this.dialogRef.close();
  }



  fileName =''

  onFileChange(event )  {
    for  (var i =  0; i <  event.target.files.length; i++)  {
         //console.log(event.target.files[i].name);
        this.fileName = event.target.files[i].name
        this.files.push(event.target.files[i]);
    }
    //let fu1 = document.getElementById("FileUpload1").value;

    var filesAmount = event.target.files.length;
    for (let i = 0; i < filesAmount; i++) {
      var reader = new FileReader();
      reader.onload = (event:any) => {
           this.srcResult = event.target.result ;


      }
      reader.readAsDataURL(event.target.files[i]);
    }
    this.files.forEach(e => { this.atachmentFormGroup.value.fileUrl = e   ; this.data.document = e});
  }


}
