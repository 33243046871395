import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { JobZioIntegrationComponent } from './jobZioIntegration.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { JobOffreComponent } from './jobOffre/jobOffre.component';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PopSettingsComponent } from './popSettings/popSettings.component';
import { DomainComponent } from './domain/domain.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableSharedComponent } from './table-shared/table-shared.component';
import { EmptyDataComponent } from './emptyData/emptyData.component';
import { GroupComponent } from './group/group.component';
import { TeamComponent } from './team/team.component';
import { PartnerComponent } from './partner/partner.component';
import { PopInfoComponent } from './popInfo/popInfo.component';
import { PopMeetingComponent } from './popMeeting/popMeeting.component';
import { ProposeCandidateComponent } from './proposeCandidate/proposeCandidate.component';
import { NgSelect2Module } from 'ng-select2';

import { Select2Module } from 'ng-select2-component';
import { PopApiComponent } from './popApi/popApi.component';
import { PopVedioComponent } from './popVedio/popVedio.component';
import { GetCommentStepComponent } from './getCommentStep/getCommentStep.component';
import { PopPriceComponent } from './popPrice/popPrice.component';
import { StateDialogComponent } from './stateDialog/stateDialog.component';
import { UpladDocumentComponent } from './upladDocument/upladDocument.component';
import { PartnerSmComponent } from './partner-sm/partner-sm.component';
import { JobSmComponent } from './job-sm/job-sm.component';
import { CardRecrutmentComponent } from './card-recrutment/card-recrutment.component';
import { CardUserComponent } from './card-user/card-user.component';
import { CardCandidComponent } from './card-candid/card-candid.component';
import { CardWordComponent } from './card-word/card-word.component';
import { CardTermsComponent } from './card-terms/card-terms.component';
import { CardServiceComponent } from './card-service/card-service.component';
import { CardOrderComponent } from './card-order/card-order.component';
import { PatientModalComponent } from './patientModal/patientModal.component';
import { CvCandidateComponent } from './cvCandidate/cvCandidate.component';
import { MatChipsModule } from '@angular/material/chips';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Document_candiComponent } from './document_candi/document_candi.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    NgSelectModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    RouterModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    NgSelect2Module,
    Select2Module,
    MatChipsModule,
    PdfViewerModule
  ],
  declarations: [
    Document_candiComponent, CvCandidateComponent, PatientModalComponent, CardOrderComponent, CardServiceComponent, CardTermsComponent, CardWordComponent, CardCandidComponent, CardUserComponent, PartnerSmComponent, JobSmComponent, CardRecrutmentComponent,
    UpladDocumentComponent, StateDialogComponent, PopPriceComponent, GetCommentStepComponent, PopVedioComponent, PopApiComponent, ProposeCandidateComponent, PopInfoComponent, PopMeetingComponent, JobZioIntegrationComponent, PartnerComponent, TeamComponent, EmptyDataComponent, EditGroupComponent, JobOffreComponent, SettingsComponent, PopSettingsComponent, DomainComponent, TableSharedComponent, GroupComponent],
  exports: [Document_candiComponent, CvCandidateComponent, PatientModalComponent, CardOrderComponent, CardServiceComponent, CardTermsComponent, CardWordComponent, CardCandidComponent, CardUserComponent, CardRecrutmentComponent, JobSmComponent, PartnerSmComponent, UpladDocumentComponent, StateDialogComponent, PopPriceComponent, GetCommentStepComponent, PopVedioComponent, PopApiComponent, ProposeCandidateComponent, PopInfoComponent, PopMeetingComponent, EditGroupComponent, TeamComponent, PartnerComponent, JobOffreComponent, EmptyDataComponent, SettingsComponent, PopSettingsComponent, DomainComponent, TableSharedComponent, GroupComponent],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
  ]

})
export class JobZioIntegrationModule { }
