import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class CandidateService {

    private candidatesUrl = `${environment.apiUrl}back/candidate`;
    private backUrl = `${environment.apiUrl}back`;


    constructor(private http: HttpClient) {
    }


    hide(codeCandi, mode, data): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandi}/${mode}/${data}/state`, {});
    }

    submitCV(codeCandi): Observable<any> {
        return this.http.put<any>(`${this.candidatesUrl}/${codeCandi}/pending`, {});
    }


    addItem(codeCandi, mode, data): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${codeCandi}/${mode}`, data);
    }

    putItem(mode, codeCandi, id, data): Observable<any> {
        return this.http.put<any>(`${this.candidatesUrl}/${codeCandi}/${mode}/${id}`, data);
    }

    updateProof(codeCandi, mode, id, data): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${codeCandi}/${mode}/${id}/proof`, data);
    }

    deleted(codeCandi, mode, id): Observable<any> {
        return this.http.delete<any>(`${this.candidatesUrl}/${codeCandi}/${mode}/${id}`);
    }



    deleteAttachment(documentType, code): Observable<any> {
        return this.http.delete<any>(`${this.candidatesUrl}/${code}/attachement/${documentType}`);
    }

    getItem(codeCandi, mode, code?): Observable<any> {
        if (code) {
            return this.http.get<any>(`${this.candidatesUrl}/${codeCandi}/${mode}/${code}`);
        } else {
            // //console.log('vvvv');
            if (mode === 'educationexperience') {
                return this.http.get<any>(`${this.candidatesUrl}/${codeCandi}/educationsexperiences`);
            } else {
                return this.http.get<any>(`${this.candidatesUrl}/${codeCandi}/${mode}s`);
            }


        }
    }


    add_to_prepring(userCode, codePreparing) {
        return this.http.post<any>(`${this.backUrl}/preparing/candidate/${userCode}/process/${codePreparing}`, {});

    }

    declineCand(userCode, payload) {
        return this.http.patch<any>(`${this.candidatesUrl}/${userCode}/decline?commentary=${payload}`, {});
    }


    acceptCandi(userCode) {
        return this.http.patch<any>(`${this.candidatesUrl}/${userCode}/accept`, {});
    }



    downloadCV(code) {
        return this.http.patch<any>(`${this.candidatesUrl}/${code}/cv/download`, {});
    }

    getRasonsList(code): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/reason`, {});
    }

    getCandidates(params): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}s`, { params });
    }

    getModeComment(codeCandid, mode: string, codeItem: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${codeCandid}/${mode}/${codeItem}/comments`, {});
    }


    addCandidate(data: FormData): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}`, data);
    }

    editCandidate(data, code): Observable<any> {
        return this.http.put<any>(`${this.candidatesUrl}/${code}`, data);
    }

    createLinkCandidate(data, code): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${code}/link`, data);
    }

    updateAttachment(documentType, code, data): Observable<any> {
        ///candidate/{code}/attachement/{documentType} POST , Back office
        // attachement/{documentType}

        return this.http.post<any>(`${this.candidatesUrl}/${code}/attachement/${documentType}`, data);
    }

    getCandidateInfo(code: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}`, {});
    }


    getCandidateCV(code: string, mode?): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/${mode}`, {});
    }

    getCandidateCertification(code: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/certification`, {});
    }

    getCandidateCertificationComment(code: string, codeCertif: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/certification/${codeCertif}`, {});
    }

    declineReason(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/reasons`, {});
    }

    DeclinedCandidateCertification(codeCandidate: string, codeCertification: string, data: any): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${codeCandidate}/certification/${codeCertification}/decline`, data);
    }

    AcceptCandidateCertification(codeCandidate: string, codeCertification: string): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandidate}/certification/${codeCertification}/accept`, {});
    }

    AcceptCandidateEducationExperience(codeCandidate: string, codeExperience: string): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandidate}/educationexperience/${codeExperience}/accept`, {});
    }

    AcceptCandidateWorkExperience(codeCandidate: string, codeExperience: string, mode): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandidate}/${mode}/${codeExperience}/accept`, {});
    }

    DeclinedCandidateExperience(codeCandidate: string, codeExperience: string, data: any): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${codeCandidate}/educationexperience/${codeExperience}/decline`, data);
    }

    AcceptCandidateLanguage(codeCandidate: string, codeExperience: string): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandidate}/language/${codeExperience}/accept`, {});
    }

    DeclinedCandidateWorkExperience(codeCandidate: string, codeExperience: string, data: any): Observable<any> {
        return this.http.post<any>(`${this.candidatesUrl}/${codeCandidate}/workexperience/${codeExperience}/decline`, data);
    }




    getCandidateEducationExperienceComment(code: string, codeExperience: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/educationexperience/${codeExperience}/comments`, {});
    }

    getCandidateCVComment(code: string, codeExperience: string, itemName: string): Observable<any> {
        return this.http.get<any>(`${this.candidatesUrl}/${code}/${itemName}/${codeExperience}/comments`, {});
    }


    acceptCandidate(codeCondidate, action, description): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCondidate}/${action}`, { description: description });
    }

    getJobOffreByCandidate(codeCandidate, state) {
        return this.http.get<any>(`${this.candidatesUrl}/${codeCandidate}/joboffers/${state}`);
    }

    acceptOrDeclineCV(codeCandidate, mode, data?) {
        //console.log(codeCandidate,mode  , data);
        //  const payload = {
        //     commentary: data ,
        // };
        if (mode === 'accept') {
            return this.http.put<any>(`${this.candidatesUrl}/${codeCandidate}/cv/${mode}`, data);
        }
        if (mode === 'decline') {
            return this.http.post<any>(`${this.candidatesUrl}/${codeCandidate}/cv/${mode}`, data);
        }
    }


    getCandidateByType(status) {
        return this.http.get<any>(`${this.backUrl}/${status}`, {});
    }

    getCreateRecruiting(codeRec, codeStep, params) {
        return this.http.post<any>(`${this.backUrl}/recruiting/${codeRec}/step/${codeStep}`, { params });
    }



    getHistoryCandidate(codeCondidate) {
        return this.http.get<any>(`${this.candidatesUrl}/${codeCondidate}/history`, {});
    }

    getCandidatePurchase(codeCondidate) {
        return this.http.get<any>(`${this.candidatesUrl}/${codeCondidate}/purchases`, {});
    }


    acceptItem(codeCandidate: string, type, code: string, mode): Observable<any> {
        return this.http.patch<any>(`${this.candidatesUrl}/${codeCandidate}/${type}/${code}/${mode}`, {});
    }

    DeclinedCVItem(codeCandidate, codeExperience: string, itemName: string, payload) {
        //console.log(payload);

        return this.http.post<any>(`${this.candidatesUrl}/${codeCandidate}/${itemName}/${codeExperience}/decline`, payload);

    }


}
