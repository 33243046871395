import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private backUrl = `${environment.apiUrl}back/purchases`;
  private statusUrl = `${environment.apiUrl}back`;

  constructor(private http: HttpClient) {
  }


      getOrderList(params?): Observable<any>{
        //console.log(params);
        
          return this.http.get<any>(`${this.backUrl}`, {params});
      } 

      getOrder(code): Observable<any>{
        return this.http.get<any>(`${this.statusUrl}/purchase/${code}`, {});
      } 

      acceptProof(code , status?) : Observable<any>{
        if(!status){
          return this.http.post<any>(`${this.statusUrl}/purchase/${code}`, {});
        }
        if(status) {
          return this.http.post<any>(`${this.statusUrl}/purchase/${code}/${status}`, {});

        }

      }
      getHistoryOrdersByCandd(code , params?): Observable<any> {          
        return this.http.get<any>(`${this.statusUrl}/candidate/${code}/purchases`, params);
      }

      addDocument(code , payload){
        return this.http.post<any>(`${this.statusUrl}/purchase/${code}/document`, payload);
      } 


      finishOrder(code ){
        return this.http.patch<any>(`${this.statusUrl}/purchase/${code}/finished` , {});
      }


}
