import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [


    {
        id       : 'dashboard',
        title    : 'dashboard',
        translate: 'COMMUN.DASHBOARD',
        type     : 'item',
        icon     : './assets/integration/navbar/dashboard.svg',
        classes :'group',
        url      : '/',

    },

    {
        id       : 'partners',
        title    : 'partners',
        translate: 'PARTNERS.PARTNERS',
        type     : 'item',
        icon     : './assets/icons/jobzioo-icons/partners.svg',
        classes :'group',
        url      : '/partners',

    },


    {
        id: 'candidate',
        title: 'candidate',
        translate: 'CANDIDATE.CONDIDATES',
        type: 'collapsable',
        icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
        classes :'group',
      //  url      : '/candidates',
          children : [
                    {
                        id   : 'analytics',
                        title: 'CANDIDATE.MY_CONDIDATES',
                        type : 'item',
                        url  : '/candidates/my-candidates' ,
                    },
                    {
                        id   : 'analytics',
                        title: 'CANDIDATE.LEADS',
                        type : 'item',
                        url  : '/candidates/leads' ,

                    },
                ]

    },
    {
        id       : 'jobOffer',
        title    : 'joboffer',
        translate: 'JOBOFFERS.JOBOFFERS',
        type     : 'item',
        icon     : './assets/icons/jobzioo-icons/job-offers.svg',
        classes :'group',
        url      : '/joboffers',

    },



    { //recruitings
        id: 'services',
        title: 'services',
        translate: 'SERVICES.SERVICES',
        type: 'item',
        icon     : './assets/integration/navbar/pack.svg',
        classes :'group',
        url      : '/services',

    },


    {
        id       : 'orders',
        title    : 'orders',
        translate: 'ORDERS.ORDERS',
        type     : 'item',
        icon     : './assets/integration/Desktop57/order.svg',
        classes :'group',
        url      : '/orders',

    },


    {
        id: 'recruitings',
        title: 'recruitings',
        translate: 'CANDIDATE.RECRUTINGS',
        type: 'collapsable',
        icon     : './assets/icons/jobzioo-icons/Experiences-list.svg',
        classes :'group',
        //url      : '/recrutment',
        children : [
            {
                id   : 'analytics',
                title: 'CANDIDATE.RECRUTING',
                type : 'item',
                url  : '/recrutment' ,
            },
            {
                id   : 'analytics',
                title: 'CANDIDATE.DOSSIER_PREPARING',
                type : 'item',
                url  : '/recrutment/preparing' ,
            },
        ]
    },


    // {
    //     id: 'recrutmentPreparing',
    //     title: 'recruitingsPreparing',
    //     translate: 'CANDIDATE.RECRUTING_PREPARING',
    //     type: 'item',
    //     icon     : './assets/icons/jobzioo-icons/Experiences-list.svg',
    //     classes :'group',
    //     url      : '/recrutmentPreparing',

    // },


    {
        id: 'workflowsRec',
        title: 'workflows',
        translate:'WORKFLOWS.WORKFLOWS',
        type: 'collapsable',
        icon     : './assets/integration/navbar/workFlow.svg',
        classes :'group',
     //   url      : '/workflows',
        children : [
            {
                id   : 'analytics',
                title: 'WORKFLOWS.WORKFLOWS_RECRUTMENT',
                type : 'item',
                url  : '/workflows/workflowsRecruitment' ,
            },
            {
                id   : 'analytics',
                title: 'WORKFLOWS.WORKFLOWS_PREPARING',
                type : 'item',
                url  : '/workflows/preparing' ,
            },
        ]
    },


    // {
    //     id: 'workflowsPreparing',
    //     title: 'workflowsPreparing',
    //     translate:'WORKFLOWS.WORKFLOWS_PREPARING',
    //     type: 'item',
    //     icon     : './assets/integration/navbar/workFlow.svg',
    //     classes :'group',
    //     url      : '/workflowsPreparing',

    // },


    {
        id       : 'groups',
        title    : 'Groups',
        translate: 'GROUPS.GROUPS',
        type     : 'item',
        url      : '/groups',
        icon     : './assets/integration/navbar/team-group.svg',
        classes :'group'
    },


    {
        id       : 'team',
        title    : 'team',
        translate: 'PARTNERS.USERS',
        type     : 'item',
        url      : '/team',
        icon     : './assets/icons/jobzioo-icons/team.svg',
         classes :'group'
    },



    {
        id       : 'settings',
        title    : 'settings',
        translate: 'SETTINGS.SETTINGS',
        type     : 'item',
        icon     : './assets/integration/navbar/setting.svg',
        classes :'group',
        url      : '/settings',

    },

    {
        id       : 'wording',
        title    : 'wording',
        translate: 'SETTINGS.WORDING',
        type     : 'item',
        icon     : './assets/integration/navbar/word.svg',
        classes :'wording',
        url      : '/wording',

    },









];
