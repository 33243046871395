
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import {catchError, map, retry} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: localStorage.getItem('jwt')
  })
};

@Injectable({
  providedIn: 'root'
})
export class LOCAL_ASSISTANCEAuthService  {

  jwtHelper = new JwtHelperService()
   role =""
  currentUseer ;
  constructor(
      private router: Router
  ) { 

   
  }



  canActivate() {
  const role =   localStorage.getItem('role');

   
      if ( role !== "LOCAL_ASSISTANCE") {                 
          return true;
      }

      
      else {        
          this.router.navigate(['']);
          return false;
      }
  }

}
