
export const locale = {
    lang: 'en',
    data: {
        ICON: {
            REFRESH: 'Refresh',
            ADD_USER: 'Add user',
            EDIT_USER: 'Edit user',
            VIEW_USER: 'User details',
            VIEW_RECRUITING: 'Recruitment details',
            VIEW_JOS: 'Offer details',
            VIEW_JO: 'Offer details',
            COPY_JO: "Copy offer",
            EXPIRE_JO: "Expire offer",
            CANCEL_JO: "Cancel offer",
            EDIT_JO: "Edit offer",
            ADD_WHISHLIST: 'Add to wishlist',
            REMOVE_WHISHLIST: 'Remove from wishlist',
            INVITE_CANDIDATE: 'Invite candidate',
            CANCEL_INVITATION: 'Cancel invitation',
            VIEW_CANDIDATE: 'Candidate Details',
            VIEW_COMMENT: 'View comment',
            APPROVE_DOCUMENT: 'Approve document',
            DELETE_DOCUMENT: 'Delete document',
            ADD_DOCUMENT: 'Add document',
            ACCEPT_APPLICATION: 'Accept application',
            DECLINE_APPLICATION: 'Reject application',
            ACCEPT_PROPOSITION: 'Accept proposal',
            DECLINE_PROPOSITION: 'Reject proposal',
            ACCEPT_MATCH: 'Accept match',
            DECLINE_MATCH: 'Reject match',
            ACCEPT_INTERVIEW: 'Accept interview',
            DECLINE_INTERVIEW: 'Reject interview',
            ADD_EXPERIENCE: 'Add experience',
            DELETE_EXPERIENCE: 'Delete experience',
            ADD_LANGUAGE: 'Add language',
            DELETE_LANGUAGE: 'Delete language',
            ADD_BENEFITS: 'Add benefits',
            DELETE_BENEFITS: 'Delete benefits',
            UNPUBLISH_JO: "Unpublish job",
            PUBLISH_JO: "Publish Job",
        },
        COMMUN: {
            SURE_DECLINE_MATCH: 'Do you confirm that you want to decline?',
            RECRUTMENT: "Recruitment",
            NO_SPECIALTY: "No speciality",
            CANT_BLANK: "This field can't be blank",
            COLOR: 'Color',
            CURRENCY: 'Currency',
            ERROR_AUTH: 'Your username or password are invalid.',
            PREVIOUS: 'Previous',
            CURRENT: 'Current',
            EMPLOYER: "Employees",
            BROSO_LOG0: 'Choose Logo',
            BROSO_IMG: 'Choose Image',
            MY_JOBZIOO: 'My Jobzioo',
            ERROR_DATE: `Start date can't be less then the end date`,
            EDIT_CV: 'Edit CV',
            SELECT_PREPARING_DOSSIER: 'Assign the corresponding preparing dossier ',
            RADIUS: 'Perimeter',

            //  EMTY_CITY:'no city' ,
            EMTY_CITY: 'no city ( type 3 characters then enter ) ',

            /////////////////////////////
            FEMALE: 'Female',
            MALE: 'Male',
            SIGNATURE: 'Signature',
            PASSPORT: 'Passport',
            PRESENTATION: 'Presentation',
            IMG: 'Image',
            PROGRESS_UPLOAD: 'Uploading in progress',
            UPLOAD_MEDIA: 'Upload media',

            SINGLE: 'Single',
            MARIED: 'Married',
            FILE_MAX_SIZE: `Can't upload.The media size is more than 400 Mo`,



            /////////////////////////////

            BROSO_PRESENTATION: 'Upload your video presentation',
            BROSO_SIGNATURE: 'Upload your signature',
            BROSO_PHOTO: 'Upload photo',
            BROSO_PASSPORT: 'Upload your Passport',
            SEE_MORE: 'See More',
            ADD_CANDIDATE: 'Add candidate',
            VIDEO: 'Video',
            DOWNLOAD_CV: 'Download CV',
            MESSAGES: 'Message',
            PRESENTATION_TYPE: 'Presentation type',
            MAX_SIZE: 'Max size',
            UPLOAD_COMPANY: 'Upload your Company Logo',
            UPLOAD_SIGNATURE: 'Upload your signature',
            UPLOAD_PHOTO: 'Upload your photo',
            LOGO_TYPE: 'The Logo should be in PNG or Jpeg format ',
            SIGNATURE_TYPE: 'The signature should be in PNG or Jpeg format ',
            YOU_HAVNT_CANDIDATE: 'You have no candidates specialized in this field',
            PHOTO_TYPE: 'The photo should be in PNG or Jpeg format ',
            UPLOAD_PASSPORT: 'Upload your Passport',
            RETURN_LOGIN: 'Return to Login page',
            TOTAL_RECRUTMENTS: 'Total Recruitment',
            OF: 'Of',
            AT: 'At',
            SHOW_DOCUMENT: "View document",
            ON: 'On',
            STATEE: 'State',

            YOUR_EMAIL: 'Enter your email',
            ADD_NEW_PARTNER: 'Add new partner',
            SEND: 'Send',
            FORGET_PASSWORD: 'I forget my Password',
            TERMS_CONDITIONS: 'Terms & Conditions',
            YOU_CAN_NOT_USE: 'Access denied to My Jobzioo Platform, please accept the Terms & Conditions to continue',
            APPROVED_LOGG: `You haven't been approved yet, you can't log in to My Jobzioo platform`,
            SURE_DECLINE_FROM_CV: ' Do you confirm declining this section from the CV? ',
            SURE_DELETE_FROM_CV: ' Do you confirm deleting this section from the CV ? ',
            SURE_HIDE_FROM_CV: ' Do you confirm hiding this section from the CV ? ',
            SURE_SHOW_IN_CV: ' Do you confirm showing this section in the CV ? ',
            SURE_DECLINE_CV: ' Do you confirm declining this CV ? ',
            SURE_ACCEPT_CV: ' Do you confirm accepting this CV ? ',
            SURE_ABLE_STEP: ' Do you confirm enabling this step ? ',
            SURE_DISABLE_STEP: ' Do you confirm disabling this step ? ',
            SURE_ADD_TO_PREPARING: ' Do you confirm adding this to preparing recruitment? ',
            FINISH_ORDER: 'Do you confirm finishing this order ? ',
            THANKS_VERIVICATION: 'Your account is now verified ,thank you for joining us. Welcome to My Jobzioo!',
            SURE_FINISH: 'Do you confirm finishing this recruitment ? ',
            VERIFICATION_ACCOUNT: 'Please verify your account',
            I_ACCEPT_TERMS: 'I agree',
            EXIST: 'Exist Service',
            UNDERSTOOd: 'Understood',
            SUBMIT_CV: 'Submit CV',
            FAVORIS_JOBS: 'Favorite Jobs',
            MATCH_JOBS: 'Matched Jobs',
            APPLY_JOBS: 'Applied Jobs',
            APPLY_JOB: 'Apply to Job',
            SUBJECT: 'Subject',
            CONTUNUE: 'Continue',
            BROSE: 'Browse',
            SELECT_JOB_OFFER: 'Select Job offer',
            FINISH: 'Finish',
            CHECK_YOUR_APPOINTMENT: 'Please check your appointment',
            CHECK: 'Check',
            UPLOAD: 'Upload',
            UPLOAD_DOCUMENTS: 'Upload documents',
            UPLOAD_DOCUMENT: 'Upload document',
            CHANGE_ATTACHMENT: 'Change Attachment',
            DASHBOARD: 'Dashboard',
            SELCTE_INTERRVIEW_DATE: 'Please select a date for the interview',
            SUR_PUBLISH: 'Do you confirm publishing this job Offer?',
            SURE_PORCHASE_ORDER: 'Do you confirm purchasing this order? ',
            SURE_APPLIED_JOB: 'Do you confirm applying to this job Offer? ',
            SURE_CANCEL_APPLICATION: 'Do you confirm canceling this application? ',

            SURE_ACCEPT_PROPOSITION: 'Do you confirm accepting this proposition?',
            SURE_DECLINE_PROPOSITION: 'Do you confirm declining this proposition?',

            SURE_DECLINE_INTERVIEWD: 'Do you confirm declining this interview?',
            SURE_ACCEPT_INTERVIEWD: 'Do you confirm accepting this interview? ',
            SURE_DELETE_DOCUMENT: 'Do you confirm deleting this document? ',
            SURE_CONFIRM_DOCUMENT: 'Do you confirm this document?',
            SURE_CANCEL_INVITATION: 'Do you confirm canceling this invitation? ',
            SURE_CANCEL_ORDER: 'Do you confirm canceling this order? ',
            SURE_ACCEPT_APPLICATION: 'Do you confirm accepting this application? ',
            SURE_DECLINE_APPLICATION: 'Do you confirm canceling this application? ',
            SURE_RESET_PASSWORD: 'Do you confirm reseting your password? ',
            SUR_UNPUBLISH: 'Do you confirm unpublishing this job Offer? ',
            SURE_CANCEL_JOB: 'Do you confirm canceling this job Offer? ',
            SURE_EXPIRED_JOB: 'Do you confirm that this job Offer is expired? ',
            SURE_ACCEPT_INVITATION: 'Do you confirm accepting this invitation? ',
            SURE_RESET: 'Do you confirm reseting your password ?',
            SELECT_CANDIDATE: 'Please select a candidate',
            SELECT_NAME: 'Search',
            DOCUEMNT_BY_CANDIDATE: 'Documents provided by the candidate ',
            DOCUEMNT_BY_PARTNER: 'Documents provided by the partner',

            ADDWORKFLOW: 'Add a new workflow',
            HI: 'Hi',
            TITLE: 'Title',
            THE_COMMENTS: 'The comments',
            MEET_WITH: 'Meeting with',
            ALIAS: 'Alias',
            ALIAS_JOBOFFRE: 'Alias job Offer',
            INVITEDBY: 'Invited By',
            APPLIED_JOBS: 'Applied Jobs',
            ALIAS_PARTNER: 'Alias partner',
            CHOOSE_DATE: 'Please choose a date & time',
            ALIAS_CANDIDATE: 'Alias candidate',
            YOU_HAVE_WORK: "It`s a lot of work for today! So, let`s start.",
            YOU_HAVE_NOTI1: "You have",
            YOU_HAVE_NOTI2: "New notification",
            FILL: 'Fill Now',
            FILL_PLEASE: 'Please fill your CV with the needed info and documents',
            NEW_PARTNERS: 'New Partners',
            NEW_JOBS: 'New Jobs',
            CREATED_AT: 'Created at',
            NEW_CANDIDATE: 'New Candidates',
            NEW_RECRUTMENT: 'New Recruitment',
            NEEDS_BE_VIEWD: 'Needs to be viewed',
            TOTAL_PARTNER: 'Total partners',
            TOTAL_JOBS: 'Total jobs',
            TOTAL_JOBS_OFFRES: 'Total Job offers',
            TOTAL_INTERVIEWD: 'Total interviews',
            TOTAL_PUBLISHED_JOBS: 'Total published jobs',
            FAVORIS_JOB: 'Favorite Jobs',
            APPLIED_JOB: 'Applied Jobs',
            PROPOSED_JOBS: 'Proposed Jobs',
            TOTAL_MATCHED_JOB: 'Total matched Jobs',
            TOTAL_PENDING_JOBS: 'Total pending jobs',
            TOTAL_APPLIED: 'Total Applied',
            TOTAL_MATCHED: 'Total matched profiles',
            TOTAL_CANDIDATE: 'Total candidates',
            INCOMMING_MEETING: 'Incomming meeting',
            VIEW_ALL: 'View all',
            PUBLISH: 'Publish Job',
            UNPUBLISH: 'Unpublish Job',
            APPOINTMENT: 'Appointment date',
            DATE: 'Date',
            STATELABELL: 'sµState Label',
            CONFIRM: 'Confirm',
            PRIVATE: 'Private',
            SHORTCODE: 'Short code',
            CREATE_ITEM: 'Create item',
            UPDATE_ITEM: 'Update item',
            MAKEARAISON: 'State a reason',
            RAISON: 'The reasons',
            MAKEACOMMENTARY: 'Write a comment',
            SEARCH: 'Search',
            ACCEPT: 'Accept',
            DECLINE: 'Decline',
            ACCEPT_CV: 'Accept CV',
            DECLINE_CV: 'Decline CV',
            APPROVED: 'Applications',
            APPROVE: 'Approve',
            BAN: 'Ban',
            CHECKED: 'Checked',
            DECLINED: 'Declined',
            CANCELED: 'Cancled',
            PENDING: 'Pending',
            UPDATE: 'Update',
            DELETE: 'Delete',
            ADD: 'Add',
            AGE: 'Age',
            FIRSTNAME: 'First Name',
            LASTNAME: 'Last Name',
            FULLNAME: 'Full Name',
            MAIL: 'Mail',
            MAIL_PARTNER: 'Mail of Partner',
            ADDRESS: 'Address',
            CITY: 'City',
            COUNTRY: 'country',
            YEARS: 'Years',
            SUBMITTEDDATE: 'Submission date',
            ZIPCODE: 'ZIP Code',
            ACTIVE: 'Enable',
            DESACTIVE: 'Disable',
            LOGO: 'Logo',
            LOGO_UPLOAD: 'Upload Logo',
            LOGIN: 'Login',
            REGISTER: 'Register',
            CREATE_ACCOUNT: 'Create Account',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm Password',
            PHONE: 'Phone',
            SPECIALITY: 'Specialty',
            Departement: 'Departement',
            CREATEDAT: 'Created At: ',
            EDIT: 'Edit ',
            SAVE: 'Save',
            UPDATE_PROOF: 'Update Proof',
            IDENTITY_VALIDATION: 'Identity Validation',
            NEW: 'New',
            AGENCY: 'Agencies',
            ACCEPTED: 'Accepted',
            GROUP: 'Group',
            PROFESSION: 'Professional Information',
            GENRALINFO: 'General Info',
            Email: 'Mail',
            Phone: 'Phone',
            PHOTO: 'Photo',
            address: 'Address',
            Contact: 'Contact',
            DOMAINE: 'Field of expertise',
            NATIONALITY: 'Nationality',
            GENDER: 'Gender',
            FULL_NAME: 'Full Name',
            GENERAL_INFO: 'General Info ',
            ExeperiencesEducation: 'Educational Exeperiences',
            Languages: 'Languages',
            JOBTYPE: 'Job Type ',
            ADDTEAM: 'Add Team',
            EDITTEAM: 'Edit Team',
            CONTACT: 'Contact',
            CLOSE: 'Close',
            COMMENT: 'Comment',
            YES: 'Yes, pretty sure',
            NO: 'No, not really ',
            DEPARTMENT: 'Department',
            GET_COMMENTS: 'Get Comments',
            MEDIA: 'Media',
            HISTORY: ' Candidate History',
            DATE_BEGIN: 'Date Begin',
            DATE_END: 'Date End',
            NUMBER_PRODUCT: 'Number of Product ',
            CANCEL: "Cancel",
            LABELL: "Label",
            STATE: "Status",
            DATE_VALIDITY: "Validity date",
            STATUS: "Status",
            POSITION: "Position",
            COMPANY: "Company",
            DOCUMENTS: "Documents",
            STEPS: "Steps",
            OTHERS: 'Others',
            TOTALMATCH: 'Total Matches',
            TOTALPUBLISH: 'Total Published Offers',
            SEND_EMAIL: 'Send Email',
            SHEDLE_MEETING: 'Schedule a meeting',
            NEWUSER: 'Create a new User',
            VIEW_CV: 'View CV',
            VIEW_VEDIO: 'View Video presentation',
            ATTACHEMENTS: 'Attachments',
            PARAGRAPHE: 'Connecting Future Talents',
            NEWTEAM: 'New Team',
            CHOOSELOGO: 'Choose Logo',
            CHOOSEFLAG: 'Choose Flag',
            CHOOSESIGNATURE: 'Choose Signature',
            CHOOSEPHOTO: 'Choose Photo',
            CHOOSEPRESENTATION: 'Choose Presentation',
            CHOOSEPASSPORT: 'Choose Passport',
            BIRTHDAY: 'Birthday',
            AGENCYNAME: 'Agency',
            OPTIONS: 'Options',
            RESET: 'Reset Password',
            OK: 'Ok',
            MARITALSTATUS: 'Marital Status',
            SHOWPROOF: 'Show Proof',
            INVITATION: 'Invitations',
            PROPOSITION: 'Proposition',
            APPLICATION: 'Application',
            INTERVIEWD: 'Interview',
            MATCHED: 'Matched',
            SURE_ABLE: 'Do you confirm enabling this ? ',
            SURE_INABLE: 'Do you confirm disabling this ? ',
            SHOW_APPOINMENT: 'Show Appointment',
            SURE_ACCEPT_CV_OF: 'Do you confirm accepting this CV?',
            SURE_DECLINE_CV_OF: 'Do you confirm declining this CV?',
            SURE_CANCEL_PROPOSE: 'Do you confirm canceling this proposition?',
            SURE_FINISH_STEP: 'Do you confirm finishing this step?',
            OBJECT_NOT_FOUND: 'Object not found',
            PARTNER_BANNED_REASONS: 'This Partner is banned due to the following reasons',
            CANDIDATE_BANNED_REASONS: 'This candidate is declined due to the following reasons',
            APPROVED_PARTNERS: 'Approved Partners',
            T0_APPROVE_PARTNERS: 'To Approve Partners',
            PUBLISH_JOB: 'Published Jobs',

            PROBLEM_NEWORK: 'Problem occurred. Please try again later!',
            CANT_CECYLCE: `Already used `,

            TO_APPROVE_PARTNERS: 'To Approve Partners',
            PRICE_BY_COUNTRY: "Prices Per Country",

            BY: 'By',
            SURE_DISABLE_FROM_TEAM: ' Do you confirm disabling this from team ? ',
            SURE_ABLE_FROM_TEAM: ' Do you confirm enabling this from team ? ',
            SURE_ACCCEPT_TO_CV: 'Do you confirm accepting this in CV?',
            SURE_BAN_IT: 'Do you confirm banning this partner?',
            SURE_APPROVE_IT: 'Do you confirm approving this partner?',
            SURE_DISABLE_IT: 'Do you confirm disabling this group?',
            SURE_ABLE_IT: 'Do you confirm enabling this group?',
            SUR_CANCEL_RECRUTMENT: 'Do you confirm canceling this recruitment?',
            SUR_CANCEL_CANDIDATE: 'Do you confirm canceling this candidate?',
            SUR_ACCEPT_CANDIDATE: 'Do you confirm accepting this candidate?',
            ADD_COMMENT: 'Add comment',
            ADD_PRICE_BY_COUNTRY: 'Add price by country',
            LIST_PRICE_BY_COUNTRY: 'List of price by country ',
            PRICE: 'Price',
            ADD_TO_PREPARING: 'Add to preparing',
            APPOIEMENT_DATE: 'Appointment Date',

            ADD_STEP: 'Add new step',
            UPDATE_STEP: 'Update step',

            DECLINE_PROOF: 'Do you confirm declining this Proof? ',
            ACCEPT_PROOF: 'Do you confirm accepting this Proof? ',


            WORKFLOW: 'Workflow',

            READ_MORE: 'Read more',

            BROSO_LOGO: 'Browse Logo',


            RESOLUTION: 'Resolution',

            YOU_HAVNT_JOB: 'Job offer unavailable in order to invite the candidate ',
            OPS: 'Ops',
            ADD_JOB: 'Add job',

            CREATE_NEW_WORKFLOW: "Create new Workflow",
            UPDATE_WORKFLOW: "Update Workflow"
        },
        JOBOFFERS: {
            SAVE_AS_DRAFT: 'Save as draft',
            JOBINFO: 'Job Offer Info',
            REQUIRMENTS: 'Requirements',
            REQUI: 'Requisitions',
            OPTIONS: 'Options',
            ADD_NEW: 'Add new Job Offer ',
            JOBOFFER: 'Job Offer ',
            INFO: 'Info',
            JOBOFFERS: 'Job Offers ',
            JOBTITLE: 'Title',
            JOBTYPELABEL: 'Job Type Name',
            CREATEDBY: 'Created by',
            CREATEDAT: 'Created At',
            DOMAINLABEL: 'Field',
            RECOGNITION: 'Recognition',
            DESCRIPTION: 'Description',
            POSITION: 'Position',
            SALARYRANGE: 'Salary range',
            DURATION: 'Duration',
            WORKTIME: 'Work Time',
            STATUS: 'Status',
            ZIPCODE: 'ZIP Code',
            ADD: 'ADD',
            CREEATEDAT: 'Created At',

            CITYLABEL: 'City',
            PARTNERCREATOR: 'Created By',
            BACKUPDATENAME: 'Update',
            BENIFITS: 'Benefits',
            BENIFITSLIST: 'Benefits list',
            EXPERIENCES: 'Experiences',
            LANGUAGES: 'Languages',
            DEGREE: 'Degree',
            DRAFT: 'Draft',
            PENDING: 'Pending',
            PUBLISHED: 'Published',
            EXPIRED: 'Expired',
            CANCELED: 'Canceled',
            UNPUBLISH: 'Unpublished',
            AT_LEAST_THREE: 'Please mention at least 3 Benefits',
            NEXT: 'Next Step',
            PREV: 'Previous Step',
            PUBLISH_JOB: 'Publish the Job Offer ',


        },
        PARTNERS: {
            USERS: 'Team',
            PARTNERS: 'Partners',
            BANNED: 'Banned',
            JOBOFFERS: 'Job Offers',
            COMPANY: 'Company',
            POSITION: 'Position',
            REGISTRY: 'Taxpayer Identification Number',
            PHONE: 'Phone Number',
            NEW: 'New',
            APPROVED: 'Approved',
            TO_APPROVE: 'To Approve',
            DECLINED: 'Declined',
            EMPLYEERANGE: 'Declined',
            EMPLOYEERANGE: 'Employee Range',
            COMPNAY_INFO: 'Company Information',
            EDIT: 'Edit',
            DOMAIN: 'Domains',
            ACCEPTED: 'Accepted',
            PARTNER: 'Partner',
            RECRUTMENT_CANDIDATES: 'Recruitment candidates',
            RAISONS_BANNED: 'Reasons for Ban',
            COMPANY_INFO: 'Company Info',
            DETAILS: 'Details',
            ADD_NEW_PARTNER: 'Add new partner',

        },
        GROUPS: {
            GROUPS: 'Groups',
            ROLES: 'Roles',
            ROLE: 'Role',
            CODE: 'Code',
            NEWGROUP: "Create new group",
            EDITGROUP: "Edit  group"
        },

        SETTINGS: {
            ADD_TRANSLATE: 'Add translation',
            WORD: 'Word',
            ALMAND: "GR",
            ENGLISH: "EN",
            FRENSH: "FR",
            WORDING: 'Wording',
            TERMS_CONDITIONS: 'Terms & Conditions',
            ADD_SERVICE_TERMS: 'Add General sales conditions',
            PREPARING: 'preparing',
            SETTINGS: 'Settings',
            DOMAIN: 'Domain list',
            AGENCY: 'Agencies list',
            LANGUAGES: 'Languages list',
            JOBTYPE: 'Jobtypes list',
            DEGREE: 'Degrees list',
            EXPERIENCE: 'Experience list',
            COUNTRIES: 'Countries list',
            CITIES: 'Cities list',
            DOMAINS: 'Domains',
            SUBDOMAINS: 'Sub Domains',
            SKILLS: 'Specialties',
            UPDATE_COUNTRY: 'Update Country',
            ADD_COUNTRY: 'Add Country',
            UPDATE_CITY: 'Update City',
            ADD_CITY: 'Add City',
            UPDATE_JOBTYPE: 'Update Job type',
            ADD_JOBTYPE: 'Add Job type',
            UPDATE_AGENCY: 'Update Agency',
            ADD_AGENCY: 'Add Agency',
            UPDATE_LANGUAGE: 'Update Language',
            ADD_LANGUAGE: 'Add Language',
            UPDATE_EXPERIENCE: 'Update Experience',
            ADD_EXPERIENCE: 'Add Experience',
            UPDATE_DEGREE: 'Update Degree',
            ADD_DEGREE: 'Add Degree',
            UPDATE_DOMAIN: 'Update Domain',
            ADD_DOMAIN: 'Add Domain',
            ADD_SPECIALITY: 'Add Speciality',
            UPDATE_SPECIALITY: 'Update Speciality',
            ADD_SUB_DOMAIN: 'Add Sub Domain',
            UPDATE_SUB_DOMAIN: 'Update Sub Domain',
            ADD_GENRAL_TERMS: 'Add General Terms',
            FEDERALS: 'Federal',
            DISTRICTS: 'District',
            TYPE: 'Type',
            ZIP_CODE: 'Zip code',

        },

        USERS: {
            USERS: 'Team',
        },
        CANDIDATE: {
            EPHEMERAL_LINK: "Ephemeral link",
            EXPIRED_AT: "Expire on",
            SELECT_DOCUMENTS: "Select documents",
            BACK_DATASHEET: "Back to candidate Datasheet",
            CREATE_LINK: "Create link",
            RECOGNITION: 'Recognitions',
            RAISONS_DECLINE: 'Decline Reasons',
            SEARCH: 'First Name , Last Name',
            SCHOOL: 'School',
            FROM: 'From',
            TO: 'To',
            TALENTS: "Talents",
            MY_CANDIDATES: "My candidates",

            RECRUTMENT_DOCUMENTS: 'Recruitment documents',
            DIPLOME: 'Speciality',
            DEGREE: 'Degree',
            ACCEPTED: 'Accepted',
            DECLINED: 'Declined',
            CONDIDATE: 'Candidate',
            CONDIDATES: 'Candidates',
            JOBTYPE: 'Job Type',
            COUNTRY: 'Country',
            CITY: 'City',
            IDENTITY_VALIDATION: 'Identity validation',
            CVVALIDATION: 'CV validation',
            NEW: 'New',
            PROPOSEJOB: 'Propose a job offer',
            UPLOADIMAGE: 'Upload image',
            UPLOADPASSPORT: 'Upload passport',
            UPLOADPRESENTATION: 'Upload presentation',
            REASON: 'Reason',
            CREATED_BY: 'Created By',
            COMMENTARY: 'Comment',
            CREATEDAT: 'Created',
            CVPENDING: 'CV Pending',
            CVACCEPTED: 'CV Accepted',
            USER: 'User',
            SHORTCODE: 'Short code',
            LABEL: 'Language Label',
            EDITDEGREE: 'Edit degree',
            EDITEXPERIENCE: 'Edit Experience',
            AGENCE: 'Agency',
            ADDNEW: 'Add New Candidate',
            CANDIDATE: 'Candidate',
            PROFESSION: 'Professional Information',
            GENRALINFO: 'General Info',
            EDUCATIONEXPERIENCES: 'Education Experiences',
            LANGUAGES: 'Languages',
            CERTIFICATIONS: 'Certifications',
            WORKEXPERIENCES: 'Work Experiences',
            UPDATE_PROOF: 'Update Proof',
            INTERSHIP: 'Interships',
            INTERESTS: 'Interests',
            DRIVERLICENCES: 'Driving licence',
            SKILLS: 'Skills',
            LANGUAGE: 'Language',
            CERITIFICATION: 'Certification',
            LEVEL: 'Level',
            INFO: 'Info',
            CV: 'CV',
            CVDRAFT: 'CV Draft',
            CVDECLINED: 'CV Declined',
            INVITED: 'Invitations',
            PROPOSED: 'Propositions',
            MATCHED: 'Matches',
            INTERVIEW: 'Interviews',
            INTERVIEWD: 'Interviewd',
            RECRUTING: 'Recrutement',
            RECRUTING_PREPARING: 'Recrutement Preparing',
            PROPOSE: 'Propose Candidate',
            INVITED_JOBOFFRE: 'Invited job offer',
            APPLIED_JOBOFFRE: 'Applied job offer',
            PROPOSED_JOBOFFRE: 'Proposed job offer',
            MATCHING_JOBOFFRE: 'Matched job offer',
            INTERVIEW_JOBOFFRE: 'Interviewed job offer',
            APPLIED: 'Applied',
            OTHERS: 'Others',
            RECRUTING_DOCUMENTS: 'Recruiting documents',
            MISSION: 'Mission',
            TASKS: 'Tasks',
            INSTITUE: 'Institue',
            SUBJECT: 'Subject',
            DONE_ON: 'Done On',
            SCORE: 'Score',

            SELECT_LICENCE_DRIVER: 'Select driving licence',
            CV_CANDIDATE: 'My Cv & Info',

            ADD_INTEREST: 'Add interest',
            ADD_SKILL: 'Add skill',

            WISHLIST: 'Wishlist',

            ALL: 'All',
            CANCEL_INVITATION: 'Cancel Invitation',
            FAVORIS: 'Favorites',
            INVITED_CANDIDATE: 'Invite Candidate',
            ACCEPT_MATCH: 'Accept Match',

            ACCEPT_PROPOSITIONS: 'Accept Proposition',
            CANCEL_PROPOSITIONS: 'Decline Proposition',
            CANCEL_INTERVIEW: 'Cancel Interview',
            ACCEPT_INTERVIEW: 'Accept Interview',

        },


        SERVICES: {
            SERVICE_TERMS: 'General Sales Conditions',
            FINISH: "Finish order",
            PENDING_PAYMENT: 'Pending payment',
            CANCELED: 'Canceled',
            FINISHED: 'Finished',
            CANCELED_PAYMENT: 'Canceled payment',
            WAIT_PAYMENT: 'Waiting for payment',
            DOCUMENT_SERVICE_UPLOADED: 'Document uploaded',
            COMPELETED: 'Completed',
            PAID: 'Paid',


            PRODUCT_OF_PACK: 'Services of this pack',
            UPDATE_PACK: 'Update Pack',
            UPDATE_PRODUCT: 'Update Product',
            TOTAL: 'Total',
            UPLOAD_DOCUMENT: 'Upload document',
            RECIPIENT: 'Recipient',
            FILE_NAME: 'File name',
            ALL_ORDERS: 'All Orders',
            NAME_SERVICE: 'Name Of Service',
            SERVICE: 'Service',
            SERVICES: 'Services',
            PRODUCTS: 'Products',
            PACKS: 'Packs',
            PRICE: 'Price',
            DESCRIPTION: "Description",
            VERSION: 'Version',
            //  PACKS : 'Packs' ,
            PACK: 'Pack',
            //  PRODUCTS : 'Products' ,
            PRODUCT: 'Product',
            ADD_PRODUCT: 'Add Product',
            ADD_PACK: 'Add Pack',

            TERMS_CONDITION: 'Terms & Conditions',
            CUSTOMER_NAME: 'Customer Name',
            ODER_NUMBER: 'Order Number',
            STATUS: 'Status',

            PENDING: 'Pending',

            YOUR_CART: 'Your cart contains',
            CART_CONTAINS_OF: 'Cart composed of',
            DOWNLOAD_INVOICE: 'Get Invoice',
            ACCEPT_PROOF: 'Accept Proof',
            DECLINE_PROOF: 'Decline Proof',
            SHOW_PROOF: 'Show Proof',
            PAYMENT_HISTORY: 'Payment history',
            CANCEL_CHECKOUT: 'Cancel Checkout',
            TO_BUY_SERVICE_YOU_SHOULD_ACCEPT_THIS_TERMS: 'In order to benefit from this service, please accept our terms & conditions',
            BUY: 'Buy',
            CHECKOUT: 'Checkout',
            PRODUCTS_OF_PACK: 'Products of the pack',

            GET_DOCUMENT: 'Get document',

            UPLOAD_PROOF: 'Upload proof',

            YOUR_CART_CONTAINS: 'Your cart contains',


        },

        ORDERS: {
            ORDERS: 'Orders',
            PRICE: 'Price',
            TOTAL_ORDER: 'Total Order',
        },
        WORKFLOWS: {
            WORKFLOWS: 'Workflows',
            WORKFLOWS_PREPARING: "Workflows Preparing",
            WORKFLOWS_RECRUTMENT: "Workflows Recruitment",


        },
        PAGES: {
            RECRUTING_PREPARING: 'Recrutement Preparing',
            WORKFLOWS_PREPARING: "Workflows Preparing",
            TERMS: 'The Terms & Conditions',
            WORDINGMANAGMENT: 'Wording Managment',
            ORDER: 'Order',
            TEAM: 'Team',
            PRICES_SERVICE: 'Prices Service',
            NOTIFICATIONMANAGMENT: 'Notifications Managment',
            PARTNERMANAGMENT: 'Partners Managment',
            USERSMANAGMENT: 'Users Managment',
            SETTINGSMANAGMENT: 'Settings Managment',
            JOBOFFRESMANAGMENT: 'Job Offers Managment',
            CANDIDATESMANAGMENT: 'Candidates Managment',
            TEAMSMANAGMENT: 'Teams Managment',
            GROUPSMANAGMENT: 'Groups Managment',
            WORKFLOWMANAGMENT: 'Workflow Managment',
            SERVICEMANAGMENT: 'Services Managment',
            ORDERSMANAGMENT: 'Orders Managment',
            MYPROFILE: 'My profile',
            LOGOUT: 'Log out',
            READPARTNER: 'Partner',
            ADD_NEW_PARTNER: 'Partner',
            UPDATE_PARTNER: 'Partner',
            READCANDIDATE: 'Candidate',
            UPDATECANDIDATE: 'Candidate',
            ADDCANDIDATE: 'Candidate',
            READCVCANDIDATE: 'Candidate CV',
            READJOBOFFR: 'Job Offer',
            UPDATEJOBOFFER: 'Job Offer',
            ADD_NEW_JOBOFFER: 'Job Offer',
            DASHBOARD: 'Dashboard',
            RECRUTMENTMANAGMENT: 'Recruitment managment',
            READRECRUTMENT: 'Recruitment',
            STEPS: 'Steps',
            CHECKOUT: 'Checkout',
            MY_CV: 'My CV',

            NEWUSER: 'User',
            READUSER: 'User',
            UPDATEUSER: 'User',

            /**
            
            msg: 'Problem occurred please try again later!' , ERROR_AUTH  , Can't be recycled
            */
        }
        ,
        EMPTY: {
            NOTRECRUTMENTSFOUND: ' No recruitment was found',
            MAX_LENGTH: 'Maximum length',
            CHAR: 'Characters',
            CAN_NOT_HAVE_DOSSIER: 'You don"t have a recrutement preparation dossier yet',
            NO_COMMENTS: 'No comments were found',
            DONE: 'Done',
            CHECK_YOUR_EMAIL: 'Please check your email',
            CAN_NOT_RECYCLE_PROPOSTION_TO_SAME_CANDIDATE: `Unfortunately, you can't assign the proposition to the same candidate`,
            NOTORDERSFOUND: 'No orders were found',
            NOTPACKSFOUND: 'No packs were found',
            NOTPRODUCTSFOUND: 'No products were found',
            NOTGROUPFOUND: 'No groups were found',
            NOTPRICEFOUND: 'No prices were found',
            NOTNOTIFICATIONSFOUND: 'No notification found',
            EMAIL: 'E-mail is required',
            PASSWORD: 'Password is required',
            VALIDMAIL: 'Please enter a valid Email address',
            NOTTEAMFOUND: 'No team were found',
            NOTPARTNERFOUND: 'No partners were found',
            NOTJOBSFOUND: 'No job offers were found',
            NOTSTEPFOUND: 'No steps were found',
            NOTUSERSFOUND: 'No users were found',
            NOTCANDIDATESFOUND: 'No candidates were found',
            NOTFOUNDPAGE: 'We are Sorry,the page you are looking for cannot be found',
            HOME: 'Home',
            GENDER: 'Gender is required ',
            FIRSTNAME: 'First Name is required ',
            LASTNAME: 'Last Name is required ',
            ADDRESS: 'Address is required ',
            COUNTRY: 'Country is required ',
            CITY: 'City is required ',
            RECOGNITION: 'Recognition is required ',
            PHONE: 'Phone is required ',
            MAIL: 'Mail is required ',
            BIRTHDAY: 'Birthday is required ',
            NATIONALITY: 'Nationality is required ',
            MARITALSTATUS: 'Marital status is required ',
            JOBTYPE: 'Job type is required ',
            DOMAINE: 'Field is required ',
            THANKS_CHECK_YOUR_EMAIL: 'Thank you for your inscription. Please check your email to get your password',
            WE_WILL_CONTACT_YOU: 'Done , we will contact you soon',

            NOTUSERFOUND: 'No user found',


            CAN_NOT_RECYCLE_INVITATION_TO_SAME_JOB: `Unfortunately, you can't assign the invitation to the same job Offer`,
            CAN_NOT_RECYCLE_AN_APPLICATION: `Unfortunately, you can't recycle an application `,

            SCHOOL: 'School name is required',
            DIPLOME: 'Diploma name is required',
            FROM: 'Start year is required',
            TO: 'End year is required',
            COMPANY: 'Company name is required',
            MISSION: 'Mission  is required',
            TASKS: 'Tasks  are required',
            TITLE: 'Title  is required',
            SUBJECT: 'Subject  is required',
            INSTITUE: 'Institut  is required',
            SCORE: 'Score  is required',
            ON: 'Year is required',
            LANGUAGE: 'Language is required',
            LEVEL: 'Level is required',
            CERTIFICATION: 'Certification is required',


        }

    }



};
