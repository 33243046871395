<div class="card  my-8 p-24" fxLayout="row">
<div class="logo mr-18">
  <div class="name flex-sm-sm">
    <img [src]="user?.photo" (error)="onImgError($event)" alt="" srcset="" class="mr-16 logo-offre">
  </div>
</div>
<div class="details " fxLayout="column">
  <div fxLayout="row "   fxShow fxHide.xs  class="mb-4" *ngIf="user.status !== 'ACCEPTED'">
    <a   *ngIf="candidateStatus===''" [routerLink]="['/candidates/cv/', user.code]" mat-raised-button class=" btn-mat btn-attachmets py-4 bnt-phone mr-12" >
      <img src="./assets/integration/Desktop34/file.svg" class="icon">
      {{'COMMUN.VIEW_CV' | translate}}
    </a>

    <a   *ngIf="candidateStatus!==''" [routerLink]="['/candidates/cv/', user.ct_code]" mat-raised-button class=" btn-mat btn-attachmets py-4 bnt-phone mr-12" >
      <img src="./assets/integration/Desktop34/file.svg" class="icon">
      {{'COMMUN.VIEW_CV' | translate}}
    </a>
    <a   *ngIf="user.presentation" (click)="showPresent(user.presentation)"    mat-raised-button class="   btn-mat btn-attachmets py-4 bnt-Black " >
      <img src="./assets/integration/Desktop34/vedio.svg" class="icon">
      {{'COMMUN.VIEW_VEDIO' | translate}}
    </a>

  </div> 

  <span class="fullName " *ngIf="candidateStatus===''">
    <div class="text-bold" >{{user?.alias}} </div> {{user?.firstname}} {{user?.firstname}}
  </span>
  <span class="fullName " *ngIf="candidateStatus!==''">
    <div class="text-bold" >{{user?.ct_alias}} </div> {{user?.completename}} 
  </span>
  <span> {{user.domain_label}}</span>
  <span> {{user.jobtype_label}}</span>

  <span *ngIf="candidateStatus  && ( user.status ===  'CANCELED'  || user.status ===  'DECLINED' )"> <span class="text-bold"> {{'COMMUN.STATUS' | translate}} : </span> {{'COMMUN.'+user.status | translate}}</span>

</div>
<div class="btn-actions  " fxLayoutAlign=" end end" fxLayout="column">

  <a  *ngIf="candidateStatus===''" class="mr-32 i" [routerLink]="['/candidates/datasheet', user.code , 'read']"><img
      src="./assets/icons/jobzioo-icons/eye.svg" class="cursor-pointer " alt=""> </a>


      <a  *ngIf="candidateStatus!==''" class="mr-32 i" [routerLink]="['/candidates/datasheet', user.ct_code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" class="cursor-pointer " alt=""> </a>

      <a  *ngIf="candidateStatus===''&& user.status === 'ACCEPTED'" class=" i" [routerLink]="['/candidates/datasheet', user.code , 'edit']" ><img
        src="./assets/icons/jobzioo-icons/edit.png" class=" cursor-pointer icon" alt=""> </a>


        <a  *ngIf="candidateStatus==='proposed'&& user.status !== 'CANCELED'&& user.status !== 'DECLINED'" class=" i" (click)="cancelPropo(user.aff_code)" ><img
          src="./assets/integration/Desktop57/NOlampe.svg"  class=" cursor-pointer icon" alt=""> </a>
  


</div>

</div>


<!--  
       <thead>
            <tr>
              <th>{{'COMMUN.FULLNAME' | translate}}</th>
              <th>{{'COMMUN.SUBMITTEDDATE' | translate}}</th>
              <th>{{'COMMUN.DOMAINE' | translate}} / {{'COMMUN.JOBTYPE' | translate}} </th>
              <th>{{'COMMUN.OPTIONS' | translate}}</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of  listCandidates ; let index = index">

              <td>
                <div class="name flex-sm-sm">
                  <img [src]="user?.photo" (error)="onImgError($event)" alt="" srcset="" class="mr-16">
                  <div class="details" fxLayout="column">
                    <span class="fullName"> {{user?.firstname}} {{user?.lastname}} </span>
                    <span class="mail" > {{user?.alias}} </span>
                    <div  fxLayout="column">
                      <span class="mail">  <b>  {{'COMMUN.MAIL' | translate}} : </b> {{user?.mail}} </span>
                    </div>
                  </div>
                </div>

              </td>

              <td>
                {{ returnDate(user.created_at)}}
              </td>

              <td>
                {{user.domain_label}} ,  {{user?. jobtype_label}} 

              </td>

              <td class="actions">
                
                <div class="btn-actions  ">
                  <a [routerLink]="['/candidates/datasheet', user.code , 'read']"><img
                    src="./assets/icons/jobzioo-icons/eye.svg" class=" mr-4 cursor-pointer" alt=""> </a>
                <a [routerLink]="['/candidates/datasheet', user.code , 'edit']" ><img
                    src="./assets/icons/jobzioo-icons/edit.png" class=" mr-4 cursor-pointer icon" alt=""> </a>

              </div>
            </td>

          </tr>


        </tbody>
-->