import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],

    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit, OnChanges {
    @Input()
    layout = 'vertical';
    @Input() role = ""

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */




    ngOnChanges(): void {


        this._fuseNavigationService.getCurrentNavigation().forEach(e => {

            if (this.role === 'LOCAL_ASSISTANCE' && (e.title === 'dashboard' || e.title === 'joboffer' || e.title === 'candidate')) {
                e.hidden = false
            }

            else if (this.role === 'CANDIDATE_RECRUITER' && (e.title === 'dashboard' || e.title === 'joboffer' || e.title === 'candidate' || e.title === 'partners' || e.title === 'orders' || e.title === 'recruitings')) {
                e.hidden = false
            }


            else if (this.role === 'PARTNER_RECRUITER' && (e.title === 'dashboard' || e.title === 'joboffer' || e.title === 'candidate' || e.title === 'partners' || e.title === 'orders' || e.title === 'recruitings')) {
                e.hidden = false
            }


            else if ((this.role === 'root' || this.role === 'ROOT')    && (e.title === 'dashboard' || e.title === 'partners' || e.title === 'candidate'
                || e.title === 'services' || e.title === 'orders' || e.title === 'recruitings' || e.title === 'workflows' || e.title === 'Groups'
                || e.title === 'team' || e.title === 'settings' || e.title === 'wording' ||  e.title === 'recruitingsPreparing' || e.title === 'workflowsPreparing'     || e.title === 'joboffer')) {
                    e.hidden = false
            }


            else if ((this.role === 'SuperAdmin' || this.role === 'SUPERADMIN')    && (e.title === 'dashboard' || e.title === 'partners' || e.title === 'candidate'
            || e.title === 'services' || e.title === 'orders' || e.title === 'recruitings' || e.title === 'workflows' || e.title === 'Groups'
            || e.title === 'team' || e.title === 'settings' || e.title === 'wording' ||  e.title === 'recruitingsPreparing' || e.title === 'workflowsPreparing'     || e.title === 'joboffer')) {
            e.hidden = false
        }

            else {
                e.hidden = true

            }




        })
    }

    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
}
