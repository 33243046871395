import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    private userUrl = `${environment.apiUrl}back/backusers`;
    private agenciesChoiceUrl = `${environment.apiUrl}back/agencies`;
    private groupsChoiceUrl = `${environment.apiUrl}back/backGroups`;

    constructor(private http: HttpClient) {
    }

    GetUsers(params): Observable<any> {

        return this.http.get<any>(`${this.userUrl}`, {params});
    }

    GetAgenciesUsers(): Observable<any> {
        return this.http.get<any>(`${this.agenciesChoiceUrl}/choices`);
    }

    GetGroupsChoice(): Observable<any> {
        return this.http.get<any>(`${this.groupsChoiceUrl}/choices`);
    }

    AddUser(user: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}back/backuser`, user);
    }


    EditGroup(params, code): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}back/backuser/${code}`, params);
    }

    editImg(code , params): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}back/backuser/${code}/img`, params);
    }


    getGroup(code): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/backuser/${code}`, {});
    }


    setStateTeams(code): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}back/backuser/${code}/state`, {});
    }



    resetPasswordTeams(code): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}back/backuser/${code}/resetpassword`, {});
    }

    resetPassword(mail) {
        ///back/resetpasswd/{mail}
        return this.http.patch<any>(`${environment.apiUrl}back/resetpasswd/${mail}`, {});

    }

}