


  <div class=" maria-integration ">

    <div  class="authh" fxLayout="row wrap" fxFlex="100%" >
     <div fxFlex="30%" fxFlex.md="30%"   fxHide.xs fxHide.sm>
        <div class="login"  style="background-image: url('./assets/integration/Desktop57/business.png');">
          <div class="details-log">
           
              <div class="logo">
                <img src="./assets/integration/Desktop57/JobziooLogo.svg" alt="">
              </div>
              <span class="footer">  {{'COMMUN.PARAGRAPHE' | translate}} </span> 
         
          </div>
        </div>
     </div>
  
     <div fxFlex="70%"    fxFlex.md="70%" fxFlex.sm="100%" fxFlexAlign="center center">
      <div class="forms"   >
        <div class="details-form"  fxLayout="column" >
          <span class="login-form-title mb-36">   
            {{'COMMUN.FORGET_PASSWORD' | translate}}
          </span>

        <form [formGroup]="formGroup"  class="filter last login-form " fxLayout="row wrap" fxLayoutGap="16px grid" fxFlex="100%" fxLayoutAlign="center center">
           
                <mat-form-field appearance="outline" fxFlex="100%">
                    <input  matInput  type="email" [placeholder]="'COMMUN.YOUR_EMAIL' | translate"  formControlName="mail">
                  </mat-form-field>
           

            <div class="container-login-form-btn"  fxFlex="100%">
                <button mat-raised-button  [disabled]="formGroup.invalid" class=" btn-mat active  " type="submit" (click)="addAppoint()">
                  {{'COMMUN.SEND' | translate}}
                </button>
              </div>

              <!-- <a routerLink="/auth/login" mat-raised-button class=" btn-mat bnt-Black py-4  mt-8"  >
              {{'COMMUN.RETURN_LOGIN' | translate}}
            </a> -->




            </form>
      </div>
      </div>
    </div>
  </div>
