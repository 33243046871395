import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from 'app/features/integration/serviceIntegration/services/product.service';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopSettingsComponent } from '../popSettings/popSettings.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-card-service',
  templateUrl: './card-service.component.html',
  styleUrls: ['./card-service.component.scss']
})
export class CardServiceComponent implements OnInit {
  @Input() service ;
  @Output() changed = new EventEmitter<string>();
  item = {
    label: "",
    description: "",
    tc: "",
    products: []
  };data
  constructor( private _matDialog: MatDialog,  private productService: ProductService, private serviceSetting: SettingsService ) { }

  ngOnInit() {
  }

  returnDomain(domain) {
    return domain.substring(0, 59);
   } 




  addSerivce(type, setting?) {
    
    this.item.products = [];
    if (setting) {
      this.item.label = setting?.label;
      this.item.description = setting?.description


   
          const dialogRef = this._matDialog.open(PopSettingsComponent, {
            data: {  item: this.item, type: type, action: 'SERVICES.UPDATE_PRODUCT' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.data = {
                  label: result.item.label,
                  description: result.item.description,
                }
           
              this.productService.updateService(type, this.data , setting.code).subscribe(res => {
                if (res.status === 'success') {
                  this._matDialog.open(PopApiComponent, {
                    data: {
                      mode: 'active',
                      msg: 'EMPTY.DONE',
                      image: "./assets/icons/jobzioo-icons/check.svg"
                    }
                  })
                 
                  this.changed.emit('done')
            
                }
              })
        
            }})
      

    } 
    
 
    
  }


  changeState(item, type) {
    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data: item.active ? { mode: "active", item: item, msg: 'Are you sure to disable this ? ', image: "./assets/icons/jobzioo-icons/stop.svg" } : { mode: "inactive", item: item, msg: 'Are you sure to able this ? ', image: "./assets/icons/jobzioo-icons/check.svg" },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceSetting.changeStatus(item.code, type).subscribe(res => {
          if (res.status === 'success') {
            // this.alertify.success('done') ;
            this._matDialog.open(PopApiComponent, {
              data: {
                mode: 'active',
                msg: 'EMPTY.DONE',
                image: "./assets/icons/jobzioo-icons/check.svg"
              }
            })
            this.changed.emit('done')

            // this.getGroups() ;
          }
        })


      }else {
        this.changed.emit('done')
      }
    })
  }


  
}
