<table class="simple invoice-table py-16 ">
  <thead >
      <tr >
           <th>Name</th>
          <th >Attachments</th>
          <th >Submitted</th>
          <th >Profile</th>
          <th >Options</th>
         
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let user of  listUser ; let index = index" >
        
        <td >
          <div class="name flex-sm-sm"   >
            <img [src]="user?.photo" alt="" srcset="" class="mr-16">
            <div class="details" fxLayout="column">
             <span class="fullName"> {{user?.firstname}} {{user?.firstname}}  </span> 
              <span class="mail" *ngIf="get!=='all'" >  {{user?.alias}} </span>
              <div *ngIf="get==='all' " fxLayout="column">
                <span class="mail" >  {{user?.mail}} </span>
                <span class="mail"> Age : 25 years </span>
                <span class="mail" > Country : {{user?.country_label}} </span>
                <span class="mail" > {{user?. jobtype_label}} , 5 years</span>

               
              </div>
            

            </div>
          </div>
         
          </td>

          <td> 
            <div fxLayout="column">
              <button mat-raised-button class=" btn-mat btn-attachmets py-4 bnt-phone mb-12" (click)="sendEmail()">
                <img src="./assets/integration/Desktop34/file.svg" class="icon">
                {{'COMMUN.VIEW_CV'| translate}} 
              </button>
              <button mat-raised-button class="   btn-mat btn-attachmets py-4 bnt-Black " (click)="shedleMetting()">
                <img src="./assets/integration/Desktop34/vedio.svg" class="icon">
                {{'COMMUN.VIEW_VEDIO' | translate}}</button>
               
             </div>
          </td>

          <td> 
            {{ returnDate(user.submitted)}}
          </td>

          <td> 
            {{user.profile}}
          </td>

          <td class="actions" >
            <div class="btn-actions  "  >
              <a [routerLink]="['/candidates/datasheet', user.code , 'read']"><img src="./assets/icons/jobzioo-icons/eye.svg"    class=" mr-4 cursor-pointer" alt="" > </a>
               <a [routerLink]="['/candidates/datasheet', user.code , 'edit']"><img src="./assets/icons/jobzioo-icons/edit.png"    class=" mr-4 cursor-pointer icon" alt="" > </a> 
    
    
              <img   src="./assets/icons/jobzioo-icons/check.svg" class=" mr-4 cursor-pointer" alt="" >
              <img   src="./assets/icons/jobzioo-icons/sortie.svg" class=" mr-4 cursor-pointer" alt="" >

          </div>
          </td>
     
      </tr>


  </tbody>
</table>