

export const locale = {
    lang: 'de',
    data: {
        ICON: {
            REFRESH: 'Aktualisieren',
            ADD: 'Hinzufügen',
            EDIT: 'Bearbeiten',
            DELETE: 'Löschen',
            ACCEPT: 'Annehmen',
            DECLINE: 'Ablehnen',
            VIEW: 'Einzelheiten',
            BANNED: 'Verboten',
            PREPARE_DOSSIER: 'Ordner vorbereiten',
            VERBOT: 'Verbot',
            UNBAN: 'Sperre aufheben',
            ABBRECHEN: 'Abbrechen',
            SCHRITTE: 'Schritte',
            FINISH_STEP: "Schritt beenden",
            PRIVAT: 'Privat',
            PUBLIKUM: 'Publikum',
            GENEHMIGEN: 'Genehmigen',
            CANCEL_PROPOSITION: 'Angebot stornieren',
            ADD_USER: 'Benutzer hinzufügen',
            EDIT_USER: 'Benutzer bearbeiten',
            VIEW_USER: 'Benutzerdetails',
            VIEW_RECRUITING: 'Rekrutierungsdetails',
            VIEW_JOS: 'Angebotsdetails',
            VIEW_JO: 'Angebotsdetails',
            COPY_JO: "Angebot kopieren",
            CANCEL_JO: "Angebot stornieren",
            EXPIRE_JO: "Angebot auslaufen lassen",
            EDIT_JO: "Angebot bearbeiten",
            ADD_WHISHLIST: 'Zur Wunschliste hinzufügen',
            REMOVE_WHISHLIST: 'Von der Wunschliste entfernen',
            INVITE_CANDIDATE: 'Kandidaten einladen',
            CANCEL_INVITATION: 'Einladung stornieren',
            VIEW_CANDIDATE: 'Kandidatendetails',
            VIEW_COMMENT: 'Kommentar anzeigen',
            APPROVE_DOCUMENT: 'Dokument genehmigen',
            DELETE_DOCUMENT: 'Dokument löschen',
            ADD_DOCUMENT: 'Dokument hinzufügen',
            ACCEPT_APPLICATION: 'Bewerbung annehmen',
            DECLINE_APPLICATION: 'Bewerbung ablehnen',
            ACCEPT_PROPOSITION: 'Vorschlag annehmen',
            DECLINE_PROPOSITION: 'Vorschlag ablehnen',
            ACCEPT_MATCH: 'Match akzeptieren',
            DECLINE_MATCH: 'Match ablehnen',
            ACCEPT_INTERVIEW: 'Interview annehmen',
            DECLINE_INTERVIEW: 'Interview ablehnen',
            ADD_EXPERIENCE: 'Erfahrung hinzufügen',
            DELETE_EXPERIENCE: 'Erfahrung löschen',
            ADD_LANGUAGE: 'Sprache hinzufügen',
            DELETE_LANGUAGE: 'Sprache löschen',
            ADD_BENEFITS: 'Vorteile hinzufügen',
            DELETE_BENEFITS: 'Vorteile löschen',
            UNPUBLISH_JO: "Veröffentlichung des Jobs rückgängig machen.",
            ASSIGN_RECRUTMENT: 'Ordner zuweisen'
        },
        COMMUN: {
            CANT_BLANK: "Darf nicht leer sein.",
            FINISH_ORDER: 'Sind Sie sicher, dass Sie diese Bestellung abschließen möchten?',
            CURRENCY: 'Währung',
            EMTY_CITY: 'Keine Stadt (Geben Sie 3 Zeichen ein und geben Sie dann ein)  ',
            YOU_HAVNT_CANDIDATE: "Sie haben keine auf diesen Bereich spezialisierten Kandidaten            ",
            COLOR: 'Farbe',
            PRICE_BY_COUNTRY: "Preise pro Land",
            CURRENT: 'Aktuell',
            PREVIOUS: 'Vorherige',
            SHOW_DOCUMENT: "Dokument ansehen",
            STATEE: 'Status',
            MY_JOBZIOO: 'Mein Jobzioo',
            EDIT_CV: 'Lebenslauf bearbeiten',
            SURE_HIDE_FROM_CV: 'Möchten Sie diesen Abschnitt aus dem Lebenslauf ausblenden?',
            CAN_NOT_HAVE_DOSSIER: 'Sie haben noch kein Bewerbungsvorbereitungsdossier.',

            SELECT_PREPARING_DOSSIER: 'Das entsprechende Vorbereitungsdossier zuordnen.',
            NO_SPECIALTY: "Keine Fachrichtung",
            CREATE_NEW_WORKFLOW: "Erstellen Sie einen neuen Arbeitsablauf.",
            UPDATE_WORKFLOW: "Arbeitsablauf bearbeiten",
            ///////   ///////   ///////   ///////

            UPLOAD_MEDIA: 'Dateien hochladen            ',
            PROGRESS_UPLOAD: 'Hochladen läuft            ',
            SIGNATURE: 'Unterschrift            ',
            IMG: 'Bild',
            FEMALE: 'Weiblich            ',
            MALE: 'Männlich            ',
            SINGLE: 'Ledig            ',
            MARIED: 'Verheiratet            ',
            DIVORCED: 'Geschieden            ',
            WIDOWED: 'Verwitwet            ',




            FILE_MAX_SIZE: `maximale Dateigröße 400 MB`,

            RADIUS: 'Umfang',

            ///////   ///////   ///////   ///////

            EMPLOYER: "Arbeitgeber",
            BROSO_LOG0: 'Logo auswählen',
            BROSO_IMG: 'IMG auswählen',
            BROSO_PRESENTATION: 'Videopräsentation hochladen',
            BROSO_SIGNATURE: 'Unterschrift hochladen',
            BROSO_PHOTO: 'Foto hochladen',
            BROSO_PASSPORT: 'Ausweis/Reisepass',
            SEE_MORE: 'Mehr sehen',
            VIDEO: 'Video',
            DOWNLOAD_CV: 'Lebenslauf herunterladen',
            MESSAGES: 'Nachricht',
            PRESENTATION_TYPE: 'max.: 400 MB als MP4',
            MAX_SIZE: 'Selfie-Video',
            UPLOAD_COMPANY: 'Firmenlogo hochladen',
            UPLOAD_SIGNATURE: 'Unterschrift hochladen',
            UPLOAD_PHOTO: 'Bewerbungsfoto',
            LOGO_TYPE: 'Logo als PNG oder JPEG hochladen. ',
            SIGNATURE_TYPE: 'Unterschrift als PNG oder JPEG hochladen. ',
            PHOTO_TYPE: 'Datei als JPEG oder PNG hochladen.',
            UPLOAD_PASSPORT: 'Ausweis/Reisepass hochladen',
            RETURN_LOGIN: 'Login',
            TOTAL_RECRUTMENTS: 'Gesamteinstellungen',
            OF: 'Von',
            AT: 'Auf',
            ON: 'An',
            YOUR_EMAIL: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
            SEND: 'Senden',
            FORGET_PASSWORD: 'Passwort vergessen',
            TERMS_CONDITIONS: 'Allgemeine Geschäftsbedingungen',
            YOU_CAN_NOT_USE: 'Bitte akzeptieren Sie die AGBs',
            APPROVED_LOGG: ` Entschuldigung, Ihr Zugang wird erst in Kürze freigeschaltet. Bitte prüfen Sie hierzu Ihr E-Mail-Postfach.`,
            SURE_DECLINE_FROM_CV: ' Möchten Sie den Lebenslauf ablehnen? ',

            THANKS_VERIVICATION: 'Ihr Benutzerkonto wurde verifiziert. Vielen Dank für Ihre Anmeldung und willkommen bei Jobzioo!',
            VERIFICATION_ACCOUNT: 'Anmeldung bestätigen',
            I_ACCEPT_TERMS: 'Ich bestätige hiermit die Allgemeinen Geschäftsbedingungen(AGB)',
            EXIST: 'Storniert',
            SUBMIT_CV: 'Lebenslauf einreichen',
            FAVORIS_JOBS: 'Meine Favoriten',
            MATCH_JOBS: 'meine Matches',
            APPLY_JOBS: 'meine Stellengesuche',
            APPLY_JOB: 'Für ein Stellenangebot bewerben.',
            SUBJECT: 'Thema',
            CONTUNUE: 'Weiter',
            BROSE: 'Durchsuchen',
            SELECT_JOB_OFFER: 'Ein Stellenangebot auswählen.',
            FINISH: 'Beenden',
            CHECK_YOUR_APPOINTMENT: 'Wir möchten Sie gerne persönlich kennenlernen. Vereinbaren Sie bitte hierzu mit uns einen Termin, damit wir Sie endgültig freischalten können.',
            CHECK: 'Prüfen',
            UPLOAD: 'Hochladen',
            UPLOAD_DOCUMENTS: 'Dokumente hochladen',
            UPLOAD_DOCUMENT: 'Dokument hochladen',
            CHANGE_ATTACHMENT: 'Anhang hochladen',
            DASHBOARD: 'Dashboard',
            SELCTE_INTERRVIEW_DATE: 'Wählen Sie einen Termin für ein Vorstellungsgespräch aus.',
            SUR_PUBLISH: 'Bestätigen Sie, dass sie das Stellenangebot veröffentlichen möchten. ',
            SURE_PORCHASE_ORDER: 'Meine Bestellung bestätigen.',
            SURE_APPLIED_JOB: 'Meine Bewerbung bestätigen.',
            SURE_CANCEL_APPLICATION: 'Bestätigen Sie, dass Sie die Bewerbung stornieren möchten. ',
            SURE_DECLINE_INTERVIEWD: 'Bestätigen Sie, dass Sie dieses Interview ablehnen möchten. ',
            SURE_ACCEPT_INTERVIEWD: 'Bestätigen Sie, dass Sie dieses Interview annehmen möchten. ',
            SURE_VISIBLITY_DOCUMENT: 'Bestätigen Sie die Änderung der Sichtbarkeit dieses Dokuments.',
            SURE_CONFIRM_DOCUMENT: 'Bestätigen Sie dieses Dokument.',
            SURE_DELETE_DOCUMENT: 'Bestätigen Sie, dass sie das Dokument entfernen möchten.',
            SURE_CANCEL_INVITATION: 'Bestätigen Sie, dass sie diese Einladung absagen möchten.  ',
            SURE_CANCEL_ORDER: 'Bestätigen Sie, dass Sie diese Einladung stornieren möchten. ',
            SURE_ACCEPT_APPLICATION: 'Bitte bestätigen Sie, dass Sie diese Bewerbung annehmen möchten. ',
            SURE_DECLINE_APPLICATION: 'Nennen Sie uns bitte den Ablehnungsgrund. ',
            SURE_RESET_PASSWORD: 'Bestätigen Sie, dass Sie Ihr Passwort zurücksetzen möchten. ',
            SUR_UNPUBLISH: 'Bestätigen Sie, dass sie das Stellenangebot nicht veröffentlichen möchten. ',
            SURE_CANCEL_JOB: 'Bestätigen Sie, dass Sie dieses Stellenagebot löschen möchten. ',
            SURE_EXPIRED_JOB: 'Bestätigen Sie, das dieses Stellenangebot abgelaufen ist. ',
            SURE_ACCEPT_INVITATION: 'Bestätigen Sie, dass sie die Einladung annehmen möchten. ',
            SURE_RESET: 'Bestätigen Sie, dass sie Ihr Passwort zurücksetzen möchten.',
            SELECT_CANDIDATE: 'Bewerber auswählen',
            SELECT_NAME: 'Suchen',
            DOCUEMNT_BY_CANDIDATE: 'Unterlagen, die der Bewerber zur Verfügung gestellt hat ',
            DOCUEMNT_BY_PARTNER: 'Unterlagen, die uns der Partner zur Verfügung gestellt hat',
            ADDWORKFLOW: 'Fügen Sie einen neuen Arbeitsablauf hinzu.',
            SURE_CANCEL_RECRUTMENT: 'Bestätigen Sie die Stornierung dieser Rekrutierung.',
            SURE_CANCEL_PREPARING: "Bestätigen Sie den Abbruch dieser Dateivorbereitung.",
            HI: 'Hallo',
            TITLE: 'Titel',
            THE_COMMENTS: 'Kommentare',
            MEET_WITH: 'Termin mit',
            ALIAS: 'Alias',
            ALIAS_JOBOFFRE: 'Alias Stellenangebot',
            INVITEDBY: 'Eingeladen von',
            APPLIED_JOBS: 'Gesendete Bewerbungen',
            ALIAS_PARTNER: 'Alias Partner',
            CHOOSE_DATE: 'Wählen Sie ein Datum aus.',
            ALIAS_CANDIDATE: 'Alias Kandidat',
            YOU_HAVE_WORK: "Es gibt heute viel zu tun! Lass uns anfangen.",
            YOU_HAVE_NOTI1: "Sie haben",
            YOU_HAVE_NOTI2: "neue Benachrichtigungen",
            FILL: 'Meinen Lebenslauf erstellen.',
            FILL_PLEASE: 'Lassen Sie uns mit Ihrem Lebenslauf beginnen und vergessen Sie nicht detaillierte Angaben zu machen.',
            NEW_PARTNERS: 'Neue Partner',
            NEW_JOBS: 'Neue Stellenangebote',
            CREATED_AT: 'Erstellt von',
            NEW_CANDIDATE: 'Neue Bewerber',
            NEW_RECRUTMENT: 'Neue Rekrutierung',
            NEEDS_BE_VIEWD: 'Überprüfen',
            TOTAL_PARTNER: 'Alle Partner',
            TOTAL_JOBS: 'Alle Stellen',
            TOTAL_JOBS_OFFRES: 'Alle Stellenangebote',
            TOTAL_INTERVIEWD: 'Alle Vorstellungsgespräche',
            TOTAL_PUBLISHED_JOBS: 'Alle veröffentlichten Stellen',
            FAVORIS_JOB: 'Meine Favoriten',
            APPLIED_JOB: 'Meine Bewerbung bestätigen',
            PROPOSED_JOBS: 'Vorgeschlagene Stellen',
            TOTAL_MATCHED_JOB: 'Passende Stellenangebote',
            TOTAL_PENDING_JOBS: 'Alle offenen Stellen',
            TOTAL_APPLIED: 'Bewerbungen insgesamt',
            TOTAL_MATCHED: 'Alle passenden Profile',
            TOTAL_CANDIDATE: 'Alle Bewerber ',
            INCOMMING_MEETING: 'Bevorstehendes Meeting',
            VIEW_ALL: 'Alle ansehen',
            PUBLISH: 'Veröffentlichte Stellenangebote',
            UNPUBLISH: 'Unveröffentlichte Stellenangebote',
            APPOINTMENT: 'Termin',
            DATE: 'Eingereichtes Datum',
            STATELABELL: 'Label angeben',
            CONFIRM: 'Bestätigen',
            PRIVATE: 'Privat',
            SHORTCODE: 'Kurzer Code',
            CREATE_ITEM: 'Element erstellen',
            UPDATE_ITEM: 'Element aktualisieren',
            MAKEARAISON: 'Ablehnungsgrund',
            RAISON: 'Gründe ansehen',
            MAKEACOMMENTARY: 'Kommentar hinzufügen',
            SEARCH: 'Berufsbezeichnung',
            ACCEPT: 'Akzeptieren',
            DECLINE: 'Zurückgehen',
            ACCEPT_CV: 'Lebenslauf akzeptieren',
            DECLINE_CV: 'Lebenslauf ablehnen',
            APPROVED: 'Genehmigt',
            APPROVE: 'Genehmigen',
            BAN: 'Sperren',
            CHECKED: 'Überprüft',
            DECLINED: 'Abgelehnt',
            CANCELED: 'Abgesagt',
            PENDING: 'Offen',
            UPDATE: 'Aktualisieren',
            DELETE: 'Löschen',
            ADD: 'Hinzufügen',
            AGE: 'Alter',
            FIRSTNAME: 'Vorname',
            LASTNAME: 'Nachname',
            FULLNAME: 'Vollständiger Name',
            MAIL: 'E-Mail-Adresse (Firma)',
            MAIL_PARTNER: 'E-Mail-Adresse Partner',
            ADDRESS: 'Straße',
            CITY: 'Stadt',
            COUNTRY: 'Land',
            YEARS: 'Jahr',
            SUBMITTEDDATE: 'Eingegangen am',
            ZIPCODE: 'Postleitzahl',
            ACTIVE: 'Aktivieren',
            DESACTIVE: 'Deaktivieren',
            LOGO: 'Logo',
            LOGO_UPLOAD: 'Logo hochladen',
            LOGIN: 'Anmeldung',
            REGISTER: 'Registrieren',
            CREATE_ACCOUNT: 'Benutzerkonto erstellen',
            PASSWORD: 'Passwort',
            CONFIRM_PASSWORD: 'Passwort bestätigen',
            PHONE: 'Telefon',
            SPECIALITY: 'Fachgebiet',
            Departement: 'Abteilung',
            CREATEDAT: 'Erstellt am: ',
            EDIT: 'Bearbeiten',
            SAVE: 'Speichern',
            UPDATE_PROOF: 'Nachweis aktualisieren',
            IDENTITY_VALIDATION: 'Identitätsprüfung',
            NEW: 'NEU',
            AGENCY: 'Agentur',
            ACCEPTED: 'AKZEPTIERT',
            GROUP: 'Gruppe',
            PROFESSION: 'berufliche Informationen',
            GENRALINFO: 'allgemeine Inormationen',
            PASSPORT: 'Reisepass',
            Email: 'E-Mail-Adresse',
            Phone: 'Telefonnummer',
            PHOTO: 'Profilbild',
            address: 'Straße',
            Contact: 'Kontakt',
            DOMAINE: 'Beruf',
            NATIONALITY: 'Nationalität',
            GENDER: 'Geschlecht',
            FULL_NAME: 'Vollständiger Name',
            GENERAL_INFO: 'Allgemeine Informationen  ',
            ExeperiencesEducation: 'Beruflicher Werdegang',
            Languages: 'Sprachen',
            JOBTYPE: 'Beschäftigungsart ',
            ADDTEAM: 'Team hinzufügen',
            EDITTEAM: 'Team bearbeiten',
            CONTACT: 'Kontakt',
            CLOSE: 'Schließen',
            COMMENT: 'Kommentar hinzufügen',
            YES: 'JA, ich nehme die Bewerbung an.',
            NO: 'NEIN, ich möchte den Bewerber nicht. ',
            DEPARTMENT: 'Abteilung',
            GET_COMMENTS: 'Kommentare erhalten',
            MEDIA: 'Dateien',
            HISTORY: ' Berwerberhistorie',
            DATE_BEGIN: 'Beginndatum',
            DATE_END: 'Enddatum',
            NUMBER_PRODUCT: 'Produktnummer ',
            CANCEL: "Stornieren",
            LABELL: "Label",
            STATE: "Status",
            DATE_VALIDITY: "Gültigkeitsdatum",
            STATUS: "Status",
            POSITION: "Position",
            COMPANY: "Unternehmen",
            DOCUMENTS: "Dokumente",
            ERROR_AUTH: 'Benutzername oder Passwort sind ungültig ',

            FINISHED: 'Beenden',
            STEPS: "Schritte",
            OTHERS: 'Anderes',
            TOTALMATCH: 'Alle Matches',
            TOTALPUBLISH: 'Alle veröffentlichten Angebote',
            SEND_EMAIL: 'E-Mail senden',
            SHEDLE_MEETING: 'Meeting planen',
            NEWUSER: 'Neuen Benutzer erstellen',
            VIEW_CV: 'Lebenslauf ansehen',
            VIEW_VEDIO: 'Video ansehen',
            ATTACHEMENTS: 'Anhang',
            PARAGRAPHE: 'Zukünftige Bewerber verbinden',
            NEWTEAM: 'Neues Teams',
            CHOOSELOGO: 'Logo auswählen',
            CHOOSEFLAG: 'Flagge auswählen',
            CHOOSESIGNATURE: 'Unterschrift hochladen',
            CHOOSEPHOTO: 'Bewerbungsfoto',
            CHOOSEPRESENTATION: 'Präsentation hochladen',
            CHOOSEPASSPORT: 'Reisepass hochladen',
            BIRTHDAY: 'Geburtstag',
            AGENCYNAME: 'Agentur',
            OPTIONS: 'Optionen',
            RESET: 'Passwort zurücksetzen',
            OK: 'Ok',
            PRESENTATION: 'Präsentation',
            MARITALSTATUS: 'Familienstand',
            SHOWPROOF: 'Bitte Nachweis hochladen.',
            INVITATION: 'Einladung',
            PROPOSITION: 'Vorschlag',
            APPLICATION: 'Bewerbung',
            INTERVIEWD: 'Bewerbungsgespräch durchgeführt',
            MATCHED: 'Matches',
            SURE_ABLE: 'Bestätigen Sie, dass Sie diese Funktion aktivieren möchten. ',
            SURE_INABLE: 'Bestätigen Sie, dass Sie diese Funktion deaktivieren möchten. ',
            SHOW_APPOINMENT: 'Termin anzeigen',
            SURE_ACCEPT_CV_OF: 'Bestätigen Sie, dass Sie diesen Lebenslauf annehmen wollen.',
            SURE_DECLINE_CV_OF: 'Bestätigen Sie, dass Sie diesen Lebenslauf ablehnen wollen.',
            SURE_CANCEL_PROPOSE: 'Bestätigen Sie, dass Sie diesen Vorschlag stornieren möchten.',
            SURE_FINISH_STEP: 'Bestätigen Sie, dass Sie diesen Schritt beenden möchten.',
            OBJECT_NOT_FOUND: 'Objekt nicht gefunden',

            PARTNER_BANNED_REASONS: 'Dieser Partner ist aus den folgenden Gründen gesperrt.',
            CANDIDATE_BANNED_REASONS: 'Dieser Bewerber wurde aus folgenden Gründen abgelehnt.',
            APPROVED_PARTNERS: 'Zugelassene Partner',
            T0_APPROVE_PARTNERS: 'Partner zulassen',
            PUBLISH_JOB: 'veröffentlichte Stellen',

            TO_APPROVE_PARTNERS: 'Partner zulassen',

            CANT_CECYLCE: `Bereits verwendet`,
            PROBLEM_NEWORK: 'Es ist ein Problem aufgetreten, bitte versuchen Sie es später erneut!',

            SURE_DISABLE_FROM_TEAM: ' Sind Sie sich sicher, dass Sie dieses Team deaktivieren möchten?',
            SURE_ABLE_FROM_TEAM: ' Sind Sie sich sicher, dass Sie dieses Team aktivieren möchten? ',
            SURE_ACCCEPT_TO_CV: 'Sind Sie sicher, dass Sie diese Information im Lebenslauf angeben möchten?',
            SURE_BAN_IT: 'Sind Sie sich sicher, dass Sie diesen Partner sperren wollen?',
            SURE_APPROVE_IT: 'Sind Sie sich sicher, dass Sie diesen Partner annehmen wollen?',
            SURE_DISABLE_IT: 'Sind Sie sich sicher, dass Sie diese Gruppe deaktivieren möchten?',
            SURE_ABLE_IT: 'Sind Sie sich sicher, dass Sie diese Gruppe aktivieren möchten?',
            SURE_DECLINE_CV: 'Sind Sie sich sicher, dass Sie den Lebenslauf ablehnen möchten?',
            SURE_ACCEPT_CV: 'Sind Sie sich sicher, das Sie den Lebenslauf akzeptieren?',
            SUR_CANCEL_CANDIDATE: 'Bestätigen Sie die Absage dieses Kandidaten?',
            SUR_ACCEPT_CANDIDATE: 'Bestätigen Sie die Annahme dieses Kandidaten?',
            ADD_COMMENT: 'Kommentar hinzufügen',
            ADD_PRICE_BY_COUNTRY: 'Preis je nach Land hinzufügen',
            LIST_PRICE_BY_COUNTRY: 'Preis je nach Land festsetzen ',
            PRICE: 'Preis',

            APPOIEMENT_DATE: 'Datum des Termins',

            ADD_STEP: 'Neuen Vorgang hinzufügen',
            UPDATE_STEP: 'Vorgang aktualisieren',

            DECLINE_PROOF: 'Bestätigen Sie, dass Sie diesen Prüfvorgang ablehnen möchten. ',
            ACCEPT_PROOF: 'Bestätigen Sie, dass Sie diesen Prüfvorgang akzeptieren. ',


            WORKFLOW: 'Arbeitsablauf',

            READ_MORE: 'Lesen Sie mehr',

            BROSO_LOGO: 'Logo auswählen',


            RESOLUTION: 'Auflösung',

            YOU_HAVNT_JOB: ' Keine Stelle verfügbar, um Bewerber einzuladen.',
            OPS: 'Ops',
            ADD_JOB: 'Stelle hinzufügen',



            BY: 'By',


        },
        JOBOFFERS: {
            SAVE_AS_DRAFT: 'als Entwurf speichern',
            JOBINFO: 'Informationen zum Stellenangebot',
            REQUIRMENTS: 'Anforderungen',
            OPTIONS: 'Optionen',
            ADD_NEW: 'Neues Stellenangebot hinzufügen',
            JOBOFFER: 'Stellenangebot  ',
            JOBOFFERS: 'Stellenangebote ',
            JOBTITLE: 'Titel',
            JOBTYPELABEL: 'Berufsbezeichnung',
            CREATEDBY: 'Erstellt von',
            CREATEDAT: 'Erstellt am',
            DOMAINLABEL: 'Beschäftigungsart',
            RECOGNITION: 'Berufsanerkennung',
            DESCRIPTION: 'Beschreibung',
            POSITION: 'Position',
            SALARYRANGE: 'Gehaltsspanne',
            DURATION: 'Dauer',
            WORKTIME: 'Beschäftigungsart',
            STATUS: 'Status',
            ZIPCODE: 'Postleitzahl',
            ADD: 'Hinzufügen',
            CREEATEDAT: 'Erstellt am',

            CITYLABEL: 'Stadt',
            PARTNERCREATOR: 'Erstellt von',
            BACKUPDATENAME: 'Update',
            BENIFITS: ' Vorteile',
            BENIFITSLIST: 'Deine Vorteile',
            EXPERIENCES: 'Berufserfahrung',
            LANGUAGES: 'Sprachen',
            DEGREE: '*Abschluss',
            DRAFT: 'Entwurf',
            PENDING: 'offen',
            PUBLISHED: 'Veröffentlicht am',
            EXPIRED: 'Abgelaufen',
            CANCELED: 'Abgesagt',
            UNPUBLISH: 'Unveröffentlicht',
            AT_LEAST_THREE: 'Nennen Sie mindestens 3 Vorteile',
            NEXT: 'Nächster Schritt',
            PREV: 'Vorheriger Schritt',
            PUBLISH_JOB: 'Stellenangebot veröffentlichen ',



        },
        PARTNERS: {
            USERS: 'Team',
            PARTNERS: 'Partner',
            BANNED: 'Gesperrte Partner',
            JOBOFFERS: 'Stellenangebote',
            COMPANY: 'Firma',
            POSITION: 'Position',
            REGISTRY: 'Handelregisternummer (falls vorhanden)',
            PHONE: 'Telefonnummer',
            NEW: 'Neu',
            APPROVED: 'Zugelassen',
            TO_APPROVE: 'Bestätigung ausstehend ',
            DECLINED: 'Abgelehnt',
            EMPLYEERANGE: 'Anzahl der Mitarbeiter',
            EMPLOYEERANGE: 'Anzahl der Mitarbeiter',
            COMPNAY_INFO: 'Informationen über das Unternehmen ',
            EDIT: 'Löschen',
            DOMAIN: 'Berufsbezeichnung',
            ACCEPTED: 'Akzeptiert',
            PARTNER: 'Partner',
            RECRUTMENT_CANDIDATES: 'Bewerber für Rekrutierung',
            RAISONS_BANNED: 'Gründe für die Sperre',
            COMPANY_INFO: 'Informationen über das Unternehmen ',
            DETAILS: 'Persönliche Angaben',


        },
        GROUPS: {
            GROUPS: 'Gruppe',
            ROLES: 'Rollen',
            ROLE: 'Rolle',
            CODE: 'Code',
            NEWGROUP: "Neue Gruppe erstellen",
            EDITGROUP: "Gruppe löschen"
        },

        SETTINGS: {
            NAME: 'Nachname',
            LONG: 'Längengrad',
            LAT: 'Breite',
            ADD_TRANSLATE: 'Übersetzung hinzufügen',
            WORD: 'Wort',
            ALMAND: "Deutsch",
            ENGLISH: "Englisch",
            FRENSH: "Französisch",
            WORDING: 'Formulierung',
            TERMS_CONDITIONS: 'Allgemeine Geschäftsbedinungen',
            ADD_SERVICE_TERMS: 'Allgemeine Verkaufsbedingungen',
            SETTINGS: 'Einstellungen',
            DOMAIN: 'Domänenliste',
            AGENCY: 'Agenturenübersicht',
            LANGUAGES: 'Sprachkenntnisse',
            JOBTYPE: 'Berufsübersicht',
            DEGREE: 'Abschlussübersicht',
            EXPERIENCE: 'Erfahrungsübersicht',
            COUNTRIES: 'Länderübersicht',
            CITIES: 'Städteübersicht',
            DOMAINS: 'Berufsbezeichnung ',
            SUBDOMAINS: 'Subdomäne',
            SKILLS: 'Besonderheiten',
            UPDATE_COUNTRY: 'Land aktualisieren',
            ADD_COUNTRY: 'Land hinzufügen',
            UPDATE_CITY: 'Stadt aktualisieren',
            ADD_CITY: 'Stadt hinzufügen',
            UPDATE_JOBTYPE: 'Stelle aktualisieren',
            ADD_JOBTYPE: 'Stelle hinzufügen',
            UPDATE_AGENCY: 'Agentur aktualisieren',
            ADD_AGENCY: 'Agentur hinzufügen',
            UPDATE_LANGUAGE: 'Sprache aktualisieren',
            ADD_LANGUAGE: 'Sprache hinzufügen',
            UPDATE_EXPERIENCE: 'Erfahrungen aktualisieren',
            ADD_EXPERIENCE: 'Erfahrungen hinzufügen',
            UPDATE_DEGREE: 'Abschluss aktualisieren',
            ADD_DEGREE: 'Abschluss hinzufügen',
            UPDATE_DOMAIN: 'Domäne aktualisieren',
            ADD_DOMAIN: 'Domäne hinzufügen',
            ADD_SPECIALITY: 'Besonderheiten hinzufügen',
            UPDATE_SPECIALITY: 'Besonderheiten aktualisieren',
            ADD_SUB_DOMAIN: 'Subdomäne hinzufügen',
            UPDATE_SUB_DOMAIN: 'Subdomäne aktualisieren',
            ADD_GENRAL_TERMS: 'Allgemeine Angaben hinzufügen',
            DISTRICTS: 'Stadtteil',
            TYPE: 'Typ',
            ZIP_CODE: 'Postleitzahl',
            FEDERALS: 'Land',

        },

        USERS: {
            USERS: 'Team',
        },
        CANDIDATE: {
            EPHEMERAL_LINK: "vergänglicher Link",
            EXPIRED_AT: "Läuft ab am",
            SELECT_DOCUMENTS: "Wählt Dokumente aus",
            BACK_DATASHEET: "Zurück zum Datenblatt des Kandidaten",
            CREATE_LINK: "Link erstellen",
            RAISONS_DECLINE: 'Gründe für die Ablehnung',
            SEARCH: 'Vorname , Nachname',
            SCHOOL: 'Schule',
            FROM: 'Schule',
            MY_CONDIDATES: "Meine Kandidaten",
            LEADS: 'Führt',
            TO: 'zu ',
            RECRUTMENT_DOCUMENTS: 'Rekrutierungsdokumente',
            DIPLOME: 'Diplom',
            DEGREE: 'Abschluss',
            ACCEPTED: 'Akzeptiert',
            DECLINED: 'Abgelehnt',
            CONDIDATE: 'Bewerber',
            CONDIDATES: 'Bewerber',
            JOBTYPE: 'Stelle',
            COUNTRY: 'Land',
            CITY: 'Stadt',
            IDENTITY_VALIDATION: 'Identitätsüberprüfung',
            CVVALIDATION: 'Überprüfung des Lebenslaufs',
            NEW: 'Neu',
            PROPOSEJOB: 'Stellenangebot vorschlagen',
            UPLOADIMAGE: 'Bild hochladen',
            UPLOADPASSPORT: 'Reisepass hochladen',
            UPLOADPRESENTATION: 'Videopräsentation hochladen',
            REASON: 'Grund',
            CREATED_BY: 'Erstellt von',
            COMMENTARY: 'Kommentare',
            CREATEDAT: 'Erstellt am',
            CVPENDING: 'Lebenslauf steht noch aus',
            CVACCEPTED: 'Lebenslauf akzeptiert',
            USER: 'Nutzer',
            SHORTCODE: 'Kurzbezeichnung ',
            LABEL: 'Label',
            EDITDEGREE: 'Abschluss hinzufügen',
            EDITEXPERIENCE: 'Erfahrungen löschen',
            AGENCE: 'Agentur',
            ADDNEW: 'Neuen Bewerber hinzufügen',
            CANDIDATE: 'Kandidat',
            PROFESSION: 'Beruf',
            GENRALINFO: 'Allgemeine Informationen',
            EDUCATIONEXPERIENCES: 'Schulische Ausbildung',
            LANGUAGES: 'Sprachen',
            CERTIFICATIONS: 'Zertifikate',
            WORKEXPERIENCES: 'Berufliche Erfahrungen',
            UPDATE_PROOF: 'Nachweis aktualisieren',
            INTERSHIP: 'Praktika',
            INTERESTS: 'Interessen',
            DRIVERLICENCES: 'Fahrerlaubnis',
            SKILLS: 'Fähigkeiten',
            LANGUAGE: 'Sprachen',
            CERITIFICATION: 'Zertifikate',
            LEVEL: 'Sprachniveau',
            INFO: 'Information',
            CV: 'Lebenslauf',
            CVDRAFT: 'Lebenslaufentwurf',
            CVDECLINED: 'Lebenslauf abgelehnt',
            INVITED: 'Einladungen',
            PROPOSED: 'Vorschläge',
            MATCHED: 'Matches',
            INTERVIEW: 'Vorstellungsgespräche',
            INTERVIEWD: 'Bewerbungsgespräch durchgeführt',
            RECRUTING: 'Rekrutieren',
            RECRUTINGS: 'Rekrutierung',
            RECRUTING_PREPARING: 'Rekrutierungsvorbereitungen',

            PROPOSE: 'Bewerber vorschlagen',
            INVITED_JOBOFFRE: 'Einladung für das Stellenangebot',
            APPLIED_JOBOFFRE: 'Bewerbung auf das Stellenangebot',
            PROPOSED_JOBOFFRE: 'Stellenangebot vorgeschlagen',
            MATCHING_JOBOFFRE: 'Match zum Stellenangebot',
            INTERVIEW_JOBOFFRE: 'Bewerbungsgespräche zum Stellenangebot',
            APPLIED: 'Angelegt',
            OTHERS: 'Sonstiges',
            RECRUTING_DOCUMENTS: 'Rekrutierungsdokumente',
            MISSION: 'Auftrag',
            TASKS: 'Aufgaben',
            INSTITUE: 'Einrichtung',
            SUBJECT: 'Betreff',
            DONE_ON: 'Durchgeführt am',
            SCORE: 'Ergebnis',

            SELECT_LICENCE_DRIVER: 'Fahrelaubnis auswählen',
            CV_CANDIDATE: 'Mein Lebenslauf und Infos',

            ADD_INTEREST: 'Interessen hinzufügen',
            ADD_SKILL: 'Fähigkeiten hinzufügen',

            WISHLIST: 'Wunschliste',

            ALL: 'Alles',
            CANCEL_INVITATION: 'Termin absagen',
            FAVORIS: 'Favoriten',
            INVITED_CANDIDATE: 'Bewerber einladen',
            ACCEPT_MATCH: 'Match akzeptieren',

            ACCEPT_PROPOSITIONS: 'Vorschlag akzeptieren',
            CANCEL_PROPOSITIONS: 'Vorschlag ablehnen',
            CANCEL_INTERVIEW: 'Bewerbungsgespräch absagen',
            ACCEPT_INTERVIEW: 'Bewerbungsgespräch akzeptieren',

        },


        SERVICES: {
            SERVICE_TERMS: 'Allgemeine Verkaufsbedingungen',
            FINISH: "Bestellung abschließen",
            TOTAL: 'Gesamt',
            UPLOAD_DOCUMENT: 'Dokument hochladen',
            RECIPIENT: 'Empfänger',
            FILE_NAME: 'Dateiname',
            ALL_ORDERS: 'Alle Aufträge',
            NAME_SERVICE: 'Bezeichnung der Dienstleistung',
            SERVICE: 'Dienstleistung',
            SERVICES: 'Dienstleistungen',
            PRODUCTS: 'Produkte',
            PACKS: 'Pakete',
            PRICE: 'Preise',
            DESCRIPTION: "Beschreibung",
            VERSION: 'Version',
            PAID: 'Bezahlt',
            //  PACKS : 'Pakete' ,
            PACK: 'Paket',
            //  PRODUCTS : 'Produkte' ,
            PRODUCT: 'Produkt',
            ADD_PRODUCT: 'Produkt hinzufügen',
            ADD_PACK: 'Paket hinzufügen',
            UPDATE_PRODUCT: 'Produkt aktualisieren',
            TERMS_CONDITION: 'Allgemeine Geschäftsbedingungen',
            CUSTOMER_NAME: 'Kundenname',
            ODER_NUMBER: 'Bestellbezeichnung',
            STATUS: 'Status',
            //    COMPELETED: 'abgeschlossen',
            PENDING: 'Ausstehend',
            CANCELED: 'Abgesagt',
            YOUR_CART: 'Dein Warenkorb',
            CART_CONTAINS_OF: 'Warenkorb',
            DOWNLOAD_INVOICE: 'Rechnung abrufen',
            ACCEPT_PROOF: 'Nachweis akzeptieren',
            DECLINE_PROOF: 'Ablehnungsnachweis',
            SHOW_PROOF: 'Nachweis erbringen',
            PAYMENT_HISTORY: 'Zahlungshistorie',
            CANCEL_CHECKOUT: 'Bestellung abbrechen',
            TO_BUY_SERVICE_YOU_SHOULD_ACCEPT_THIS_TERMS: 'Um den Service zu kaufen, müssen Sie diese Bedingungen akzeptieren.',
            BUY: 'Kaufen',
            CHECKOUT: 'Kasse',
            PRODUCTS_OF_PACK: 'Paketprodukte',

            GET_DOCUMENT: 'Dokument aufrufen',

            UPLOAD_PROOF: 'Nachweis hochladen',

            YOUR_CART_CONTAINS: 'Ihr Warenkorb enthält',

            PENDING_PAYMENT: 'Offene Zahlung',
            FINISHED: 'Beenden',
            CANCELED_PAYMENT: 'Abgeschlossen',
            WAIT_PAYMENT: 'Kein Zahlungseingang ',
            DOCUMENT_SERVICE_UPLOADED: 'Dokument hochgeladen',
            COMPELETED: 'Abgeschlossen',
        },

        ORDERS: {
            ORDERS: 'Bestellungen',
            PRICE: 'Preis',
            TOTAL_ORDER: 'Gesamte Bestellung',
        },
        WORKFLOWS: {
            WORKFLOWS: 'Arbeitsablauf',
            WORKFLOWS_PREPARING: "Arbeitsablauf vorbereiten",
            WORKFLOWS_RECRUTMENT: "Arbeitsablauf Rekrutierung",
        },
        PAGES: {
            RECRUTING_PREPARING: 'Rekrutierung vorbereiten',
            WORKFLOWS_PREPARING: "Arbeitsablauf vorbereiten",

            TERMS: 'Allgemeine Geschäftsbedingungen',
            WORDINGMANAGMENT: 'Wortlautverwaltung',
            ORDER: 'Bestellung',
            TEAM: 'Team',
            PRICES_SERVICE: 'Preisliste Dienstleistungen',
            NOTIFICATIONMANAGMENT: 'Verwaltung von Benachrichtigungen',
            PARTNERMANAGMENT: 'Verwaltung von Partnern',
            USERSMANAGMENT: 'Verwaltung von Usern',
            SETTINGSMANAGMENT: 'Verwaltung der Einstellungen',
            JOBOFFRESMANAGMENT: 'Verwaltung von Stellenangeboten',
            CANDIDATESMANAGMENT: 'Verwaltung der Bewerber',
            TEAMSMANAGMENT: 'Verwaltung der Teams',
            GROUPSMANAGMENT: 'Verwaltung der Gruppen',
            WORKFLOWMANAGMENT: 'Verwaltung des Arbeitsablaufs',
            SERVICEMANAGMENT: 'Verwaltung der Dienstleistungen',
            ORDERSMANAGMENT: 'Verwaltung der Bestellungen',
            MYPROFILE: 'Mein Profil',
            LOGOUT: 'Abmelden',
            READPARTNER: 'Partner',
            ADD_NEW_PARTNER: 'Neuen Partner hinzufügen',
            UPDATE_PARTNER: 'Partner aktualisieren',
            READCANDIDATE: 'Bewerber',
            UPDATECANDIDATE: 'Bewerber aktualisieren',
            ADDCANDIDATE: 'Bewerber hinzufügen',
            READCVCANDIDATE: 'Lebenslauf Bewerber',
            READJOBOFFR: 'Stellenangebot',
            UPDATEJOBOFFER: 'Stellenangebot aktualisieren',
            ADD_NEW_JOBOFFER: 'Neues Stellenangebot hinzufügen',
            DASHBOARD: 'Dashboard',
            RECRUTMENTMANAGMENT: 'Rekrutierungsverwaltung',
            READRECRUTMENT: 'Rekrutierungsübersicht',
            STEPS: 'Schritte',
            CHECKOUT: 'Kasse',
            MY_CV: 'Mein Lebenslauf',

            NEWUSER: 'Neuer Nutzer',
            READUSER: 'Nutzer',
            UPDATEUSER: 'Nutzer aktualisieren',


        }
        ,
        EMPTY: {
            NOTRECRUTMENTSFOUND: 'Keine Einstellung gefunden',
            MAX_LENGTH: 'Maximale Länge',
            MIN_LENGTH: 'Minimale Länge',
            CAN_NOT_HAVE_DOSSIER: 'Sie haben noch kein Bewerbungsvorbereitungsdossier.',
            CHAR: 'Charaktere',
            NO_COMMENTS: 'Keine Kommentare gefunden',
            DONE: 'Erledigt',
            CHECK_YOUR_EMAIL: 'Überprüfen Sie Ihre E-Mails.',
            CAN_NOT_RECYCLE_PROPOSTION_TO_SAME_CANDIDATE: `Vorschlag kann nicht an denselben Kandidaten weitergegeben werden.`,
            NOTORDERSFOUND: 'Keine Bestellungen gefunden',
            NOTPACKSFOUND: 'Keine Pakete gefunden',
            NOTPRODUCTSFOUND: 'Keine Produkte gefunden',
            NOTGROUPFOUND: 'Keine Gruppen gefunden',
            NOTPRICEFOUND: 'Keine Preise gefunden',
            NOTNOTIFICATIONSFOUND: 'Keine Mitteilungen gefunden',
            EMAIL: 'E-Mail Adresse ist erforderlich ',
            PASSWORD: 'Passwort ist erforderlich',
            VALIDMAIL: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            NOTTEAMFOUND: 'Kein Team gefunden',
            NOTPARTNERFOUND: 'Keine Partner gefunden',
            NOTJOBSFOUND: 'Keine Stellenangebote gefunden',
            NOTSTEPFOUND: 'Keine  Schritte gefunden',
            NOTUSERSFOUND: 'Keine Benutzer gefunden',
            NOTCANDIDATESFOUND: 'Keine Bewerber gefunden',
            NOTFOUNDPAGE: 'Entschuldigung, leider konnten wir diese Seite nicht finden.',
            HOME: 'Home',
            GENDER: 'Geschlecht ist erforderlich ',
            FIRSTNAME: 'Vorname ist erforderlich ',
            LASTNAME: 'Nachname ist erforderlich ',
            ADDRESS: 'Adresse ist erforderlich ',
            COUNTRY: 'Land ist erforderlich  ',
            CITY: 'Stadt ist erforderlich  ',
            RECOGNITION: 'Anerkennung ist erforderlich  ',
            PHONE: 'Telefonnummer ist erforderlich  ',
            MAIL: 'E-Mail-Adresse ist erforderlich  ',
            BIRTHDAY: 'Geburtsdatum ist erforderlich ',
            NATIONALITY: 'Nationalität ist erforderlich  ',
            MARITALSTATUS: 'Familienstand ist erforderlich ',
            JOBTYPE: 'Beruf ist erforderlich ',
            DOMAINE: 'Domäne ist erforderlich ',
            THANKS_CHECK_YOUR_EMAIL: 'Vielen Dank für Ihre Anmeldung. Überprüfen Sie Ihr E-Mail-Postfach um Ihr Passwort zu erhalten.',
            WE_WILL_CONTACT_YOU: 'Herzlichen Dank. Wir werden Sie am "DATE" um "TIME" unter Ihrer Rufnummer "phone" anrufen.',

            NOTUSERFOUND: 'Kein User gefunden',


            CAN_NOT_RECYCLE_INVITATION_TO_SAME_JOB: `Einladung kann nicht für dasselbe Stellenangebot verwendet werden.`,
            CAN_NOT_RECYCLE_AN_APPLICATION: `Kann die Anwendung nicht wiederholen `,

            SCHOOL: 'Name der Schule ist erforderlich',
            DIPLOME: 'Diplombezeichnung ist erforderlich',
            FROM: '"ab dem Jahr" ist erforderlich',
            TO: '"bis zum Jahr" ist erforderlich',
            COMPANY: 'Firmenname ist erforderlich',
            MISSION: 'Aufgabenstellung ist erforderlich',
            TASKS: 'Aufgaben sind erforderlich',
            TITLE: 'Titel ist erforderlich',
            SUBJECT: 'Betreff ist erforderlich',
            INSTITUE: 'Einrichtung ist erforderlich',
            SCORE: 'Ergebnis ist erforderlich',
            ON: 'Jahr ist erforderlich',
            LANGUAGE: 'Sprache ist erforderlich ',
            LEVEL: 'Niveaustufe ist erforderlich',
            CERTIFICATION: 'Zertifikate sind erforderlich',


        }

    }
};


// this almand version