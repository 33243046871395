<!--
                    translate: 'JOBOFFERS.CANCELED',
                                translate: 'JOBOFFERS.EXPIRED',
                                             translate: 'JOBOFFERS.PUBLISHED',
                                                translate: 'JOBOFFERS.PENDING','JOBOFFERS.DRAFT',JOBOFFERS.JOBOFFER'
  -->
<div class="jobOffre  jobOffre-lg p-20">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="title" fxLayout="column">
      <span class="alea"> {{jobOffre.joalias}} </span>
      <span class="jobtitle">{{jobOffre.jobtitle}} </span>
    </div>
    <div class="logo ">
      <img [src]="jobOffre?.logo" (error)="onImgError($event)">

    </div>
  </div>
  <div class="details mt-20" fxLayout="column">
    <span class="line"> <span class="creator"> {{'JOBOFFERS.CREATEDBY' | translate}} : </span>
      {{returnDo(jobOffre.partner)}} <span *ngIf="jobOffre?.partner?.length>10">.</span>

    </span>
    <span class="line"> <span class="creator"> {{'COMMUN.DOMAINE' | translate}} : </span> {{jobOffre.domain_label}}
    </span>

    <span class="line"> <span class="creator"> {{'COMMUN.JOBTYPE' | translate}} : </span> {{jobOffre.jobtype_label}}
    </span>


    <span class="line"> <span class="creator" *ngIf="jobOffre?.city_label"> {{'COMMUN.CITY' | translate}} : </span>
      {{returnDomain(jobOffre?.city_label)}} <span *ngIf="jobOffre?.city_label?.length>20">...</span></span>

    <span class="line"> <span class="creator" *ngIf="jobOffre?.city"> {{'COMMUN.CITY' | translate}} : </span>
      {{returnDomain(jobOffre?.city)}} <span *ngIf="jobOffre?.city?.length>20">...</span></span>

    <span class="line" *ngIf="from_partner"> <span class="creator"> {{'COMMUN.STATUS' | translate}} : </span>
      {{jobOffre.status}}</span>


    <span class="line"> <span class="creator"> {{'COMMUN.CREATEDAT' | translate}}: </span>
      {{returnDate(jobOffre.created_at)}} </span>

  </div>

  <div class="btn-actions  mt-20" fxLayout="row" fxLayoutAlign="center">
    <a *ngIf=" !from  " [routerLink]="['/joboffers/datasheet', jobOffre.code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" title="{{'ICON.VIEW_JO' | translate}}"
        class=" mr-4 cursor-pointer icon mb-16" alt=""> </a>


    <a *ngIf=" from ==='candidate' " [routerLink]="['/joboffers/datasheet', jobOffre.jo_code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" title="{{'ICON.VIEW_JO' | translate}}"
        class=" mr-4 cursor-pointer icon mb-16" alt=""> </a>


    <a *ngIf=" !from && (role !== 'LOCAL_ASSISTANCE' &&   
  role !== 'CANDIDATE_RECRUITER' ) && ( jobOffre?.status ==='PENDING' ||  jobOffre?.status ==='DRAFT'  ||  jobOffre?.status ==='UNPUBLISHED') "
      [routerLink]="['/joboffers/manage', jobOffre.code , 'edit']">
      <img src="./assets/icons/jobzioo-icons/edit.png" title="{{'ICON.EDIT_JO' | translate}}"
        class=" mr-4 cursor-pointer icon mb-16" alt=""> </a>


    <img
      *ngIf=" !from && (role !== 'LOCAL_ASSISTANCE' &&   role !== 'CANDIDATE_RECRUITER' ) && ( jobOffre?.status ==='DRAFT' ||  jobOffre?.status ==='PENDING')"
      (click)="cancelJob(jobOffre)" src="./assets/icons/jobzioo-icons/sortie.svg"
      title="{{'ICON.CANCEL_JO' | translate}}" class=" mr-4 cursor-pointer icon mb-16" alt="">




    <img *ngIf="  (role !== 'LOCAL_ASSISTANCE' &&   
  role !== 'CANDIDATE_RECRUITER' ) && jobOffre?.status !=='PENDING' && !from" (click)="copy(jobOffre )"
      class=" mr-4 cursor-pointer icon mb-16" src="./assets/icons/jobzioo-icons/copie.svg"
      title="{{'ICON.COPY_JO' | translate}}">


    <div class="iconn publish mr-4 cursor-pointer"
      *ngIf=" role !== 'LOCAL_ASSISTANCE'&&   role !== 'CANDIDATE_RECRUITER' && jobOffre?.status ==='PENDING' && !from"
      (click)="publishJob(jobOffre)"> <img src="./assets/integration/Desktop36/publish.svg"
        title="{{'ICON.PUBLISH_JO' | translate}}"></div>


    <div class="iconn unpublish mr-4 cursor-pointer"
      *ngIf=" role !== 'LOCAL_ASSISTANCE'&&   role !== 'CANDIDATE_RECRUITER' &&  jobOffre?.status ==='PENDING'&& !from"
      (click)="unpublishJob(jobOffre)"> <img src=" ./assets/icons/jobzioo-icons/unpublish.svg"
        title="{{'ICON.UNPUBLISH_JO' | translate}}"></div>



    <div class="iconn expried mr-4  cursor-pointer"
      *ngIf=" role !== 'LOCAL_ASSISTANCE'&&   role !== 'CANDIDATE_RECRUITER' &&  jobOffre?.status ==='PUBLISHED' && !from"
      (click)="makeExpired(jobOffre)"> <img src="./assets/integration/Desktop36/time.svg"
        title="{{'ICON.EXPIRE_JO' | translate}}"></div>


  </div>
</div>