import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CandidateModel } from 'app/features/integration/candidateIntegration/models/candidate.model';
import { CandidateService } from 'app/features/integration/candidateIntegration/services/candidate.service';

@Component({
  selector: 'cvCandidate',
  templateUrl: './cvCandidate.component.html',
  styleUrls: ['./cvCandidate.component.scss']
})
export class CvCandidateComponent implements OnInit , OnChanges {
   @Input() code;
   @Input() candidateInfo = new CandidateModel({});
   @Input()cv=''

   educationExperienceList=[]
    panelOpenState1=false
   recognitionsList =[]
    panelOpenState10=false

   workExperienceList=[];
    panelOpenState2=false
   certificationList=[];

    panelOpenState3=false
   languageList=[]
    panelOpenState4=false
   interestList=[]; panelOpenState5=false
   licenceList=[]; panelOpenState6=false ; panelOpenState9 = false
   intershipList=[]
    panelOpenState7=false
   skillList=[]; panelOpenState8=false
   listDegree=[];
   raisonsEducation=[] ;
   raisonsWork=[] ;
   raisonsCertif=[] ;
   raisonsIntership=[]
   raisonsLanguage=[]
   documents=[]

  constructor(  private serviceCandidate: CandidateService,
    ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.code) {
        this.getCandidate()

      }

  }


  getCandidate() {
    this.serviceCandidate.getCandidateInfo(this.code).subscribe(res => {

      this.candidateInfo = res.results.data;
      //console.log(res.results.data);

       this.educationExperienceList =  res.results.data.cv.educationExperiences;
       this.workExperienceList = res.results.data.cv.workExperiences;
       this.certificationList = res.results.data.cv.certifications;
       this.languageList =  res.results.data.cv.languages;
       this.intershipList =  res.results.data.cv.interships;
       this.interestList =  res.results.data.cv.interests;
       this.licenceList =  res.results.data.cv.licences;
       this.skillList = res.results.data.cv.skills;
       this.recognitionsList = res.results.data.cv.recognitions;
       this.documents = res.results.data.documents;
    }
);

  }
}
