import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';
import { AlertifyService } from 'app/_services/alertify.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopSettingsComponent } from '../popSettings/popSettings.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements OnInit {
  @Output() changed = new EventEmitter<string>();

  @Input() domain;
  @Input() name
  @Input() type
  item =  {
    label :"",
    active: true ,
    code:'',

}  ;
constructor( private alertify: AlertifyService, private _matDialog: MatDialog, private serviceSetting:SettingsService     ) { }

  ngOnInit() {
  }


  changeState(item ) {
    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data: item.active ? {mode:"active" ,  item : item ,  msg:'Are you sure to disable this ? ' , image :"./assets/icons/jobzioo-icons/stop.svg"  } :  { mode:"inactive" , item : item ,   msg:'Are you sure to able this ? ' , image :"./assets/icons/jobzioo-icons/check.svg"  }   ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.serviceSetting.changeStatus(item.code , this.type).subscribe(res => {
          if(res.status === 'success') {
          //  this.alertify.success('done') ;
            this._matDialog.open(PopApiComponent ,{
              data: {
               mode:'active',
               msg: 'EMPTY.DONE' ,
               image :"./assets/icons/jobzioo-icons/check.svg"
             }  
            })
            this.changed.emit('done')
            
           // this.getGroups() ;
          }
        })
    
         
      }
    })
  }


  manageDomain( domain ) {
   
      const dialogRef = this._matDialog.open(PopSettingsComponent, {
        data: domain ? {mode:'mode' ,  item : domain , type:this.type  , action :'update'} :  {mode:'mode'  , item : this.item ,type:this.type ,action :'add' }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
        
          if(this.name === 'speciality' ) {
            this.serviceSetting.AddDomain(result.item ,this.name , 'update'  , domain.code  ).subscribe(res => {
              if(res?.status === "success") {
                this.alertify.success('done') 
              }
            })      
           }else {
            this.serviceSetting.editSetting(result.item , domain.code , this.type).subscribe(res => {
              if(res.status === 'success') {
                this.alertify.success('done') ;
              }
            })
           }

  
        }
  
      })
    
  }

}
