import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopApiComponent } from '@fuse/components/jobZioIntegration/popApi/popApi.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { TeamService } from 'app/features/integration/teamIntegration/services/team.service';
import * as moment from 'moment';

@Component({
  selector: 'booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],

})

export class BookingComponent implements OnInit {


  public formGroup = new FormGroup({
    mail: new FormControl(null, [Validators.required , Validators.email]),
  })


  constructor(
    private _fuseConfigService: FuseConfigService,
    private _userService: TeamService ,
    private _matDialog : MatDialog ,
    private router : Router,




) {
    // Configure the layout
    this._fuseConfigService.config = {
        layout: {
            navbar: {
                hidden: true,
            },
            toolbar: {
                hidden: true,
            },
            footer: {
                hidden: true,
            },
            sidepanel: {
                hidden: true,
            },
        },
    };
}

  ngOnInit() {

  }

  addAppoint() {
  this._userService.resetPassword(this.formGroup.value.mail ).subscribe(res => {
    if(res.status === "success") {
      this._matDialog.open(PopApiComponent ,{
        data: {
         mode:'active',
         msg: 'EMPTY.CHECK_YOUR_EMAIL' ,
         image :"./assets/icons/jobzioo-icons/check.svg"
       }
        })

      this.router.navigateByUrl('/login')
    }
  })

}

}
