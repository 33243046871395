import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from 'app/_services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentUser ;
    unsubscribeAll: Subject<any>;
    constructor(
        private authservice: AuthService ,
        private router: Router,
    ) { 
        this.unsubscribeAll = new Subject();
    }

    canActivate() {
        if ( !this.authservice.IsloggedIn()) {                 
            return true;
        }else {        
            this.router.navigate(['/login']);
            return false;
        }
    }
}
