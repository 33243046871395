import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecrutmentService {

  private recruitingsUrl = `${environment.apiUrl}back/recruiting`;
  private backUrl = `${environment.apiUrl}back`;


  constructor(private http: HttpClient) {
  }

    getRecrutmentChoice(code){
      return  this.http.get<any>(`${this.backUrl}/recruitingProcessChoice/${code}`, {})

    }

    add_to_recrutmentProcess(codeRecrutment , codePrep) {
        return this.http.patch<any>(`${this.recruitingsUrl}/${codeRecrutment}/process/${codePrep}`, {});

    }

   // add_to_recrutmentProcess(userCode , result.codePrep)

   //{{host}}/back/recruiting/87a50cb4-517b-4ad6-b084-9933dab2fd80/process/{processCode}

  addDocument(codeRecrutment ,payload){
    return this.http.post<any>(`${this.recruitingsUrl}/${codeRecrutment}/document`, payload);
}
  getRecrutmentList(params): Observable<any> {
      return this.http.get<any>(`${this.recruitingsUrl}s`, {params});
  }

  cancelRecrutement(code ,payload): Observable<any> {
    //?commentary=desistement
    return this.http.delete<any>(`${this.recruitingsUrl}/${code}?commentary=${payload}`, );
  }

  finishRecrutment(code): Observable<any> {
    return this.http.put<any>(`${this.recruitingsUrl}/${code}`, {});
  }


  getRecrutmentCounter(): Observable<any> {
    return this.http.get<any>(`${this.recruitingsUrl}s/counter`);
}

  getRecrutmentById(code): Observable<any> {
    return this.http.get<any>(`${this.recruitingsUrl}/${code}`, {});
  }

  getDocumentRecrutment(code): Observable<any> {
    return this.http.get<any>(`${this.recruitingsUrl}/${code}/documents`, {});
  }

  visiblityDocument(code , codeDoc) : Observable<any> {
    return this.http.patch<any>(`${this.recruitingsUrl}/${code}/document/${codeDoc}/visiblity`, {});
  }

  confirmDocument(code , codeDoc) : Observable<any> {
    return this.http.patch<any>(`${this.recruitingsUrl}/${code}/document/${codeDoc}`, {});
  }

  deleteDocument(code , codeDoc) : Observable<any> {
    return this.http.delete<any>(`${this.recruitingsUrl}/${code}/document/${codeDoc}`, {});
  }

  getSteps (code) : Observable<any> {
    return this.http.get<any>(`${this.recruitingsUrl}/${code}/steps`, {});
  }

  getCommentStep(code , codeStep) {
    return this.http.get<any>(`${this.recruitingsUrl}/${code}/step/${codeStep}/comments`, {});
  }

  createCommentStep(code , codeStep , params){
    return this.http.post<any>(`${this.recruitingsUrl}/${code}/step/${codeStep}/comment`, params);
  }

  finishStep(code , codeStep ){
    return this.http.patch<any>(`${this.recruitingsUrl}/${code}/step/${codeStep}/close`, {});
  }

  getRecipient() : Observable<any>{
    return this.http.get<any>(`${this.backUrl}/recipients`, {});
  }

}
