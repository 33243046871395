<div class=" dialog-content-wrapper text-center p-16">
  <div matDialogTitle class="mat-accent m-0">
    <span class="title dialog-title" >{{data.action | translate}} </span>

  </div>
  <div mat-dialog-content >
       <div class="meeting mt-20">
           <mat-calendar [(selected)]="data.item.appointment" ></mat-calendar>
       </div>
    
  </div>
  <div mat-dialog-actions class="mt-16" >

    <button   mat-raised-button  class=" btn-mat btn-cancel" (click)="onNoClick()">  {{'COMMUN.CANCEL' | translate}} </button>
  </div>

</div>

