import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopSettingsComponent } from '../popSettings/popSettings.component';

@Component({
  selector: 'app-card-word',
  templateUrl: './card-word.component.html',
  styleUrls: ['./card-word.component.scss']
})
export class CardWordComponent implements OnInit  {
  @Input() word ;
  @Output() changed = new EventEmitter<string>();

  wordingList=[{content : '' ,language:'' }]
  ListLang=[{code : 'FR' , label : 'SETTINGS.FRENSH'},{code : 'EN' , label : 'SETTINGS.ENGLISH'},{code : 'DE' , label : 'SETTINGS.ALMAND'},]
    
  item = { label : '', word : ''  , lg : '' };

   
  constructor(private _matDialog: MatDialog,private settingService :SettingsService) { }

  ngOnInit() {
  }



  manageSetting( setting, mode? ){
      
    this.item.label = setting.label;
  //  this.item.lg = setting.language ;

   const dialogRef = this._matDialog.open(PopSettingsComponent, {
     data: {mode:"update" ,  item : this.item , type:'wording' , languages : this.ListLang  , action :'SETTINGS.ADD_TRANSLATE'} 
   });

   dialogRef.afterClosed().subscribe(result => {
     if(result) {  
       //console.log(result);
       
       const paylaod = {
         language : result.item.lg ,
         content :result.item.word
        }

        this.settingService.postWord(setting.code ,paylaod ).subscribe(res => {
          if(res.status === 'success') {
           this._matDialog.open(PopApiComponent ,{
             data: {
              mode:'active',
              msg: 'EMPTY.DONE' ,
              image :"./assets/icons/jobzioo-icons/check.svg"
            }  
           })
         //  this.alertify.success('done') ;
          // this.getWordingList() ;
          this.changed.emit('done')
           this.item = { label : '', word : ''  , lg : '' };

         }
        })
       }
     })

   } 




}
