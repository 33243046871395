import {Injectable, OnInit} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpHeaders
} from '@angular/common/http';
import {AlertifyService} from './alertify.service';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs/Rx';
import { MatDialog } from '@angular/material/dialog';
import { PopApiComponent } from '@fuse/components/jobZioIntegration/popApi/popApi.component';
import { DialogService } from './dialog.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor  {
jwtHelper = new JwtHelperService();
constructor(
    private router: Router,
    private alertify: AlertifyService,
    private _matDialog: MatDialog,
    private productService: DialogService
) {
}


// ngOnInit(): void {
//     this.productService.selectedProduct$.subscribe((value) => {
//       this.selectedProduct = value;
//     });
//   }


intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('jwt');
   // const locale: string = localStorage.getItem('locale');
  

    if (token && !this.jwtHelper.isTokenExpired(token)) {
        request = request.clone({
            setHeaders: {
            //  'Content-Type' : 'application/json; charset=utf-8',
            //  'Accept'       : 'application/json',
              'Authorization':token ,
           //   "Access-Control-Allow-Origin" : "*" ,

            },
          });
      
        // request = request.clone({

        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //         'Authorization': token
        //       }) ,

        //     headers: request.headers.set('Authorization', token), request.headers.set('Authorization', token)
        //    // headers: request.headers.set('Access-Control-Allow-Origin',' *'),

            
        // //    params: request.params.append('locale', locale),
        // });

    }

    return next.handle(request).catch((err: HttpErrorResponse) => {

            if (err.error instanceof Error) {
            }  


                else  if (err.status === 401) {
                    localStorage.clear() ;
                  this.alertify.error('Session expired!');
                    this.router.navigate(['/login']);
                    return;
                } 

                else if (err.status === 403) {
                    this._matDialog.open(PopApiComponent ,
                        
                        {
                        disableClose: true ,
                        data: {
                         mode:'inactive',
                         msg: 'COMMUN.ERROR_AUTH' ,
                         image :"./assets/icons/jobzioo-icons/close.svg"
                       }  
                      })
                } 

                else if (err.status === 412) {

                    this._matDialog.open(PopApiComponent ,{
                        disableClose: true ,
                        data: {
                         mode:'inactive',
                         msg: JSON.stringify(err.error.messages.parameter).slice(1, -1).replace(/(\r\n|\n|\r|,)/gm, "<br>") ,
                         image :"./assets/icons/jobzioo-icons/close.svg"
                       }  
                      })
               //  this.alertify.error(JSON.stringify(err.error.messages.parameter).slice(1, -1).replace(/(\r\n|\n|\r|,)/gm, "<br>"));
                    return;
                } 
                else  if (err.status === 404) {
                    this._matDialog.open(PopApiComponent ,{
                        disableClose: true ,
                        data: {
                         mode:'inactive',
                         msg: 'COMMUN.OBJECT_NOT_FOUND' ,
                         image :"./assets/icons/jobzioo-icons/close.svg"
                       }  
                      })
                }
                else if (err.status === 409) {


                 if(err.error.messages.parameter) {
                   
                    this._matDialog.open(PopApiComponent ,{
                        disableClose: true ,
                        data: {
                         mode:'inactive',
                        // msg: "COMMUN.CANT_CECYLCE",
                         error : JSON.stringify(err.error.messages.value).slice(1, -1) ,
                         image :"./assets/icons/jobzioo-icons/close.svg"
                       }  
                      })  

                       }else {

                        this._matDialog.open(PopApiComponent ,{
                            disableClose: true ,
                            data: {
                             mode:'inactive',
                             msg: 'COMMUN.PROBLEM_NEWORK' ,
                             image :"./assets/icons/jobzioo-icons/close.svg"
                           }  
                          })

                      //  this.alertify.error('');

                       }
                       return;
                   } 

                else {
                    this._matDialog.open(PopApiComponent ,{
                        disableClose: true ,
                        data: {
                         mode:'inactive',
                         msg: 'COMMUN.PROBLEM_NEWORK' ,
                         image :"./assets/icons/jobzioo-icons/close.svg"
                       }  
                      })
                    //this.alertify.error('Problem occurred please try again later!');
                }
                this.productService.setProduct('product');
                
                return Observable.throw(err.message);

                // return Observable.of(new HttpResponse({
                //     body: [
                //         {name: 'Default values returned by Interceptor', id: 88},
                //         {name: 'Default values returned by Interceptor(2)', id: 89}
                //     ]
                // }));
        
         
       // });
       
//}




}) 
}

}


