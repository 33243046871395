import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-card-order',
  templateUrl: './card-order.component.html',
  styleUrls: ['./card-order.component.scss']
})
export class CardOrderComponent implements OnInit  {
  @Input() service ;
  @Input() orderr =''
  constructor() { }

  ngOnInit() {
  }



  getTotal(pack){
    return pack.packs+pack.products
  } 


  returnDate(date) {    
    return  moment(date).format('DD/MM/YYYY'); 
  }
  
}
