import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popVedio',
  templateUrl: './popVedio.component.html',
  styleUrls: ['./popVedio.component.scss']
})
export class PopVedioComponent implements OnInit {

   
  constructor(
    private dialogRef: MatDialogRef<PopVedioComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {
 //   
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
