<div class=" maria-integration dialog-content-wrapper text-center">
  <div matDialogTitle class="mat-accent m-0">
    <span class="title dialog-title" >{{data.action | translate}} </span>

  </div>
  <div mat-dialog-content>

    <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 0">


          <mat-form-field appearance="outline" *ngIf="data.type!== 'terms' &&   data.type !== 'terms-service'  &&   data.type !== 'wording' &&   data.type !== 'city' ">
            <mat-label>{{'COMMUN.LABELL' | translate}}</mat-label>
            <input matInput [(ngModel)]="data.item.label" required />
          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="data.type === 'wording' " class="textarea">
            <mat-label>{{'COMMUN.LABELL' | translate}}</mat-label>
            <textarea matInput disabled [(ngModel)]="data.item.label" required  ></textarea>

          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="data.type=== 'wording' " class="textarea">
            <mat-label>{{'SETTINGS.WORD' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="data.item.word" required  ></textarea>
          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="data.type === 'wording'">
            <mat-label>{{'SETTINGS.LANGUAGES' | translate}}</mat-label>
            <mat-select  required class="roles" [(ngModel)]="data.item.lg" [placeholder]="'SETTINGS.LANUAGES' | translate">
              <mat-option *ngFor="let rl of data.languages " [value]="rl.code">
                {{rl.label | translate}}
              </mat-option>
            </mat-select>

          </mat-form-field>



          <mat-form-field appearance="outline" *ngIf="data.type === 'comment'">
            <mat-label>{{'SERVICES.RECIPIENT' | translate}}</mat-label>
            <mat-select  required class="roles" [(ngModel)]="data.item.recipient" [placeholder]="'SERVICES.RECIPIENT' | translate">
              <mat-option *ngFor="let rl of data.recipients " [value]="rl.code">
                {{rl.label}}
              </mat-option>
            </mat-select>

          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="data.type === 'terms'">
            <mat-label>{{'SERVICES.RECIPIENT' | translate}}</mat-label>
            <mat-select  required class="roles" [(ngModel)]="data.item.recipient" [placeholder]="'SERVICES.RECIPIENT' | translate">
              <mat-option *ngFor="let rl of ['partner' , 'candidate'] " [value]="rl">
                {{rl}}
              </mat-option>
            </mat-select>

          </mat-form-field>


          <mat-form-field appearance="outline" *ngIf="data.type=== 'terms' || data.type=== 'terms-service' " class="textarea">
            <mat-label>{{'SERVICES.DESCRIPTION' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="data.item.content" required ></textarea>
          </mat-form-field>




          <div *ngIf="data.type === 'city'">
            <mat-form-field appearance="outline">
              <mat-label>{{'SETTINGS.NAME'  | translate}}</mat-label>
              <input matInput [(ngModel)]="data.item.name" required />

              <!-- <mat-select  required class="roles"  (selectionChange)="getDistrict($event )"  [placeholder]="'SETTINGS.FEDERALS' | translate">
                <mat-option *ngFor="let rl of data.federals " [value]="rl">
                  {{rl}}
                </mat-option>
              </mat-select> -->

            </mat-form-field>
          </div>

          <div *ngIf="data.type === 'city'">
            <mat-form-field appearance="outline">
              <mat-label>{{'SETTINGS.LONG' | translate}}</mat-label>
              <input matInput [(ngModel)]="data.item.long" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required />


            </mat-form-field>
          </div>

          <div *ngIf="data.type === 'city'">
            <mat-form-field appearance="outline">
              <mat-label>{{'SETTINGS.LAT' | translate}}</mat-label>
              <input matInput [(ngModel)]="data.item.lat"  onkeypress="return event.charCode >= 48 && event.charCode <= 57" required />
            </mat-form-field>
          </div>


          <div *ngIf="data.type === 'jobtype'">
            <mat-form-field appearance="outline">
              <mat-label>{{'COMMUN.COLOR' | translate}}</mat-label>
              <input matInput type="color" [(ngModel)]="data.item.color" required />
            </mat-form-field>
          </div>


          <div *ngIf="data.type === 'pack'">
            <mat-form-field appearance="outline">
              <mat-label>{{'SERVICES.PRODUCTS' | translate}}</mat-label>
              <mat-select multiple required class="roles"   [(ngModel)]="data.item.products" (ngModelChange)="data.item.products" [placeholder]="'SERVICES.PRODUCTS' | translate">
                <mat-option *ngFor="let rl of data.products " [value]="rl.code">
                  {{rl.label}}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>


          <mat-form-field appearance="outline" *ngIf="data.type === 'product'  " class="textarea">
            <mat-label>{{'SERVICES.SERVICE_TERMS' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="data.item.tc" required ></textarea>
          </mat-form-field>


             <mat-form-field appearance="outline" *ngIf="data.type === 'product' || data.type === 'pack' " class="textarea">
            <mat-label>{{'SERVICES.DESCRIPTION' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="data.item.description" required ></textarea>
          </mat-form-field>



          <div *ngIf="data.type === 'workFlow'">
            <mat-form-field appearance="outline">
              <mat-label>{{'SETTINGS.JOBTYPE' | translate}}</mat-label>
              <mat-select required class="roles" [(ngModel)]="data.item.jobtype" [placeholder]="'SETTINGS.JOBTYPE' | translate">
                <mat-option *ngFor="let rl of data.jobtypes " [value]="rl.code">
                  {{rl.label}}
                </mat-option>
              </mat-select>

            </mat-form-field>

            <mat-form-field appearance="outline">
          <mat-select multiple="" class="roles" required  [(ngModel)]="data.item.domains"  [placeholder]="'COMMUN.DOMAINE'| translate">

          <mat-optgroup *ngFor="let domain of data.domainList" [label]="domain.text">

            <mat-option *ngFor="let sdemain of domain.options" [value]="sdemain.id">
              {{sdemain.text}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
        </mat-form-field>
    </div>


        <div *ngIf="data.type === 'country'  || data.type === 'language' ">
          <mat-form-field appearance="outline">
            <mat-label> {{'COMMUN.SHORTCODE' | translate}}</mat-label>
            <input matInput maxlength="3" [(ngModel)]="data.item.shortcode" required>
          </mat-form-field>
        </div>


        <div *ngIf="data.type === 'step-workflow' ">
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.COUNTRY' | translate}}</mat-label>
            <mat-select  class="roles" multiple [(ngModel)]="data.item.countriesList" [placeholder]="'SETTINGS.COUNTRIES' | translate">
              <mat-option *ngFor="let rl of data.countries " [value]="rl.code">
                {{rl.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="data.type === 'agency' ">
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.COUNTRY' | translate}}</mat-label>
            <mat-select required class="roles" [(ngModel)]="data.item.country" [placeholder]="'SETTINGS.COUNTRIES' | translate">
              <mat-option *ngFor="let rl of data.countries " [value]="rl.code">
                {{rl.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="data.type === 'agency' ">
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.CITY' | translate}}</mat-label>
            <input required matInput [(ngModel)]="data.item.city" [placeholder]="'COMMUN.CITY' | translate">
          </mat-form-field>
        </div>

        <div *ngIf="data.type === 'agency' ">
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.ADDRESS' | translate}}</mat-label>
            <input  required matInput [(ngModel)]="data.item.address">
          </mat-form-field>
        </div>

        <div *ngIf="data.type === 'agency'  || data.type === 'city' ">
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.ZIPCODE' | translate}}</mat-label>
            <input matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57" required [(ngModel)]="data.item.zip_code">
          </mat-form-field>
        </div>


        <div  *ngIf=" data.type === 'country'  || data.type === 'language' ">
          <button mat-raised-button class="btn-mat bnt-mail bnt-upload  " type="button" mat-raised-button
            (click)="flag.click()">{{'COMMUN.CHOOSEFLAG' | translate}}</button>
          <input hidden (change)="onFileSelected($event)" #flag type="file" id="file">
          <div *ngIf="srcResult" class="dashed-image mt-20">
            <img class="image" [src]="srcResult " alt="">
          </div>

        </div>
      </div>

  </div>
  <div mat-dialog-actions>
    <button *ngIf="data.type !== 'partner'" mat-raised-button  class=" btn-mat active  mb-16"
    [ngClass]="{ 'disabled' : ( data.item.tc===''  && data.item.description==''  && data.item.label===''   ) ||  ( data.type === 'comment' && data.item.recipient=== '' &&  data.item.label === '' ) }"
    [disabled]="   ( data.item.tc===''  && data.item.description==''  && data.item.label===''   ) ||  ( data.type === 'comment' && data.item.recipient=== '' &&  data.item.label === '' )  "


    [mat-dialog-close]="data" cdkFocusInitial aria-label="SAVE">
      {{'COMMUN.CONFIRM' | translate}}
    </button>
    <br>
    <button   mat-raised-button  class=" btn-mat btn-cancel" (click)="onNoClick()">  {{'COMMUN.CANCEL' | translate}} </button>
  </div>

</div>
