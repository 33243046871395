export const locale = {
    lang: 'fr',
    data: {
        ICON: {
            REFRESH: 'Rafraichir',
            ADD: 'Ajouter',
            EDIT: 'Editer',
            DELETE: 'Supprimer',
            ACCEPT: 'Accepter',
            DECLINE: 'Rejeter',
            VIEW: 'Détails',
            BANNED: 'Banni',
            PREPARE_DOSSIER: 'Preparer un dossier',
            BAN: 'Bannir',
            UNBAN: 'Débannir',
            CANCEL: 'Annuler',
            STEPS: 'Etapes',
            FINISH_STEP: "Finialiser l'etape",
            PRIVATE: 'Privé',
            PUBLIC: 'Public',
            APPROVE: 'Approuver',
            CANCEL_PROPOSITION: 'Annuler proposition',
            ADD_COMMENT: "Ajouter un commentaire",
            ADD_USER: 'Ajouter un utilisateur',
            EDIT_USER: 'Editer un utilisateur',
            VIEW_USER: 'Détails utilisateur',
            VIEW_RECRUITING: 'Détails recrutement',
            VIEW_JOS: 'Détails offres',
            VIEW_JO: 'Détails offre',
            COPY_JO: "Copier l'offre",
            EXPIRE_JO: "Expirer l'offre",
            CANCEL_JO: "Annuler l'offre",
            EDIT_JO: "Editer l'offre",
            ADD_WHISHLIST: 'Ajouter à la liste des souhaits',
            REMOVE_WHISHLIST: 'Retirer de la liste des souhaits',
            INVITE_CANDIDATE: 'Inviter le candidat',
            CANCEL_INVITATION: 'Annuler invitation',
            VIEW_CANDIDATE: 'Détails candidat',
            VIEW_COMMENT: 'Voir commentaire',
            APPROVE_DOCUMENT: 'Confirmer document',
            DELETE_DOCUMENT: 'Supprimer document',
            ADD_DOCUMENT: 'Ajouter document',
            ACCEPT_APPLICATION: 'Accepter application',
            DECLINE_APPLICATION: 'Rejeter application',
            ACCEPT_PROPOSITION: 'Accepter proposition',
            DECLINE_PROPOSITION: 'Rejeter proposition',
            ACCEPT_MATCH: 'Accepter match',
            DECLINE_MATCH: 'Rejeter match',
            ACCEPT_INTERVIEW: 'Accepter entrevue',
            DECLINE_INTERVIEW: 'Rejeter entrevue',
            ADD_EXPERIENCE: 'Ajouter experience',
            DELETE_EXPERIENCE: 'Supprimer experience',
            ADD_LANGUAGE: 'Ajouter langue',
            DELETE_LANGUAGE: 'Supprimer langue',
            ADD_BENEFITS: 'Ajouter benefice',
            DELETE_BENEFITS: 'Supprimer benefice',
            UNPUBLISH_JO: "Dépublier l'offre",
            ASSIGN_RECRUTMENT: 'Assigner un dossier'
        },
        COMMUN: {
            BROSO_LOG0: 'Veuillez télécharger le Logo',
            BROSO_PRESENTATION: 'Veuillez télécharger votre Présentation en Vidéo',
            BROSO_SIGNATURE: 'Veuillez télécharger votre Signature',
            BROSO_PHOTO: 'Veuillez télécharger votre Photo',
            BROSO_PASSPORT: 'Veuillez télécharger votre Passeport',
            SEE_MORE: 'Voir plus',
            VIDEO: 'Vidéo',
            SHOW_DOCUMENT: "Voir les documents",
            EDIT_CV: 'Modifier le CV',
            EMTY_CITY: 'pas de ville (Tapez 3 caractères puis entrer)',
            RADIUS: 'Périmètre',
            YOU_HAVNT_CANDIDATE: `Vous n'avez pas de candidats spécialisés dans ce domaine `,
            SURE_HIDE_FROM_CV: 'Confirmez-vous le masquage de cette section du CV ?            ',
            CAN_NOT_HAVE_DOSSIER: "Vous n'avez pas encore de dossier de préparation au recrutement",

            SELECT_PREPARING_DOSSIER: 'Attribuer le dossier de préparation correspondant',
            FINISHED: 'Terminé',
            RECRUTMENT_NAME: 'Recrutement ',

            NO_SPECIALTY: "Aucune Specialité",
            STATEE: 'Etat',
            MY_JOBZIOO: 'My Jobzioo',
            CREATE_NEW_WORKFLOW: "Créer un nouveau flux de travail  ",
            UPDATE_WORKFLOW: "Modifier le flux de travail ",
            DOWNLOAD_CV: 'Veuillez télécharger votre CV',
            MESSAGES: 'Message',
            PRESENTATION_TYPE: 'Type de présentation',
            MAX_SIZE: 'Taille Maximale',
            UPLOAD_COMPANY: 'Veuillez télécharger le Logo de votre entreprise',
            UPLOAD_SIGNATURE: 'Veuillez télécharger votre Signature',
            UPLOAD_PHOTO: 'Veuillez télécharger votre Photo',
            LOGO_TYPE: 'Le Logo doit être en format PNG ou JPEG',
            SIGNATURE_TYPE: 'La signature doit être en format PNG ou JPEG',
            PHOTO_TYPE: 'La photo doit être en format PNG ou JPEG ',
            UPLOAD_PASSPORT: 'Veuillez télécharger votre Passeport',
            RETURN_LOGIN: 'Se connecter',
            TOTAL_RECRUTMENTS: 'Total de Recrutement',
            OF: 'de',
            AT: 'à',
            ON: 'Sur',
            YOUR_EMAIL: 'Veuillez saisir votre e-mail',
            SEND: 'Envoyer',
            FORGET_PASSWORD: 'Oublier le Mot de passe',
            TERMS_CONDITIONS: 'Termes & Conditions',
            YOU_CAN_NOT_USE: 'Veuillez accepter les Termes et Conditions pour accéder à la plateforme Jobzioo',
            APPROVED_LOGG: `Veuillez attendre votre mail d"approbation pour accéder à la plateforme Jobzioo`,
            SURE_DECLINE_FROM_CV: 'Confirmez-vous que vous voulez refuser?',

            I_ACCEPT_TERMS: 'J"accepte les Termes & Conditions',
            EXIST: 'Annuler',
            SUBMIT_CV: 'Soumettre le CV',
            FAVORIS_JOBS: 'Mes favoris',
            MATCH_JOBS: 'Mes Matchs',
            APPLY_JOBS: 'Mes demandes d"emploi',
            APPLY_JOB: 'Postuler pour un Job',
            SUBJECT: 'Sujet',
            CONTUNUE: 'Continuer',
            BROSE: 'Parcourir',
            SELECT_JOB_OFFER: 'Sélectionner l"offre d"emploi',
            FINISH: 'Terminer',
            CHECK_YOUR_APPOINTMENT: 'Veuillez vérifier votre rendez-vous',
            CHECK: 'Vérifier',
            UPLOAD: 'Télécharger',
            UPLOAD_DOCUMENTS: 'Veuillez télécharger vos documents',
            UPLOAD_DOCUMENT: 'Veuillez télécharger votre document',
            CHANGE_ATTACHMENT: 'Modifier la Pièce jointe',
            DASHBOARD: 'Tableau de bord',
            SELCTE_INTERRVIEW_DATE: "Veuillez choisir une date pour l'interview",
            SUR_PUBLISH: "Confirmez-vous que vous souhaitez publier cette offre d'emploi?",
            SURE_PORCHASE_ORDER: 'Confirmer mon achat ',
            SURE_APPLIED_JOB: 'Confirmer ma requête',
            SURE_CANCEL_APPLICATION: 'Confirmez-vous que vous souhaitez annuler cette application?',
            SURE_DECLINE_INTERVIEWD: 'Confirmez-vous que vous souhaitez refuser cet interview? ',
            SURE_ACCEPT_INTERVIEWD: 'Confirmez-vous que vous souhaitez accepter cet interview?',
            SURE_VISIBLITY_DOCUMENT: 'Confirmez-vous le changement de visiblité de ce document?',
            SURE_CONFIRM_DOCUMENT: 'Confirmez-vous ce document?',
            SURE_DELETE_DOCUMENT: 'Confirmez-vous que vous souhaitez supprimer ce document?',
            SURE_CANCEL_INVITATION: 'Confirmez-vous que vous souhaitez annuler cette invitation?',
            SURE_CANCEL_ORDER: 'Confirmez-vous que vous souhaitez annuler cette commande?',
            SURE_ACCEPT_APPLICATION: 'Confirmez-vous que vous souhaitez accepter cette application?',
            SURE_DECLINE_APPLICATION: 'Confirmez-vous que vous souhaitez annuler cette application? ',
            SURE_RESET_PASSWORD: 'Confirmez-vous que vous souhaitez réinitialiser votre mot de passe ? ',
            SUR_UNPUBLISH: 'Confirmez-vous que vous souhaitez annuler la publication de cette offre d"emploi?',
            SURE_CANCEL_JOB: 'Confirmez-vous que vous souhaitez annuler cette offre d"emploi? ',
            SURE_EXPIRED_JOB: 'Confirmez-vous que cette offre d"emploi est expirée?',
            SURE_ACCEPT_INVITATION: 'Confirmez-vous que vous souhaitez accepter cette invitation?',
            SURE_RESET: 'Confirmez-vous que vous souhaitez réinitialiser votre mot de passe ??',
            SELECT_CANDIDATE: 'Veuillez sélectionner un candidat',
            SELECT_NAME: 'Chercher',
            DOCUEMNT_BY_CANDIDATE: 'Documents fournis par le candidat',
            DOCUEMNT_BY_PARTNER: 'Documents fournis par le partenaire',
            ADDWORKFLOW: 'Ajouter un nouveau flux de travail',
            SURE_CANCEL_RECRUTMENT: "Confirmez-vous l'annulation de ce recrutement ?",
            SURE_CANCEL_PREPARING: "Confirmez-vous l'annulation de cette préparation du dossier ?",
            HI: 'Salut',
            TITLE: 'Titre',
            THE_COMMENTS: 'Commentaires',
            MEET_WITH: 'Réunion avec',
            ALIAS: 'Alias',
            ALIAS_JOBOFFRE: 'Pseudo-offre d"emploi',
            INVITEDBY: 'Invité par',
            APPLIED_JOBS: 'Emplois appliqués',
            ALIAS_PARTNER: 'Pseudo-partenaire',
            CHOOSE_DATE: 'Veuillez sélectionnez la date et l"heure',
            ALIAS_CANDIDATE: 'Pseudo-candidat',
            YOU_HAVE_WORK: "On a beaucoup de travail pour aujourd'hui! Alors, commençons.",
            YOU_HAVE_NOTI1: "Vous avez",
            YOU_HAVE_NOTI2: "Nouvelle notification",
            FILL: 'Créer mon CV',
            FILL_PLEASE: 'Commençons par remplir votre CV et n’oubliez pas d’ajouter vos informations détaillées',
            NEW_PARTNERS: 'Nouveaux partenaires',
            NEW_JOBS: 'Nouveaux emplois',
            CREATED_AT: 'Créé à',
            NEW_CANDIDATE: 'Nouveaux candidats',
            NEW_RECRUTMENT: 'Nouvelles embauches',
            NEEDS_BE_VIEWD: 'à vérifier',
            TOTAL_PARTNER: 'Total des partenaires',
            TOTAL_JOBS: 'Total des emplois',
            TOTAL_JOBS_OFFRES: 'Total des offres d’emploi',
            TOTAL_INTERVIEWD: 'Total des interviews',
            TOTAL_PUBLISHED_JOBS: 'Total d’emplois publiés',
            FAVORIS_JOB: 'Mes favoris',
            APPLIED_JOB: 'Mes applications',
            PROPOSED_JOBS: 'Emplois proposés',
            TOTAL_MATCHED_JOB: 'Nombre total d’emplois jumelés',
            TOTAL_PENDING_JOBS: 'Total des emplois en attente',
            TOTAL_APPLIED: 'Total appliqué',
            TOTAL_MATCHED: 'Total des profils jumelés',
            TOTAL_CANDIDATE: 'Total des candidats',
            INCOMMING_MEETING: 'Réunion à venir',
            VIEW_ALL: 'Afficher tout',
            PUBLISH: 'Publier l"offre d"emploi',
            UNPUBLISH: "Annuler la publication de l'offre d'emploi",
            APPOINTMENT: 'Date du rendez-vous',
            DATE: 'Date',
            STATELABELL: 'Ajouter un label',
            CONFIRM: 'Confirmer',
            PRIVATE: 'Privé',
            SHORTCODE: 'Short code',
            CREATE_ITEM: 'Créer un élément',
            UPDATE_ITEM: "Mettre à jour l'élément",
            MAKEARAISON: 'Mentionner la raison',
            RAISON: 'Voir les raisons',
            MAKEACOMMENTARY: 'Ajouter un commentaire',
            SEARCH: 'Chercher',
            ACCEPT: 'Accepter',
            DECLINE: 'Refuser',
            ACCEPT_CV: 'Accepter le CV',
            DECLINE_CV: 'Refuser le CV',
            APPROVED: 'Applications',
            APPROVE: 'Approuver',
            BAN: 'Bannir',
            CHECKED: 'Vérifié',
            DECLINED: 'Refusé',
            CANCELED: 'Annulé',
            PENDING: 'En cours',
            UPDATE: 'Mettre à jour ',
            DELETE: 'Supprimer',
            ADD: 'Ajouter',
            AGE: 'âge',
            FIRSTNAME: 'Prénom',
            LASTNAME: 'Nom',
            FULLNAME: 'Nom & Prénom',
            MAIL: 'Adresse mail',
            MAIL_PARTNER: 'Adresse mail Partenaire',
            ADDRESS: 'Adresse',
            CITY: 'Ville',
            COUNTRY: 'Pays',
            YEARS: 'Années',
            ERROR_AUTH: `Votre nom d'utilisateur ou votre mot de passe sont invalides.`,


            ////////////////////////////////////////////////////////////

            SIGNATURE: 'Signature',
            IMG: 'Image',
            FEMALE: 'Femme',
            MALE: 'Homme',
            PROGRESS_UPLOAD: 'Téléchargement en cours',
            UPLOAD_MEDIA: 'Télécharger les fichiers',
            DIVORCED: 'divorcé(e)',
            WIDOWED: 'veuf(ve)',
            SINGLE: 'Célibataire',
            MARIED: 'Marié(e)',

            FILE_MAX_SIZE: `Impossible de télécharger ces fichiers. La taille est plus que 400 Mo`,

            ////////////////////////////////////////////////////////////


            SUBMITTEDDATE: 'Date de soumission',
            ZIPCODE: 'Code ZIP',
            ACTIVE: 'Activer',
            DESACTIVE: 'Desactiver',
            LOGO: 'Logo',
            LOGO_UPLOAD: 'Veuillez télécharger votre Logo  ',
            LOGIN: 'Se connecter',
            REGISTER: "S'inscrire",
            CREATE_ACCOUNT: 'Créer un compte',
            PASSWORD: 'Mot de passe',
            CONFIRM_PASSWORD: 'Confirmer le Mot de passe',
            PHONE: 'Téléphone',
            SPECIALITY: 'Spécialité',
            Departement: 'Département',
            CREATEDAT: 'Créé à: ',
            EDIT: 'Modifier',
            SAVE: 'Enregistrer',
            UPDATE_PROOF: 'Mettre à jour la preuve',
            IDENTITY_VALIDATION: "Validation d'identité",
            NEW: 'Nouveau',
            AGENCY: 'Agences',
            ACCEPTED: 'Accepté(s)',
            GROUP: 'Groupe',
            PROFESSION: 'Informations Professionnelles',
            GENRALINFO: 'Informations Générales',
            PASSPORT: 'Passport',
            Email: 'Adresse Mail',
            Phone: 'Numéro de Téléphone',
            PHOTO: 'Photo de Profil',
            address: 'Adresse',
            Contact: 'Contact',
            DOMAINE: 'Domaine',
            NATIONALITY: 'Nationalité',
            GENDER: 'Sexe',
            FULL_NAME: 'Nom et Prénom',
            GENERAL_INFO: 'Informations Générales ',
            ExeperiencesEducation: 'Parcours Scolaire',
            Languages: 'Langues',
            JOBTYPE: "Type d'Emploi ",
            ADDTEAM: 'Ajouter une équipe',
            EDITTEAM: "Modifier l'équipe",
            CONTACT: 'Contact',
            CLOSE: 'Fermer',
            COMMENT: 'Ajouter un commentaire',
            YES: 'Oui, tout à fait.',
            NO: 'Non, pas vraiment ',
            DEPARTMENT: 'Département',
            GET_COMMENTS: 'Obtenir des Commentaires',
            MEDIA: 'Média',
            HISTORY: ' Historique du Candidat',
            DATE_BEGIN: 'Date de Début',
            DATE_END: 'Date de Fin',
            NUMBER_PRODUCT: 'Numéro de Produit',
            CANCEL: "Annuler",
            LABELL: "Label",
            STATE: "Statut",
            DATE_VALIDITY: "Date de validité",
            STATUS: "Statut",
            POSITION: "Statut",
            COMPANY: "Entreprise",
            DOCUMENTS: "Documents",
            STEPS: "Etapes",
            OTHERS: 'Autres',
            TOTALMATCH: 'Total des Matchs',
            TOTALPUBLISH: 'Total des offres publiées',
            SEND_EMAIL: 'Envoyer le Mail',
            SHEDLE_MEETING: 'Planifier une réunion',
            NEWUSER: 'Créer un nouvel utilisateur',
            VIEW_CV: 'Voir cv',
            VIEW_VEDIO: 'Voir Vidéo',
            ATTACHEMENTS: 'Pièces jointes',
            PARAGRAPHE: 'Connecter les talents du futur.',
            NEWTEAM: 'Nouvelle équipe',
            CHOOSELOGO: 'Choisir le Logo',
            CHOOSEFLAG: 'Choisir Drapeau',
            CHOOSESIGNATURE: 'Veuillez télécharger la Signature',
            CHOOSEPHOTO: 'Veuillez télécharger la Photo',
            CHOOSEPRESENTATION: 'Veuillez télécharger la Présentation',
            CHOOSEPASSPORT: 'Veuillez télécharger le Passeport',
            BIRTHDAY: 'Date de Naissance',
            AGENCYNAME: 'Agence',
            OPTIONS: 'Options',
            RESET: 'Réintaliser le Mot de passe',
            OK: 'Ok',
            PRESENTATION: 'Présentation',
            MARITALSTATUS: 'état civil',
            SHOWPROOF: 'Veuillez télécharger la preuve',
            INVITATION: 'Invitations',
            PROPOSITION: 'Proposition',
            APPLICATION: 'Application',
            INTERVIEWD: 'Interviews',
            MATCHED: 'Matché(s)',
            SURE_ABLE: 'Confirmez-vous que vous souhaitez activer cette fonction? ',
            SURE_INABLE: 'Confirmez-vous que vous souhaitez désactiver cette fonction? ',
            SHOW_APPOINMENT: 'Voir Rendez-vous',
            SURE_ACCEPT_CV_OF: 'Confirmez-vous que vous souhaitez accepter ce CV?',
            SURE_DECLINE_CV_OF: 'Confirmez-vous que vous souhaitez refuser ce CV?',
            SURE_CANCEL_PROPOSE: 'Confirmez-vous que vous souhaitez annuler cette proposition?',
            SURE_FINISH_STEP: 'Confirmez-vous que vous voulez terminer cette étape?',
            OBJECT_NOT_FOUND: 'Objet introuvable',

            PARTNER_BANNED_REASONS: 'Ce partenaire est banni pour les raisons suivantes:',
            CANDIDATE_BANNED_REASONS: 'Ce candidat est refusé pour les raisons suivantes:',
            APPROVED_PARTNERS: 'Partenaires approuvés',
            T0_APPROVE_PARTNERS: 'Partenaires à approuver',
            PUBLISH_JOB: 'Emplois publiés',

            PROBLEM_NEWORK: 'Un problème est survenu, veuillez réessayer plus tard !',
            CANT_CECYLCE: `Déjà utilisé`,

            TO_APPROVE_PARTNERS: 'Partenaires à approuver',


            SURE_DISABLE_FROM_TEAM: 'Confirmez-vous que vous souhaitez désactiver cette équipe ?',
            SURE_ABLE_FROM_TEAM: ' Confirmez-vous que vous souhaitez activer cette équipe? ',
            SURE_ACCCEPT_TO_CV: 'Êtes-vous sûr d’accepter cela dans le CV?',
            SURE_BAN_IT: 'Confirmez-vous que vous souhaitez bannir ce partenaire?',
            SURE_APPROVE_IT: 'Confirmez-vous que vous souhaitez accepter ce partenaire?',
            SURE_DISABLE_IT: 'Confirmez-vous que vous souhaitez désactiver ce groupe ?',
            SURE_ABLE_IT: 'Confirmez-vous que vous souhaitez activer ce groupe ?',
            SURE_DECLINE_CV: 'Confirmez-vous que vous souhaitez refuser ce CV?',
            SURE_ACCEPT_CV: 'Confirmez-vous que vous souhaitez accepter ce CV?',
            SUR_CANCEL_CANDIDATE: "Confirmez-vous l'annulation de ce candidat?",
            SUR_ACCEPT_CANDIDATE: "Confirmez-vous l'acceptance de de candidate?",
            ADD_COMMENT: 'Ajouter un commentaire',
            ADD_PRICE_BY_COUNTRY: 'Fixer le Prix par pays',
            LIST_PRICE_BY_COUNTRY: 'Fixer le Prix par pays ',
            PRICE: 'Prix',

            APPOIEMENT_DATE: 'Date de rendez-vous',

            ADD_STEP: 'Ajouter une nouvelle étape',
            UPDATE_STEP: "Mettre à jour l'étape",

            DECLINE_PROOF: 'Confirmez-vous que vous voulez refuser cette preuve?',
            ACCEPT_PROOF: 'Confirmez-vous que vous voulez accepter cette preuve? ',


            WORKFLOW: 'Flux de travail',

            READ_MORE: 'Lire plus',

            BROSO_LOGO: 'Parcourir Logo',


            RESOLUTION: 'Résolution',

            YOU_HAVNT_JOB: 'Aucun poste disponible pour inviter le candidat',
            OPS: 'Ops',
            ADD_JOB: 'Ajouter un Emploi',


        },
        JOBOFFERS: {
            SAVE_AS_DRAFT: 'Enregistrer comme Brouillon',
            JOBINFO: 'Infos sur l’offre d’emploi',
            REQUIRMENTS: 'Critères',
            OPTIONS: 'Exigences',
            ADD_NEW: 'Ajouter une nouvelle offre d’emploi',
            JOBOFFER: 'Offre d’emploi',
            JOBOFFERS: 'Offres d’emploi',
            JOBTITLE: 'Titre',
            JOBTYPELABEL: 'Type de poste',
            CREATEDBY: 'Créé par',
            CREATEDAT: 'Créé à',
            DOMAINLABEL: 'Domaine',
            RECOGNITION: 'Reconnaissance',
            DESCRIPTION: 'Description',
            POSITION: 'Position',
            SALARYRANGE: 'Fourchette salariale ',
            DURATION: 'Durée',
            WORKTIME: 'Temps de travail',
            STATUS: 'Statut',
            ZIPCODE: 'Code ZIP',
            ADD: 'Ajouter',
            CREEATEDAT: 'Créé à',

            CITYLABEL: 'Ville',
            PARTNERCREATOR: 'Créé par',
            BACKUPDATENAME: 'Mettre à jour',
            BENIFITS: 'Avantages',
            BENIFITSLIST: 'Vos Avantages',
            EXPERIENCES: 'Expériences',
            LANGUAGES: 'Langues',
            DEGREE: 'Diplôme',
            DRAFT: 'Brouillon',
            PENDING: 'En suspens',
            PUBLISHED: 'Publié',
            EXPIRED: 'Expiré',
            CANCELED: 'Annulé',
            UNPUBLISH: 'Non Publié',
            AT_LEAST_THREE: 'Veuillez mentionner au minimum 3 avantages ',
            NEXT: 'étape suivante',
            PREV: 'étape précédente',
            PUBLISH_JOB: 'Publier l’offre d’emploi ',



        },
        PARTNERS: {
            USERS: 'Equipe',
            PARTNERS: 'Partenaires',
            BANNED: 'Partenaires Bannis',
            JOBOFFERS: "Offres d'emploi",
            COMPANY: 'Entreprise',
            POSITION: 'Position',
            REGISTRY: "Numéro d'entreprise",
            PHONE: 'Numéro de Téléphone',
            NEW: 'Nouveau',
            APPROVED: 'Approuvé',
            TO_APPROVE: 'à approuvé',
            DECLINED: 'Refusé',
            EMPLYEERANGE: 'Refusé',
            EMPLOYEERANGE: 'Nombre approximatif des employés',
            COMPNAY_INFO: "Infos de l'Entreprise",
            EDIT: 'Modifier',
            DOMAIN: 'Domaines',
            ACCEPTED: 'Accepté',
            PARTNER: 'Partenaire',
            RECRUTMENT_CANDIDATES: 'Candidats au recrutement',
            RAISONS_BANNED: 'Raisons du Ban',
            COMPANY_INFO: "Infos de l'Entreprise",
            DETAILS: 'Détails',


        },
        GROUPS: {
            GROUPS: 'Groupes',
            ROLES: 'Rôles',
            ROLE: 'Rôle',
            CODE: 'Code',
            NEWGROUP: "Créer un nouveau groupe",
            EDITGROUP: "Modifier le groupe"
        },

        SETTINGS: {
            NAME: 'Nom',
            LONG: 'Longitude',
            LAT: 'Latitude',
            ADD_TRANSLATE: 'Ajouter une traduction',
            WORD: 'Mot',
            ALMAND: "DE",
            ENGLISH: "EN",
            FRENSH: "FR",
            WORDING: 'Formulation',
            TERMS_CONDITIONS: 'Termes & Conditions',
            ADD_SERVICE_TERMS: 'Ajouter SGV',
            SETTINGS: 'Paramétres',
            DOMAIN: 'Liste des Domaines',
            AGENCY: 'Liste des Agences',
            LANGUAGES: 'Liste des Langues',
            JOBTYPE: "Liste des Types d'emplois",
            DEGREE: 'Liste des Diplômes',
            EXPERIENCE: 'Liste des Expériences',
            COUNTRIES: 'Liste des Pays',
            CITIES: 'Liste des Villes',
            DOMAINS: 'Domaines',
            SUBDOMAINS: 'Sous Domaines',
            SKILLS: 'Spécialités',
            UPDATE_COUNTRY: 'Mettre à jour Pays',
            ADD_COUNTRY: 'Ajouter Pays ',
            UPDATE_CITY: 'Mettre à jour Ville',
            ADD_CITY: 'Ajouter Ville ',
            UPDATE_JOBTYPE: "Mettre à jour le Type d'emploi",
            ADD_JOBTYPE: "Ajouter Type d'emploi",
            UPDATE_AGENCY: 'Mettre à jour Agence',
            ADD_AGENCY: 'Ajouter Langue',
            UPDATE_LANGUAGE: 'Mettre à jour Langue',
            ADD_LANGUAGE: 'Ajouter Langue',
            UPDATE_EXPERIENCE: 'Mettre à jour Expérience',
            ADD_EXPERIENCE: 'Ajouter Expérience',
            UPDATE_DEGREE: 'Mettre à jour Diplôme',
            ADD_DEGREE: 'Ajouter Diplôme',
            UPDATE_DOMAIN: 'Mettre à jour Domaine',
            ADD_DOMAIN: 'Ajouter Domaine',
            ADD_SPECIALITY: 'Ajouter Spécialité',
            UPDATE_SPECIALITY: 'Mettre à jour Spécialité',
            ADD_SUB_DOMAIN: 'Ajouter Sous Domaine',
            UPDATE_SUB_DOMAIN: 'Mettre à jour Sous Domaine',
            ADD_GENRAL_TERMS: 'Ajouter Termes Générales',
            DISTRICTS: 'Quartier',
            TYPE: 'Type',
            ZIP_CODE: 'Code postal',

        },
        ORDERS: {
            ORDERS: 'Commandes',
            PRICE: 'Prix',
            TOTAL_ORDER: 'Totale de commandes',
        },

        USERS: {
            USERS: 'Equipe',
        },
        CANDIDATE: {
            EPHEMERAL_LINK: "Lien éphémère",
            EXPIRED_AT: "Expire le",
            SELECT_DOCUMENTS: "Selectionner les documents",
            BACK_DATASHEET: "Retour a la fiche candidat",
            CREATE_LINK: "Créer un lien",
            RECOGNITION: "Reconnaissance",
            RAISONS_DECLINE: 'Motifs de refus',
            SEARCH: 'Prénom, Nom',
            SCHOOL: 'Ecole',
            FROM: 'de',
            TO: 'à',
            MY_CONDIDATES: "Mes candidats",
            LEADS: 'Talents',
            DOSSIER_PREPARING: "préparation du dossier",
            DISPLAY_DOCUMENTS: "Afficher les documents",
            HIDE_DOCUMENTS: "Masquer des documents",
            DISPLAY_CV: "Afficher CV",
            HIDE_CV: "Masquer le CV",

            RECRUTMENT_DOCUMENTS: 'Documents de recrutement',
            DIPLOME: 'Diplôme',
            DEGREE: 'Niveau',
            ACCEPTED: 'Accepté',
            DECLINED: 'Refusé',
            CONDIDATE: 'Candidat',
            CONDIDATES: 'Candidats',
            JOBTYPE: "Type d'Emploi",
            COUNTRY: 'Pays',
            CITY: 'Ville',
            IDENTITY_VALIDATION: "Vérification d'Identité",
            CVVALIDATION: 'Validation CV',
            NEW: 'Nouveau',
            PROPOSEJOB: "Proposer une Offre d'emploi",
            UPLOADIMAGE: 'Veuillez télécharger Photo',
            UPLOADPASSPORT: 'Veuillez télécharger Passeport',
            UPLOADPRESENTATION: 'Veuillez télécharger Présentation Vidéo',
            REASON: 'Raisons',
            CREATED_BY: 'Créé par',
            COMMENTARY: 'Commentaires',
            CREATEDAT: 'Créé ',
            CVPENDING: 'CV en attente',
            CVACCEPTED: 'CV Accepté',
            USER: 'Utilisateur',
            SHORTCODE: 'Short code',
            LABEL: 'Label Langue',
            EDITDEGREE: 'Modifier Diplôme',
            EDITEXPERIENCE: 'Modifier Experience',
            AGENCE: 'Agence',
            ADDNEW: 'Ajouter Nouveau Candidat',
            CANDIDATE: 'Candidat',
            PROFESSION: 'Profession',
            GENRALINFO: 'Info Générales',
            EDUCATIONEXPERIENCES: 'Parcours Scolaire',
            LANGUAGES: 'Langues',
            CERTIFICATIONS: 'Certifications',
            WORKEXPERIENCES: 'Expérience Professionnelle',
            UPDATE_PROOF: 'Mettre à jour Preuve',
            INTERSHIP: 'Stages',
            INTERESTS: 'Intérêts',
            DRIVERLICENCES: 'Permis de conduire',
            SKILLS: 'Compétences',
            LANGUAGE: 'Langue',
            CERITIFICATION: 'Certification',
            LEVEL: 'Niveau',
            INFO: 'Info',
            CV: 'CV',
            CVDRAFT: 'Brouillon CV',
            CVDECLINED: 'CV Refusé',
            INVITED: 'Invitations',
            PROPOSED: 'Propositions',
            MATCHED: 'Matchs',
            INTERVIEW: 'Interviews',
            INTERVIEWD: 'Interviewé',
            RECRUTING: 'Recruitement',
            RECRUTINGS: 'Recruitements',
            RECRUTING_PREPARING: 'Recrutement Preparing',

            PROPOSE: 'Proposer Candidat',
            INVITED_JOBOFFRE: "Invitation pour Offre d'Emploi",
            APPLIED_JOBOFFRE: "Offre d’emploi appliquée",
            PROPOSED_JOBOFFRE: 'Offre d’emploi proposée',
            MATCHING_JOBOFFRE: 'Offre d’emploi matchée',
            INTERVIEW_JOBOFFRE: 'Offre d’emploi interviewé',
            APPLIED: 'Appliqué',
            OTHERS: 'Autres',
            RECRUTING_DOCUMENTS: 'Documents de recrutement',
            MISSION: 'Mission',
            TASKS: 'Tâches',
            INSTITUE: 'Institut',
            SUBJECT: 'Sujet',
            DONE_ON: 'Fait à',
            SCORE: 'Score',

            SELECT_LICENCE_DRIVER: 'Sélectionner le permis de conduire',
            CV_CANDIDATE: 'Mon CV et Info',

            ADD_INTEREST: 'Ajouter Intérêt',
            ADD_SKILL: 'Ajouter Compétence',

            WISHLIST: 'Liste de favoris',

            ALL: 'Tous',
            CANCEL_INVITATION: 'Annuler Invitation',
            FAVORIS: 'Favoris',
            INVITED_CANDIDATE: 'Invite Candidate',
            ACCEPT_MATCH: 'Accept match',

            ACCEPT_PROPOSITIONS: 'Inviter Candidat',
            CANCEL_PROPOSITIONS: 'Accepter Proposition',
            CANCEL_INTERVIEW: 'Refuser Proposition',
            ACCEPT_INTERVIEW: 'Accepter Interview',

        },


        SERVICES: {
            TOTAL: 'Total',
            UPLOAD_DOCUMENT: 'Veuillez télécharger le Document',
            RECIPIENT: 'Bénéficiaire',
            FILE_NAME: 'Nom du Fichier',
            ALL_ORDERS: 'Tous les commandes',
            NAME_SERVICE: 'Nom du Service',
            SERVICE: 'Service',
            SERVICES: 'Services',
            PRODUCTS: 'Produits',
            PACKS: 'Packs',
            PRICE: 'Prix',
            DESCRIPTION: "Description",
            VERSION: 'Version',
            //  PACKS : 'Packs' ,
            PACK: 'Pack',
            //  PRODUCTS : 'Produits' ,
            PRODUCT: 'Produits',
            ADD_PRODUCT: 'Ajouter Produit',
            ADD_PACK: 'Ajouter un Pack',
            UPDATE_PRODUCT: 'Mettre à jour Produit',
            TERMS_CONDITION: 'Termes & Conditions',
            CUSTOMER_NAME: 'Nom du Client',
            ODER_NUMBER: 'Nombre de commande',
            STATUS: 'Statut',
            COMPELETED: 'Achevé',
            PENDING: 'En attente',
            CANCELED: 'Annulé',
            YOUR_CART: 'Votre panier contient ',
            CART_CONTAINS_OF: 'Panier contient de',
            DOWNLOAD_INVOICE: 'Obtenir une facture',
            ACCEPT_PROOF: 'Accepter Preuve',
            DECLINE_PROOF: 'Refuser Preuve',
            SHOW_PROOF: 'Montrer Preuve',
            PAYMENT_HISTORY: 'Historique de Paiement',
            CANCEL_CHECKOUT: 'Annuler Checkout',
            TO_BUY_SERVICE_YOU_SHOULD_ACCEPT_THIS_TERMS: 'Afin de bénéficier de ce service, veuillez accepter nos conditions générales.',
            BUY: 'Acheter',
            CHECKOUT: 'Passer au paiement ',
            PRODUCTS_OF_PACK: 'Produits du Pack',
            ADDED: 'ajouté',

            GET_DOCUMENT: 'Obtenir le document',

            UPLOAD_PROOF: 'Veuillez télécharger votre Preuve de paiement',

            YOUR_CART_CONTAINS: 'Votre panier contient ',

            PENDING_PAYMENT: 'Paiement en attente',
            FINISHED: 'Terminé',
            CANCELED_PAYMENT: 'Paiement annulé',
            WAIT_PAYMENT: 'Attente de paiement',
            DOCUMENT_SERVICE_UPLOADED: ' Document téléchargé ',
            PAID: 'Payé'


        },

        ouDERS: {
            ouDERS: 'Commandes',
            PRICE: 'Prix',
            TOTAL_ORDER: 'Total des commandes',
        },
        WORKFLOWS: {

            WORKFLOWS: 'Flux de travail',
            WORKFLOWS_PREPARING: "Préparation Flux de travail ",
            WORKFLOWS_RECRUTMENT: "Recrutement Flux de travail ",
            WORK: 'Flux de travail',
        },
        PAGES: {
            RECRUTING_PREPARING: ' Préparation Recrutement',
            WORKFLOW_PREPARING: "Préparation Flux de travail ",
            TERMS: 'Les Termes & Conditions',
            WORDINGMANAGMENT: 'Gestion du libellé',
            ouDER: 'Commande',
            TEAM: 'Equipe',
            PRICES_SERVICE: 'Prix Service',
            NOTIFICATIONMANAGMENT: 'Gestion des notifications',
            ORDERSMANAGMENT: 'Gestion des Ordres',
            PARTNERMANAGMENT: 'Gestion des partenaires',
            USERSMANAGMENT: 'Gestion des utilisateurs',
            SETTINGSMANAGMENT: 'Gestion des paramétres',
            JOBOFFRESMANAGMENT: "Gestion des offres d'emploi",
            CANDIDATESMANAGMENT: 'Gestion des candidats',
            TEAMSMANAGMENT: 'Gestion des équipes',
            GROUPSMANAGMENT: 'Gestion des groupes',
            WORKFLOWMANAGMENT: 'Gestion des flux de travails',
            SERVICEMANAGMENT: 'Gestion des services',
            ouDERSMANAGMENT: 'Gestion des commandes',
            MYPROFILE: 'Mon profil',
            LOGOUT: 'Se déconnecter',
            READPARTNER: 'Partenaire',
            ADD_NEW_PARTNER: 'Partenaire',
            UPDATE_PARTNER: 'Partenaire',
            READCANDIDATE: 'Candidat',
            UPDATECANDIDATE: 'Candidat',
            ADDCANDIDATE: 'Candidat',
            READCVCANDIDATE: 'CV Candidat',
            READJOBOFFR: "Offre d'emploi",
            UPDATEJOBOFFER: "Offre d'emploi",
            ADD_NEW_JOBOFFER: "Offre d'emploi",
            DASHBOARD: 'Tableau de bord',
            RECRUTMENTMANAGMENT: 'Gestion de Recruitement',
            READRECRUTMENT: 'Lire Recruitement',
            STEPS: 'Etapes',
            CHECKOUT: 'Passer au paiement',
            MY_CV: 'Mon CV',

            NEWUSER: 'Utilisateur',
            READUSER: 'Utilisateur',
            UPDATEUSER: 'Utilisateur',


        }
        ,
        EMPTY: {
            NOTRECRUTMENTSFOUND: 'Aucun recrutement trouvé',
            MAX_LENGTH: 'longueur maximale',
            MIN_LENGTH: 'longueur minimale',

            CAN_NOT_HAVE_DOSSIER: "Vous n'avez pas encore de dossier de préparation au recrutement",
            CHAR: 'caractères',

            NO_COMMENTS: 'Aucun commentaire trouvé',
            DONE: 'Terminé',
            CHECK_YOUR_EMAIL: 'Veuillez vérifier votre mail',
            CAN_NOT_RECYCLE_PROPOSTION_TO_SAME_CANDIDATE: `Impossible de recycler la proposition au même candidat`,
            NOTORDERSFOUND: 'Aucune commande trouvée',
            NOTPACKSFOUND: 'Aucun pack trouvé',
            NOTPRODUCTSFOUND: 'Aucun produit trouvé',
            NOTGROUPFOUND: 'Aucun groupe trouvé',
            NOTPRICEFOUND: 'Aucun prix trouvé',
            NOTNOTIFICATIONSFOUND: 'Aucune notification trouvée',
            EMAIL: 'Adresse mail requise',
            PASSWORD: 'Mot de passe requis',
            VALIDMAIL: 'Veuillez insérer une adresse mail valide',
            NOTTEAMFOUND: 'Aucune équipe trouvée',
            NOTPARTNERFOUND: 'Aucun partenaire trouvé',
            NOTJOBSFOUND: "Aucune offre d'emploi trouvée",
            NOTSTEPFOUND: 'Aucune étape trouvée',
            NOTUSERSFOUND: 'Aucun utilisateur trouvé',
            NOTCANDIDATESFOUND: 'Aucun candidat trouvé',
            NOTFOUNDPAGE: 'Désolé mais nous n’avons pas pu trouver la page que vous recherchez',
            HOME: 'Accueil',
            GENDER: 'Le sexe est requis ',
            FIRSTNAME: 'Prénom requis ',
            LASTNAME: 'Nom requis ',
            ADDRESS: 'Adresse requise ',
            COUNTRY: 'Pays requis',
            CITY: 'Ville requise ',
            RECOGNITION: 'Reconnaissance requise ',
            PHONE: 'Numéro de téléphone requis ',
            MAIL: 'Adresse mail requise ',
            BIRTHDAY: 'Date de naissance requise ',
            NATIONALITY: 'Nationalité requise ',
            MARITALSTATUS: 'Etat civil requis ',
            JOBTYPE: "Type d'emploi requis ",
            DOMAINE: 'Domaine requis ',
            THANKS_CHECK_YOUR_EMAIL: "Merci d'avoir choisir Jobzioo. Veuillez consultez votre mail pour obtenir votre mot de passe",
            WE_WILL_CONTACT_YOU: 'Terminé! Nous allons vous contacter.',

            NOTUSERFOUND: 'Aucun utilisateur trouvé',


            CAN_NOT_RECYCLE_INVITATION_TO_SAME_JOB: `Impossible de recycler l’invitation à la même offre d’emploi`,
            CAN_NOT_RECYCLE_AN_APPLICATION: `Impossible de recycler une application`,

            SCHOOL: 'Nom de l’école est requis',
            DIPLOME: 'Nom du diplôme requis',
            FROM: ' L"année du début est nécessaire',
            TO: 'L"année de fin est nécessaire',
            COMPANY: "Nom de l'entreprise est requis",
            MISSION: 'Mission est requise',
            TASKS: 'Tâches sont requises',
            TITLE: 'Titre est requis',
            SUBJECT: 'Sujet requis',
            INSTITUE: 'Institut requis',
            SCORE: 'Score requis',
            ON: 'Année requise',
            LANGUAGE: 'Langue requise',
            LEVEL: 'Niveau requis',
            CERTIFICATION: 'Certification requise',


        }

    }

};


// this fr version , I can't find hi work for that it show COMMUN.HI