<div class=" maria-integration text-center">
  <h1 mat-dialog-title   >  {{data.title | translate}} </h1>
  <div mat-dialog-content>

    <video *ngIf="data.type === 'presentation'" [src]="data.media"  height="200" controls></video>

    <img *ngIf="data.type !== 'presentation'" [src]="data.media" style="height: 400px;"  />

    </div>      


    <div mat-dialog-actions>
  
      <button  class="btn-mat cancel-active  btn-sm  active" mat-raised-button  (click)="onNoClick()"> {{'COMMUN.OK'| translate}}</button>

     
    </div>
    </div>