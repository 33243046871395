import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private url = `${environment.apiUrl}back`;

    constructor(private http: HttpClient) {
    }

    editProfile(params ) {
        return this.http.put<any>(`${this.url}/profile`, params);
        }


        getLicensDriverList() {
            return this.http.get<any>(`${this.url}/licences`);

        }


   editImg(params ) {
       return this.http.post<any>(`${this.url}/photo`, params);
    }


    getWording(params?): Observable<any> {
        return this.http.get<any>(`${this.url}/wordings`, {params});
    }

    postWord(codeWord , data): Observable<any> {
        return this.http.post<any>(`${this.url}/wording/${codeWord}/translation` , data);
    }

    GetLanguages(params): Observable<any> {

        return this.http.get<any>(`${this.url}/languages`, {params});
    }

    GetLanguagesLevel(): Observable<any> {

        return this.http.get<any>(`${this.url}/languagelevels`, {});
    }

    GetLanguagesChoice(): Observable<any> {

        return this.http.get<any>(`${this.url}/languages/choice`, {});
    }

    getPartnerDomains(code): Observable<any> {
        return this.http.get<any>(`${this.url}/partner/${code}/domain` );
      }

    GetjobTypes(params): Observable<any> {

        return this.http.get<any>(`${this.url}/jobtypes`, {params});
    }

    Getdegrees(params): Observable<any> {
        return this.http.get<any>(`${this.url}/degrees`, {params});
    }

    Getexperience(params): Observable<any> {
        return this.http.get<any>(`${this.url}/experiences`, {params});
    }

    getExperienceListChoice() {
        return this.http.get<any>(`${this.url}/experiences/choice`);
    }

    changeStatus(code , mode) {
        return this.http.patch<any>(`${environment.apiUrl}back/${mode}/${code}/state`, {});
    }

    editSetting(payload, code , mode): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}back/${mode}/${code}`, payload);
    }

    addSetting(payload , mode): Observable<any> {

        return this.http.post<any>(`${this.url}/${mode}`, payload);
    }


    getNotifications(params?): Observable<any> {

        return this.http.get<any>(`${this.url}/notifications`, {params});
    }


    EditGroup(params, code): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}back/backGroup/${code}`, params);
    }


    getAgencies(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/agencies` , {params});
    }

    getCountries(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/countries`, {params});
    }


    getCities(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/cities` , {params});
    }

    getCountriesChoice(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/countries/choice`);
    }


    getCitiesChoice(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/cities/choice` );
    }

    getJobTypesChoice(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/jobtypes/choice` );
    }

    getDegreesChoice(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/degrees/choice` );
    }

    getDomainsChoice(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}domains/group` );
    }

    GetRecognitionChoice(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/recoginitionStatus`, {});
    }


    getStateList(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/statefilter`, {});
    }

    getDateList() {
        return this.http.get<any>(`${environment.apiUrl}back/datefilter`);

    }

    getSatusOrder() {
        return this.http.get<any>(`${environment.apiUrl}back/statusOrder`);

    }

    getStatusOffers() {
        return this.http.get<any>(`${environment.apiUrl}back/offerStatus`);

    }

    getWorkTime() {
        return this.http.get<any>(`${environment.apiUrl}back/worktime`);
    }



    AddDomain(data: any , mode , action , code? , domain? ): Observable<any> {
        //console.log(data , mode , action , code , domain);

       if(action === "new"  && mode === 'domain' ) { //type == 'subdomain'
        return this.http.post<any>(`${this.url}/${mode}`, data);
       }
       if(action === "new"  && mode === 'subdomain' ) { //type == 'subdomain'
        return this.http.post<any>(`${this.url}/domain/${domain}/${mode}`, data);
       }
       if(action === "update"  && mode === 'domain') {
        return this.http.put<any>(`${this.url}/${mode}/${code}`, data);
       }
       if(action === "update"  && mode === 'subdomain' ) { //type == 'subdomain'
        return this.http.put<any>(`${this.url}/${mode}/${code}`, data);
       }

       if(action === "new"  && mode === 'speciality' ) { //type == 'subdomain'
        return this.http.post<any>(`${this.url}/subdomain/${domain}/${mode}`, data);
       }

       if(action === "update"  && mode === 'speciality' ) { //type == 'subdomain'
        return this.http.put<any>(`${this.url}/${mode}/${code}`, data);
       }

    }



    getDomains(params): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/domains` , {params});
    }

    getSubDomains(codeDomain): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/domain/${codeDomain}/subdomains`);
    }


    getSpecialitiesList(codeDomain): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/domain/${codeDomain}/specialities`);
    }


     getSpecialities(codeDomain): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}domain/${codeDomain}/specialitieschoice`);
    }


    getInfo() {
        return this.http.get<any>(`${this.url}/informations`);

    }

    getListRecogintionsCandi() {
        return this.http.get<any>(`${this.url}/candidateRecogintions`);
    }


    postTerms(recipient:string , data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}back/${recipient}/generalTc` , data);
    }

    getTerms(params?): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/generalTc` , {params});
    }

    getFederals(): Observable<any> {
        return this.http.get<any>(`${this.url}/cities/federals`, {});
      }


      getDistricts(federal): Observable<any> {
        return this.http.get<any>(`${this.url}/cities/${federal}/districts`, {});
      }


    getCitiesShortLabels(params? ) {

    return this.http.get<any>(`${this.url}/cities/choice?search=${params}`, {params});
    }




}
