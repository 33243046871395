import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'app/_services/auth.service';
import { AlertifyService } from 'app/_services/alertify.service';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {UpdateSelectedPath} from '../../../../store';
import * as sha512 from 'js-sha512';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],

    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
   hide=true ;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private loginService: AuthService,
        private alertify: AlertifyService,
        private _translateService: TranslateService,
        private store: Store,
        private route: ActivatedRoute  ,
) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
    loginForm: FormGroup;
    locale: string;
     returnUrl ;
    /**
     * On init
     */
    ngOnInit(): void {


        const pathData = {
            intermediatePaths: [],
            style: 'yellow',
            currentpage: ''
        };
        this.store.dispatch(new UpdateSelectedPath(pathData));


        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    login() {
      //  $ev.preventDefault();
        // tslint:disable-next-line: forin
        for (const c in this.loginForm.controls) {
            this.loginForm.controls[c].markAsTouched();
        }

        // const hashPwd = Md5.hashStr((this.loginForm.get('password').value));

        if (this.loginForm.valid) {
            const payload = {
                username: this.loginForm.get('email').value,
                intention: 'BACK',
                password: sha512.sha512(this.loginForm.get('password').value),

            };
            this.locale = this._translateService.currentLang;
            this.loginService.getAuthenticate(payload).subscribe((data) => {

                    localStorage.setItem('jwt', data.results.jwt);
                    localStorage.setItem('expire_in', data.results.expire_in);
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('role', data?.results?.data?.group?.code);
                    localStorage.setItem('joboffer_unit', data?.results?.data?.joboffer_unit);

                    localStorage.setItem('locale', this.locale);
                    this.router.navigate(['/']);
                    if( this.locale === 'en') {
                        this.alertify.success('Welcome');
                    }
                    if( this.locale === 'fr') {
                        this.alertify.success('Bienvenue');
                    }
                }
            );
        }
    }
}
