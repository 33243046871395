import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    private partnerURL = `${environment.apiUrl}back/partner`;
    private partnerUserURL = `${environment.apiUrl}back/partneruser`;
    private partnerOfffreURL = `${environment.apiUrl}back/joboffers`;

   // {{host}}/joboffers?partner=34b17562-bc6c-48e2-b83e-bec9be8404c6
    constructor(private http: HttpClient) {
    }

    GetPartner(params): Observable<any> {

        return this.http.get<any>(`${this.partnerURL}s`, {params});
    }


    partnerChoice(): Observable<any> {

        return this.http.get<any>(`${this.partnerURL}s/choice`, {});
    }



    GetOnePartnerForEdit(partner): Observable<any> {
        // if (partner !== undefined) {
             return this.http.get<any>(`${this.partnerURL}/${partner}?edit=1`, {});
        // }
     }


     getRaisonPartner(partnerCode): Observable<any> {
             return this.http.get<any>(`${this.partnerURL}/${partnerCode}/reason`, {});
     }

     changeLogo(data , partnerCode): Observable<any> {
        return this.http.post<any>(`${this.partnerURL}/${partnerCode}/logo`, data);
     }


    GetOnePartner(partner): Observable<any> {
       // if (partner !== undefined) {
            return this.http.get<any>(`${this.partnerURL}/${partner}`, {});
       // }
    }

    GetDomains(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}domains/group`, {});
    }

    AddPartner(partner: any): Observable<any> {
        return this.http.post<any>(`${this.partnerURL}`, partner);
    }


    AuprouvePartner(partnerCode: any, status: any): Observable<any> {
        return this.http.patch<any>(`${this.partnerURL}/${partnerCode}/${status}`, {});
    }


    EditPartner(params, code): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}back/partner/${code}`, params);
    }

    DeletePartner(codePartner): Observable<any> {
        return this.http.delete<any>(`${this.partnerURL}/${codePartner}`);
    }


    banPartner(codePartner , action , description): Observable<any> {
        return this.http.patch<any>(`${this.partnerURL}/${codePartner}/${action}` , {description :description});
    }

    GetUsersPartner(codePartner , params?): Observable<any> {
       
        return this.http.get<any>(`${this.partnerURL}/${codePartner}/users` ,{params});
    }



    /*partner User*/

    GetUserPartner(codePartner): Observable<any> {
        return this.http.get<any>(`${this.partnerUserURL}/${codePartner}`);
    }


    GetOffresPartner(codePartner , params): Observable<any> {
        //console.log(params);
        
        return this.http.get<any>(`${this.partnerOfffreURL}/?partner=${codePartner}` , {params});
    }

   


    EditUserPartner(payload, code): Observable<any> {
        return this.http.put<any>(`${this.partnerUserURL}/${code}`, payload);
    }

    getListAppoiemnt(): Observable<any> {
        return this.http.get<any>(`${this.partnerURL}s/appointment`, {});
    }


}
