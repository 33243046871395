import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartnerService } from 'app/features/integration/integPartner/services/partner.service';
import { AlertifyService } from 'app/_services/alertify.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  @Input() partner  ;
  @Output() changed = new EventEmitter<string>();
  @Input() role=''

  constructor(private _matDialog: MatDialog, private partnerService: PartnerService, private alertify: AlertifyService,  ) { }

  ngOnInit() {

  
  }

  onImgError(event){
    event.target.src = './assets/icons/jobzioo-icons/no-picture.svg';
  }
  
  returnD(partner) {
    return partner.substring(0, 9);

  }

  returnDomain(domain) {
    if(domain) {
      return domain.substring(0, 16);
    }
  }

  banPartner(mode , partner) {
    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data: mode === 'ban' ? {mode:"active" ,type:'partner' , description:'' , item : partner ,  msg:'COMMUN.SURE_BAN_IT' , image :"./assets/icons/jobzioo-icons/stop.svg"  } :  { mode:"inactive" , item : partner ,type:'partner', description:'',  msg:'COMMUN.SURE_APPROVE_IT' , image :"./assets/icons/jobzioo-icons/check.svg"  }   ,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.partnerService.banPartner(partner.code, mode ,result.description ).subscribe(resp => {
         // this.alertify.success('done');
         this._matDialog.open(PopApiComponent ,{
          data: {
           mode:'active',
           msg: 'EMPTY.DONE' ,
           image :"./assets/icons/jobzioo-icons/check.svg"
         }  
        })
        if(mode === 'ban') {
          this.changed.emit('ban') ;
        }
        else{
          this.changed.emit('approve') ;
        }
        })
     
      }
    })
  }


}
