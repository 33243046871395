<div class=" maria-integration text-center">
  <h1 mat-dialog-title> {{'COMMUN.THE_COMMENTS' | translate}} </h1>
  <!-- <div mat-dialog-content *ngIf="data.comments.length>0">

      <div   fxLayout="column " *ngFor="let item of data.comments" class="comments-list mb-16 text-center"> 
           {{ item.commentary}}
      <span class="text-black">  {{'COMMUN.BY'| translate}} : {{item.created_by }},{{  returnDate(item.created_at)}}</span>
    </div>
    </div> -->

    <ul  mat-dialog-content class="timeline mb-32">
      <li class="event" [data-date]="returnDate(item.created_at)" *ngFor="let item of data.comments">
        <h3>{{'COMMUN.BY'| translate}} : {{item.created_by }} ,{{  returnDate(item.created_at)}}</h3>
        <p>
          {{ item.commentary}}  </p>
      </li>
  
  
    </ul>
  


<div mat-dialog-actions>

  <button class="btn-mat btn-cancel  btn-sm  reset comment" mat-raised-button (click)="onNoClick()">
    {{'COMMUN.UNDERSTOOd'| translate}}</button>


</div>