import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'app/_services/dialog.service';
import { take } from 'lodash';

@Component({
  selector: 'app-popApi',
  templateUrl: './popApi.component.html',
  styleUrls: ['./popApi.component.scss']
})
export class PopApiComponent implements OnInit {

   
  constructor(
    private dialogRef: MatDialogRef<PopApiComponent>,
    private productService: DialogService ,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {

    
  }


  test(){
    
    this.productService.setProduct('from api');
    this.dialogRef.close();

  }

  onNoClick(): void {
    
   // this.productService.setProduct('from api');

    
  }


}
