import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { HeaderComponent } from 'app/layout/components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        HeaderComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatToolbarModule,
       TranslateModule,
        FuseSharedModule
    ],
    exports     : [
        HeaderComponent
    ]
})
export class HeaderModule
{
}
