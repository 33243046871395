



   <div class="bloc-service">
    <div fxLayout="row" fxLayoutAlign="space-between start">  
      <div style="margin-top:3px"> {{domain?.label}} </div>
       <div >
        <mat-slide-toggle  class=" mx-8"  [ngClass]="{'active': domain.active , 'inactive' : !domain.active}" 
        [checked]="domain.active" (click)="changeState(domain)"></mat-slide-toggle>

         <img class="cursor-pointer " src="./assets/integration/Desktop9/more.svg" (click)="manageDomain( domain )"> 
       </div> 
    </div>
    </div>

   