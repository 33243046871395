

<div  class="card-gray center mt-20"    [fxFlex]="'100%'"   [fxFlex.sm]="'100%'" [fxFlex.xs]="'100%'"  [fxFlex.md]="'100%'">
 <div class=" sub-card presentation">
    <pdf-viewer
    [src]="cv"
    [rotation]="0"
    [original-size]="true"
    [show-all]="true"
    [fit-to-page]="false"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"

  ></pdf-viewer>
 </div>
</div>