<div class="card job m-8 p-24" fxLayout="row">

  <div class="details " fxLayout="column">




    <span class="creator"> <span class="text-bold"> {{'COMMUN.LABELL' | translate}} : </span> {{ word.label}}
    </span>


    <span class="creator" *ngFor="let item of word?.translations">    <b *ngIf="item?.language"> {{item?.language}} </b> , {{item?.content}} </span>
    
  <div class="btn-actions  " fxLayoutAlign=" end end">


    <a (click)="manageSetting(word )"><img src="./assets/integration/Desktop57/traduction-pink.svg"
        class=" mr-4 cursor-pointer" alt=""> </a>

  </div>

</div>



<!-- 

   <td>
   <div class="btn-actions " (click)="manageSetting(word )">
     <img src="./assets/integration/Desktop57/traduction-pink.svg" alt="" class="cursor-pointer  mx-8" />
   </div>

 </td>


 <td class="cursor-pointer"> {{word?.label}} </td>

 <td *ngFor="let item of word?.translations" class="cursor-pointer">
   <b *ngIf="item?.language"> {{item?.language}} </b> , {{item?.content}}
 </td>

</tr>

-->