import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-table-shared',
  templateUrl: './table-shared.component.html',
  styleUrls: ['./table-shared.component.scss']
})
export class TableSharedComponent implements OnInit {
  @Input() get=''

  @Input() listUser=[] ;
  constructor() { }

  ngOnInit() {
  }

  returnDate(date) {    
    return  moment(date).format('DD/MM/YYYY'); 
   }


}
