
export class CandidateModel {
    code?: string;
    gender?: string;
    ct_alias?: string;
    firstname: string;
    lastname: string;
    mail: string;
    phone: string;
    nationality: string;
    photo?: string;
    signature?: string;
    passport?: string;
    presentation?: string;
    address?: string;
    city?: string;
    zip_code?: string;
    country?: string;
    agency?: string;
    jobtype?: string;
    domain?: string;
    recognition?: boolean;
    maritalStatus?: string;
    marital_status?: string;
    status?: string;
    //activityDomain: string;
    createdAt?: Date;
    speciality?: [];
    birthday?: string;
    link?: string;
    counter_unfinished?: number
    educationExperiences?: [];
    workExperiences?: [];
    certifications?: [];
    languages?: [];
    interests?: [];
    licences?: [];
    interships?: [];
    skills?: [];
    joboffers?: {
        applied: 0,
        invited: 0,
        proposed: 0,
        matched: 0,
        interview: 0,
    }


    constructor(candidate) {
        this.code = candidate.code ? candidate.code : "";
        this.gender = candidate.gender ? candidate.gender : "";
        this.ct_alias = candidate.ct_alias ? candidate.ct_alias : "";
        this.status = candidate.status ? candidate.status : "";
        this.firstname = candidate.firstname ? candidate.firstname : "";
        this.lastname = candidate.lastname ? candidate.lastname : "";
        this.lastname = candidate.link ? candidate.link : "";
        this.mail = candidate.mail ? candidate.mail : "";
        this.phone = candidate.phone ? candidate.phone : "";
        this.nationality = candidate.nationality ? candidate.nationality : "";
        this.photo = candidate.photo ? candidate.photo : "";
        this.signature = candidate.signature ? candidate.signature : "";
        this.passport = candidate.passport ? candidate.passport : "";
        this.address = candidate.address ? candidate.address : "";
        this.city = candidate.city ? candidate.city : "";
        this.zip_code = candidate.zip_code ? candidate.zip_code : "";
        this.country = candidate.country ? candidate.country : "";
        this.agency = candidate.agency ? candidate.agency : "";
        this.jobtype = candidate.jobtype ? candidate.jobtype : "";
        this.domain = candidate.domain ? candidate.domain : "";
        this.marital_status = candidate.marital_status ? candidate.marital_status : "";
        this.speciality = candidate.speciality ? candidate.speciality : "";
        this.createdAt = candidate.createdAt ? candidate.createdAt : "";
        this.birthday = candidate.birthday ? candidate.birthday : '';
        this.counter_unfinished = candidate.counter_unfinished ? candidate.counter_unfinished : '';
        this.educationExperiences = candidate.educationExperiences ? candidate.educationExperiences : [];
        this.workExperiences = candidate.workExperiences ? candidate.workExperiences : [];
        this.certifications = candidate.certifications ? candidate.certifications : [];
        this.languages = candidate.languages ? candidate.languages : [];
        this.interests = candidate.interests ? candidate.interests : [];
        this.licences = candidate.licences ? candidate.licences : [];
        this.interships = candidate.interships ? candidate.interships : [];
        this.skills = candidate.skills ? candidate.skills : [];
        this.recognition = candidate.recognition ? candidate.recognition : '';
    }



}

export const CondidatateExample: CandidateModel = {
    code: "",
    gender: "",
    firstname: "",
    lastname: "",
    mail: "",
    phone: "",
    nationality: "",
    photo: "",
    passport: "",
    address: "",
    city: "",
    zip_code: "",
    country: "",
    agency: "",
    jobtype: "",
    domain: "",
    marital_status: "",
    createdAt: new Date(),
    speciality: []
}
