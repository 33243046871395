
   <div class="block my-20" fxLayout="row" fxLayoutAlign="start"    >
    <span class=" sub cv " [fxFlex]="'100%'" > {{'COMMUN.DOCUMENTS' | translate}} </span>
    </div>

    <div *ngIf="documents.recognition?.length=== 0 && documents.education?.length=== 0 && documents.intership?.length=== 0&& documents.work_experience?.length=== 0 ">
        <app-emptyData [msg]="'NOTCANDIDATESFOUND'"></app-emptyData>

      </div>


      <div class="block my-8" *ngIf="documents.work_experience" >
        <span class="sub "> {{'CANDIDATE.WORKEXPERIENCES' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.work_experience">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>

      <div class="block my-8" *ngIf="documents.intership" >
        <span class="sub "> {{'CANDIDATE.INTERSHIP' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.intership">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>



      <div class="block my-8" *ngIf="documents.education" >
        <span class="sub "> {{'CANDIDATE.EDUCATIONEXPERIENCES' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.education">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>



         <div class="block my-8" *ngIf="documents.languages">
        <span class="sub "> {{'CANDIDATE.LANGUAGES' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.languages">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>


      <div class="block my-8" *ngIf="documents.certification">
        <span class="sub "> {{'CANDIDATE.CERTIFICATIONS' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.certification">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>


      <div class="block my-8"  *ngIf="documents.recognition" >
        <span class="sub "> {{'CANDIDATE.RECOGNITION' | translate}} </span>
        <div class="card-gray my-8 p-20" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of documents.recognition">
                          <span class="file_name" [innerHTML]="item?.file_name"></span>
                               <div class="icon-jobzio  circle-bnt job "  *ngIf="item?.file_url&& item?.file_url!== 'undefined'">

            <a [href]="item?.file_url" target="_blank">
              <img src="./assets/integration/Desktop57/flagBlack.svg" class="  cursor-pointer " alt="">
            </a>
          </div>
        </div>
      </div>
