<div class="maria-integration" id="error-404"  fxLayout="column" fxLayoutAlign="center center" class="py-64">

  <div class="content">

      <div class="error-code">
          <img src="./assets/icons/jobzioo-icons/nodatafound.svg" alt="">
      </div>

      <div class="message">{{'EMPTY.'+msg | translate}}</div>

  </div>

</div>