<div class=" maria-integration text-center">
  <h1 mat-dialog-title  *ngIf="!data.url"   >  {{'COMMUN.RESET' | translate}} </h1>
  <img mat-dialog-title *ngIf="data.url"   src="./assets/icons/jobzioo-icons/stop.svg"  />

  <div mat-dialog-content>
     <p>{{data.msg | translate}} </p>

    </div>

    <div mat-dialog-actions>
      <button  *ngIf="!data.url"  mat-raised-button class="btn-mat  btn-sm bnt-reset mb-16"    [mat-dialog-close]="data" cdkFocusInitial   >
        {{'COMMUN.OK' | translate}}
      </button>

      <button *ngIf="data.url" [ngClass]="{'popError' :data.mode === 'inactive'  }"  [routerLink]="data.url"  mat-raised-button class="btn-mat bnt-reset  btn-sm  mb-16"(click)="onNoClick()"    >
        {{'COMMUN.ADD_CANDIDATE' | translate}}
      </button>

      <button  class="btn-mat btn-cancel  btn-sm  reset" [ngClass]="{'popError' :data.mode === 'inactive'  }" mat-raised-button  (click)="onNoClick()"> {{'COMMUN.NO'| translate}}</button>

     
    </div>
    </div>