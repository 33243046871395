<div class="card job m-8 p-24" fxLayout="row">
  <span class="creator"> <span class="text-bold"> {{'SERVICES.NAME_SERVICE' | translate}} : </span> {{ returnDomain(service?.label)}} <span *ngIf="service?.label.length>60">...</span>  </span>

  <div class="details mt-80" fxLayout="row">
    <div class="btn-actions  ">
      <mat-slide-toggle class=" mr-20"  [ngClass]="{'active': service.active , 'inactive' : !service.active}"  [checked]="service.active" (click)="changeState(service ,  'product' )"></mat-slide-toggle>

      <a  (click)="addSerivce( 'product' , service )"><img
          src="./assets/icons/jobzioo-icons/edit.png" class=" mr-4 cursor-pointer" alt=""> </a>

          <!-- <a  [routerLink]="['/services/terms-conditions', service.code , 'product']" class="cursor-pointer mr-4 ">
            <img src="./assets/integration/Desktop57/term.svg" alt="">
           </a>  -->

           
           <a  [routerLink]="['/services/prices', service.code , 'product']" class="cursor-pointer  ">
            <img src="./assets/integration/Desktop57/dollar.svg" alt="">
           </a> 

    </div>


</div>
</div>


