import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/features/sample/sample.module';

import { LoginModule } from './features/pages/authentication/login/login.module';
import { AlertifyService } from './_services/alertify.service';
import { AuthGuard } from './_guards/auth.guard';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { AppState } from './store';
import { DatePipe } from '@angular/common';
import { HttpErrorInterceptor } from './_services/http-error.interceptor';
import { LoaderService } from './_services/loading.service';
import { LoaderInterceptor } from './_services/loadertercept.service';
import { FuseProgressBarModule } from '@fuse/components/progress-bar/progress-bar.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DashboardComponent } from './features/integration/dashboard/dashboard.component';
import { LOCAL_ASSISTANCEAuthService } from './_guards/LOCAL_ASSISTANCE-auth.service';
import { CANDIDATE_RECRUITERAuthService } from './_guards/CANDIDATE_RECRUITER-auth.service';

const appRoutes: Routes = [
    {
        path: 'team',

        loadChildren: () =>
            import('./features/integration/teamIntegration/teamIntegration.module').then((m) => m.TeamIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },

    // {
    //     path: 'old',
    //     loadChildren: () =>
    //         import('./features/orders/orders.module').then((m) => m.OrdersModule),
    // },

    {
        path: 'orders',
        loadChildren: () =>
            import('./features/integration/ordersIntegration/ordersIntegration.module').then((m) => m.OrdersIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService]

    },
    {
        path: 'groups',
        loadChildren: () =>
            import('./features/integration/groupIntegration/groupIntegration.module').then((m) => m.GroupIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },

    {
        path: 'partners',
        loadChildren: () =>
            import('./features/integration/integPartner/integPartner.module').then((m) => m.IntegPartnerModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService]
    },

    // {
    //     path: 'usersIntegration',
    //     loadChildren: () =>
    //         import('./integration/usersIntegration/usersIntegration.module').then((m) => m.UsersIntegrationModule),
    // },

    {
        path: 'joboffers',

        loadChildren: () =>
            import('./features/integration/jobOffreIntegration/jobOffreIntegration.module').then((m) => m.JobOffreIntegrationModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'settings',
        loadChildren: () =>
            import('./features/integration/settingIntegration/settingIntegration.module').then((m) => m.SettingIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },



    {
        path: 'candidates',
        loadChildren: () =>
            import('./features/integration/candidateIntegration/candidateIntegration.module').then((m) => m.CandidateIntegrationModule),
        canActivate: [AuthGuard]
    },


    {
        path: 'notifications',
        loadChildren: () =>
            import('./features/integration/notification/notification.module').then((m) => m.NotificationModule),
        canActivate: [AuthGuard,]
    },

    {
        path: 'recrutment',
        loadChildren: () =>
            import('./features/integration/recrutment/recrutmentIntegration/recrutmentIntegration.module').then((m) => m.recrutmentModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService]
    },


    {
        path: 'recrutmentPreparing',
        loadChildren: () =>
            import('./features/integration/recrutment/recrutmentPreparing/recrutmentPreparing.module').then((m) => m.RecrutmentPreparingModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService]
    },



    {
        path: 'workflows',
        loadChildren: () =>
            import('./features/integration/workflows/workflowIntegration/workflowIntegration.module').then((m) => m.WorkflowIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },

    {
        path: 'services',
        loadChildren: () =>
            import('./features/integration/serviceIntegration/serviceIntegration.module').then((m) => m.ServiceIntegrationModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },



    {
        path: 'wording',
        loadChildren: () =>
            import('./features/integration/wording/wording.module').then((m) => m.WordingModule),
        canActivate: [AuthGuard, LOCAL_ASSISTANCEAuthService, CANDIDATE_RECRUITERAuthService]
    },


    {
        path: 'login',
        loadChildren: () => import('./features/pages/authentication/login/login.module').then(m => m.LoginModule),
    },


    {
        path: '',
        loadChildren: () =>
            import('./features/integration/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard]
    },

    {
        path: '**',
        loadChildren: () =>
            import('./features/pages/404/error-404.module').then((m) => m.Error404Module),
    },
    // {
    //     path: '**',
    //     redirectTo: 'login'
    // }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        NgxsModule.forRoot(AppState),
        NgxsResetPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),

        //page logoin

        LoginModule,
        MatProgressBarModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule
    ],
    providers: [
        DatePipe,
        AlertifyService,
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
