<div class="card job m-8 p-24" fxLayout="row"  >

  <div  class="details " fxLayout="column">
    <div [routerLink]="['/team/datasheet/', team.id]" class="fullName ">
      <div class="text-bold">{{team?.firstname}} {{team?.firstname}} </div>
      
    </div>



    <span class="creator"> <span class="text-bold"> {{'GROUPS.ROLE' | translate}} : </span> {{ team.role}}
    </span>

    <span class="creator"> <span class="text-bold">{{'JOBOFFERS.STATUS' | translate}} : </span>   <mat-slide-toggle  [ngClass]="{'active': team.active , 'inactive' : !team.active}"
    [checked]="team.active"></mat-slide-toggle>
    </span>


 </div>
  <div class="btn-actions  " fxLayoutAlign=" end end">

    <a [routerLink]="['/team/datasheet', team.code ]"><img src="./assets/icons/jobzioo-icons/eye.svg"
        class=" mr-4 cursor-pointer" alt=""> </a>

        <a *ngIf="user=== 'team'" [routerLink]="['/team/datasheet', team.code , 'edit' ]"><img src="./assets/icons/jobzioo-icons/edit.svg"
          class=" mr-4 cursor-pointer" alt=""> </a>
  
  </div>

</div>
