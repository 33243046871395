import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {


private product$ = new BehaviorSubject<string>('');
selectedProduct$ = this.product$.asObservable();
constructor() {}

setProduct(product: any) {
  
  this.product$.next(product);
}
}
