<div class="card job m-8 p-24" fxLayout="column" *ngIf="!orderr ">
  <span class="creator"> <span class="text-bold"> {{'SERVICES.ODER_NUMBER' | translate}} :  </span>   {{service?.pu_alias}} </span>
  <span class="creator"> <span class="text-bold"> {{'SERVICES.CUSTOMER_NAME' | translate}} :  </span>   {{service?.candidate}} </span>
  <span class="creator"> <span class="text-bold"> {{'SERVICES.PRICE' | translate}} :  </span>   {{service?.total_price}} </span>
  <span class="creator"> <span class="text-bold"> {{'SERVICES.STATUS' | translate}} :  </span>   {{'SERVICES.'+service?.status | translate}}</span>
  <span class="creator"> <span class="text-bold"> {{'COMMUN.SUBMITTEDDATE' | translate}} :  </span>    {{ returnDate(service.created_at)}} </span>

  <div class="details mt-32" fxLayout="row">
    <div class="btn-actions  ">

      <a [routerLink]="['/orders/datasheet', service.code ]"><img
        src="./assets/icons/jobzioo-icons/eye.svg" class=" mr-4 cursor-pointer" alt=""> </a>

     
    </div>


</div>
</div>
  


  <div class="card job m-8 p-24" fxLayout="column" *ngIf="orderr ">
    <div   *ngFor="let item of service?.packs" fxLayout="column">
      <span class="creator"> <span class="text-bold"> {{'SERVICES.SERVICE' | translate}} :  </span>   {{item?.label}} </span>
      <span class="creator"> <span class="text-bold"> {{'SERVICES.DESCRIPTION' | translate}} :  </span>   {{item?.description}} </span>
      <span class="creator"> <span class="text-bold"> {{'SERVICES.PRICE' | translate}} :  </span>   {{item?.price}} </span>
    </div>
   
    <div   *ngFor="let item of service?.products" fxLayout="column">
      <span class="creator"> <span class="text-bold"> {{'SERVICES.SERVICE' | translate}} :  </span>   {{item?.label}} </span>
      <span class="creator"> <span class="text-bold"> {{'SERVICES.DESCRIPTION' | translate}} :  </span>   {{item?.description}} </span>
      <span class="creator"> <span class="text-bold"> {{'SERVICES.PRICE' | translate}} :  </span>   {{item?.price}} </span>
    </div>

    <span class="creator"> <span class="text-bold"> {{'SERVICES.TOTAL' | translate}} :  </span>   {{service?.total_price}} </span>

  </div>
<!-- 

 <th>


            {{'SERVICES.SERVICE' | translate}}</th>
          <th>{{'SERVICES.DESCRIPTION' | translate}}</th>

          <th>{{'SERVICES.PRICE' | translate}} </th>

        </tr>

      </thead>

      <tr *ngFor="let item of order.packs" class="order-tr">
        <td> {{item.label}}</td>
        <td> {{item.description}}</td>
        <td> {{item.price}}</td>
      </tr>

      <tr *ngFor="let item of order.products" class="order-tr">
        <td>

          {{item.label}}


        </td>
        <td> {{item.description}}</td>
        <td> {{item.price}}</td>
      </tr>
      <tr>
        <td> </td>
        <td> </td>
        <td class="total text-bold "> {{'SERVICES.TOTAL'| translate}} : {{order.total_price}}


        </td>
    
-->