<div class=" maria-integration dialog-content-wrapper text-center">
  <div matDialogTitle class="mat-accent m-0">
    <span class="title dialog-title" >{{data.action | translate}} </span>

  </div>
  <div mat-dialog-content>

      <!-- <ng-select2    [(ngModel)]="data.candidate" [data]="list"
      [width]="300">
      </ng-select2> -->
 
      <select2  *ngIf="data.listCandidates"
     
      width="300"
      [data]="data.listCandidates"
      [value]="value1"
      [placeholder]="'COMMUN.SELECT_NAME' | translate"
      (update)="update('value1', $event)"
      (change)="change('change1', $event)"
      (blur)="blur('blur1', $event)"
      (focus)="focus('focus1', $event)"
      (open)="open('open1', $event)"
      (close)="close('close1', $event)"
      (search)="search('search1', $event)"
      resettable
      id="selec2-1"
  >
  </select2>


    <select2  *ngIf="data.dataPreparing"
     
    width="300"
    [data]="data.dataPreparing"
    [value]="value1"
    [placeholder]="'COMMUN.SELECT_NAME' | translate"
    (update)="update('value1', $event)"
    (change)="change('change1', $event)"
    (blur)="blur('blur1', $event)"
    (focus)="focus('focus1', $event)"
    (open)="open('open1', $event)"
    (close)="close('close1', $event)"
    (search)="search('search1', $event)"
    resettable
    id="selec2-1"
>
</select2>


  


  <div mat-dialog-actions>
    <button  [disabled]="value1 === ''&& !data.codePrep "   [ngClass]="{'disabled': value1 === '' && !data.codePrep }"  mat-raised-button  class=" btn-mat btn-sm active mb-16"    [mat-dialog-close]="data" cdkFocusInitial aria-label="SAVE">
      {{'COMMUN.CONFIRM' | translate}}
    </button>
    <br>
    <button   mat-raised-button  class=" btn-mat btn-cancel btn-sm" (click)="onNoClick()">  {{'COMMUN.CANCEL' | translate}} </button>
  </div>

</div>

