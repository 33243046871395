<!--
    *ngIf="true" hack is required here for router-outlet to work
    correctly. Otherwise, it won't register the changes on the
    layout and won't update the view.
 -->
<div  class="page-layout simple fullwidth" fxLayout="column">
<app-header></app-header>

<router-outlet *ngIf="true" ></router-outlet>
</div>



