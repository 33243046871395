import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-popPrice',
  templateUrl: './popPrice.component.html',
  styleUrls: ['./popPrice.component.scss']
})
export class PopPriceComponent implements OnInit {
  listCurrency=["$" , "€" , "DT"]
 
  constructor(
    private dialogRef: MatDialogRef<PopPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}


  returnDate(date) {
    
    return moment(date).format('LL')
  }

  ngOnInit() {
    
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
