<div class="group p-16"  fxLayout="row" fxLayoutAlign="space-between start"  >
  <div class="left-part block-sm " >
      <div class="details flex-sm" >
       <div>
        <span class="title">  {{group?.label}}</span>

       </div>
        <span class="date"> {{'COMMUN.CREATED_AT' | translate}} : {{returnDate(group.created_at)}}</span>
      </div>

  </div>
  <div class="right-part only-flex ">
   
    <mat-slide-toggle     [ngClass]="{'active': group.active , 'inactive' : !group.active}" 
    [checked]="group.active" (click)="changeState(group)"></mat-slide-toggle>
  </div>
</div>