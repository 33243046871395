
export class Group {
    code?: string;
    label?: string;
    roles?: string[];
    created_at?: Date;
/*
    active: boolean;
*/

    /**
     * Constructor
     *
     * @param group
     */
    constructor(group)
    {
        {
            this.code = group.code || '';
            this.label = group.label || '';
            this.roles = group.roles || '';
/*
            this.active = group.active || true;
*/
        }
    }
}

