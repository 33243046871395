
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, retry} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: localStorage.getItem('jwt')
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = environment.apiUrl + 'authenticate';
  private back = environment.apiUrl + 'back';

/*
  private body = { token: environment.token };
*/
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     Authorization: localStorage.getItem('jwt')
  //   })
  // };

  constructor(private http: HttpClient) { }

  changeLangue(params) {
    return this.http.put<any>(`${this.back}/locale`, params);
  }

  login(model: any) {

    return this.http.post(this.authUrl, model, httpOptions)
      .pipe(
        map((response: any) => {
          if (response.results && response.results.data && response.results.data.length > 0) {
            const user = response.results.data;
            
            localStorage.setItem('jwt', user.jwt);
            localStorage.setItem('jwt', user?.group?.code);
            localStorage.setItem('expire_in', response.results.expire_in);
            localStorage.setItem('user', JSON.stringify(user));
            // localStorage.setItem('localUser', JSON.stringify(user.User));
            // this.decodedToken = this.jwtHelper.decodeToken(user.Token);
            // this.menuService.setMenu(localUser.status);*
            return true;
          }
          return false;
        })
      );
  }

  public getAuthenticate(body): Observable<any> {
      return this.http.post<any>(this.authUrl, body);
  }






    
    IsloggedIn() {
      const token = localStorage.getItem('jwt');
      return this.jwtHelper.isTokenExpired(token);
    }

    loggedIn() {
    const token = localStorage.getItem('jwt');
    return !this.jwtHelper.isTokenExpired(token);
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
    localStorage.removeItem('expire_in');
    localStorage.removeItem('locale');
  //  location.reload()
  }
  
}
