import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GroupsService } from 'app/features/integration/groupIntegration/services/group.service';
import { AlertifyService } from 'app/_services/alertify.service';
import * as moment from 'moment';
import { EditGroupComponent } from '../edit-group/edit-group.component';
import { PopApiComponent } from '../popApi/popApi.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  @Input() group ;
  @Output() groupChanged = new EventEmitter<string>();
  constructor( private _matDialog: MatDialog,   private alertify: AlertifyService,  private groupService :GroupsService   ) { }

  ngOnInit() {
  }

  returnDate(date) {    
    return  moment(date).format('MMMM Do , YYYY'); 
   }
 
   manageGroup( mode , item? ) {
     const dialogRef = this._matDialog.open(EditGroupComponent, {
       data: item ? {mode:mode ,  item : item  } :  {mode:mode  }
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if(result) {
       //  //console.log(result);
       //  this.alertify.success('done') ;
        // this.getGroups() ;
        this._matDialog.open(PopApiComponent ,{
          data: {
           mode:'active',
           msg: 'EMPTY.DONE' ,
           image :"./assets/icons/jobzioo-icons/check.svg"
         }  
        })
        
        this.groupChanged.emit('done')
       }
 
     })
   }
   changeState(item ) {
     const dialogRef = this._matDialog.open(StateDialogComponent, {
       data: item.active ? {mode:"active" ,  item : item ,  msg:'COMMUN.SURE_DISABLE_IT' , image :"./assets/icons/jobzioo-icons/stop.svg"  } :  { mode:"inactive" , item : item ,   msg:'COMMUN.SURE_ABLE_IT' , image :"./assets/icons/jobzioo-icons/check.svg"  }   ,
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if(result) {
         this.groupService.setStateTeams(item.code).subscribe(res => {
           if(res.status === 'success') {
             this.alertify.success('done') ;
             this.groupChanged.emit('done')

           }
         })
     
          
       }else {
        this.groupChanged.emit('done')
       }
     })
   }

}

