<div class="partner p-16 mb-12" >
  <div class="part-left w-l-20 mr-64">
    <div class="logo mt-16">
      <img [src]="partner?.logo"   (error)="onImgError($event)"      >

    </div>
  </div>
  <div class="part-left w-l-80 ">
    <div class="details " fxLayout="column">
      <span class="alias">  {{partner?.alias}}</span>
      <span class="title"> {{'COMMUN.COMPANY' | translate}} :  {{returnD(partner.company)}}</span>
      <span > <span class="role" > {{'COMMUN.CONTACT' | translate}} :  </span> {{partner?.firstname}} </span>
      <span > <span class="role" > {{'COMMUN.MAIL' | translate}} :  </span> {{partner?.mail}} </span>
      <span > <span class="role" > {{'COMMUN.CITY' | translate}} :  </span> {{partner?.city}} </span>

      <span *ngIf="partner?.status === 'TO_APPROVE'"> <span class="role" > {{'COMMUN.PHONE' | translate}} : </span>  {{partner?.phone}}</span>
      <span *ngIf="partner?.status === 'TO_APPROVE'"> <span class="role" > {{'COMMUN.CITY' | translate}} :</span>  {{returnDomain(partner?.city)}} </span>
      <span *ngIf="partner?.status !== 'TO_APPROVE'"> <span class="role" > {{'COMMUN.TOTALPUBLISH' | translate}} :</span>  {{partner?.published_jo}} </span>
      <span *ngIf="partner?.status === 'APPROVED'"> <span class="role" > {{'COMMUN.TOTALMATCH' | translate}} :</span>  {{partner?.matched_jo}} </span>
      <span > <span class="role" > {{'COMMUN.DOMAINE' | translate}} : </span>  {{returnDomain(partner.domain)}} <span *ngIf="partner?.domain?.length>16" >...</span> </span>
      <div class="btn-actions  mt-12"  fxLayout="row" fxLayoutAlign="end">
          <a [routerLink]="['/partners/datasheet', partner.code , 'read']"><img src="./assets/icons/jobzioo-icons/eye.svg"    class=" mr-4 cursor-pointer" alt="" > </a>
          <a *ngIf="partner?.status === 'TO_APPROVE'"  [routerLink]="['/partners/datasheet', partner.code , 'edit']"><img src="./assets/icons/jobzioo-icons/edit.png"    class=" mr-4 cursor-pointer icon" alt="" > </a>

          <img  *ngIf="partner?.status !== 'BANNED' && role !== 'CANDIDATE_RECRUITER'" src="./assets/icons/jobzioo-icons/sortie.svg" (click)="banPartner('ban' , partner)" class=" mr-4 cursor-pointer" alt="" >
          <img  *ngIf="partner?.status !== 'APPROVED'&& role !== 'CANDIDATE_RECRUITER'" src="./assets/icons/jobzioo-icons/check.svg"  (click)="banPartner('approve' , partner)"  class=" cursor-pointer" alt="" >

      </div>
    </div>
  </div>
</div>