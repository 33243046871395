<div class=" maria-integration text-center">
  <h1 mat-dialog-title   >  {{data.msg | translate}} </h1>
  <div mat-dialog-content>


     <form [formGroup]="atachmentFormGroup" fxLayout="row wrap" fxFlex="100%" fxFlex.xs="100%" fxFlex.md="100%" fxFlex.sm="100%">

      <div  fxLayout="row wrap" fxFlex="100%" fxFlex.xs="100%"  class=" mt-20">

         <mat-form-field  class="input-group " appearance="outline">
          <input matInput   [(ngModel)]="data.fileName"  [placeholder]="'SERVICES.FILE_NAME'| translate" formControlName="fileName" >
         </mat-form-field>


       <div  mat-raised-button class=" cursor-pointer   text-left mb-20 file-upload " fxLayout="row wrap" fxFlex="100%">
         <input   (change)="onFileChange($event)" class="upload" #logo type="file" id="file" formControlName="fileUrl">
         <div class="btn-white ">{{'COMMUN.BROSE' | translate}}</div>

         <input type="text" id="fileuploadurl" readonly [placeholder]=" fileName ?  fileName :  '.pdf file'">

         <!-- <img  *ngIf="srcResult" [src]="srcResult " alt="" class="image-found" > -->

         <!-- <a *ngIf="srcResult"[href]="srcResult "  > <img src="./assets/integration/Desktop57/push-pin.png" alt="">  {{data.fileName}}.pdf </a>

         <a *ngIf="!srcResult"  (click)="logo.click()" > <img src="./assets/integration/Desktop57/push-pin.png" alt=""> {{'COMMUN.UPLOAD'| translate}}.pdf </a> -->

         <!-- <img *ngIf="!srcResult"  src="./assets/integration/Desktop57/image.svg" class="image-empty" />  -->

         <!-- <button  mat-raised-button class="bnt-mail bnt-upload btn-mat btn-sm mt-20 " type="button" mat-raised-button (click)="logo.click()"> {{'COMMUN.BROSE'| translate}} </button> -->

       </div>

      </div>


     </form>


    </div>

    <div mat-dialog-actions>
      <button [ngClass]="{ 'disabled' : atachmentFormGroup.invalid}"   [disabled]="atachmentFormGroup.invalid"  mat-raised-button class="btn-mat  btn-sm bnt-reset mb-16"  [mat-dialog-close]="data" cdkFocusInitial   >
        {{'COMMUN.UPLOAD' | translate}}
      </button>
      <button  class="btn-mat btn-cancel  btn-sm  reset" mat-raised-button  (click)="onNoClick()"> {{'COMMUN.NO'| translate}}</button>


    </div>
    </div>