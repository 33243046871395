import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  Router } from '@angular/router';
import {  Store } from '@ngxs/store';
import { Group } from 'app/features/integration/groupIntegration/group.model';
import { GroupsService } from 'app/features/integration/groupIntegration/services/group.service';

@Component({
  selector: 'manage-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {
  action: string;
  group: Group;
  groupForm: FormGroup;
  dialogTitle: string;
  roles: string[];
  filterForm: FormGroup;

  pathData: any;
  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   * @param groupService
   * @param alertify
   * @param store
   * @param activatedRoute
   * @param router
   */
  
  constructor(
    public matDialogRef: MatDialogRef<EditGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        mode: string,
        item?:Group 
      },
    private _formBuilder: FormBuilder,
    private groupService :GroupsService ,
  
    private store: Store,
    private router: Router
  ) {

  }



  onNoClick(): void {
    this.matDialogRef.close();
  }


  ngOnInit() {

    if(this.data.item) {
      this.group = this.data.item
    }else {
      this.group = new Group({});

    }
    this.groupForm = this.createGroupForm();


  }

  createGroupForm(): FormGroup {
    return this._formBuilder.group({
      code: [this.group.code , Validators.required],
      label: [this.group.label, Validators.required],
      roles: [this.group.roles, Validators.required],
    });
  }

  addGroup() {
    if (!this.groupForm.valid) { return false}


      if (this.data.mode !== 'new') {
        const payload = {
          label: this.groupForm.get('label').value,
        //  roles: this.groupForm.get('roles').value,
        };
        this.groupService.EditGroup(payload, this.data.item?.code).subscribe(res => {
          if (res.status === 'success') {
            this.onNoClick();
          } 
        });
      }

      if (this.data.mode === 'new') {
        const payload = {
          label: this.groupForm.get('label').value,
        //  roles: this.groupForm.get('roles').value,
         // code: this.groupForm.get('code').value,
        };
        this.groupService.AddGroup(payload).subscribe(res => {
          if (res.status === 'success') {
            this.onNoClick();
          } 
        });
      }

    
  }








}
