<div class="card job partner my-8 p-24 " fxLayout="row">
  <div class="logo mr-20">
    <div class="name flex-sm-sm">
      <img [src]="user?.logo" (error)="onImgError($event)" alt="">
    </div>
  </div>
  <div class="details " fxLayout="column">
    <span class="fullName ">
      <div class="text-bold">{{user?.alias}} </div>
        {{user?.lastname}}
    </span>

    <span class="creator"> <span class="text-bold"> {{'COMMUN.DOMAINE' | translate}} :</span>
    {{returnDomain(user.domain)}}  </span>

    <span > <span class="role" > {{'COMMUN.MAIL' | translate}} :  </span> {{user?.mail}} </span>

    <span > <span class="role" > {{'COMMUN.CITY' | translate}} :  </span> {{user?.city}} </span>


      <span class="creator"  *ngIf="user?.status === 'APPROVED'" > <span class="text-bold"> {{'COMMUN.TOTALMATCH' | translate}} : </span> {{ user.matched_jo}} </span>

      <span class="creator"  *ngIf="user?.status !== 'TO_APPROVE'" > <span class="text-bold"> {{'COMMUN.TOTALPUBLISH' | translate}} : </span> {{ user.published_jo}} </span>


      <span  class="creator"  *ngIf="user?.status === 'TO_APPROVE' && user?.appointment"> <span class="text-bold"> {{'COMMUN.APPOIEMENT_DATE' | translate}} : </span> {{ returnDateAPP(user.appointment)}} </span>

      <!-- <span class="creator"> <span class="text-bold"> {{'COMMUN.CITY' | translate}} :</span>
      {{user.city}} </span> -->

      <span class="creator"> <span class="text-bold"> {{'COMMUN.PHONE' | translate}} :</span>
      {{user.phone}} </span>

  </div>
  <div class="btn-actions  " fxLayoutAlign=" end end" fxLayout="column">

    <a class="mb-8" [routerLink]="['/partners/datasheet', user.code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" class="cursor-pointer" alt=""> </a>

    <a   class="cursor-pointer mb-8" *ngIf="user?.status === 'TO_APPROVE'" [routerLink]="['/partners/datasheet', user.code , 'edit']">
      <img src="./assets/icons/jobzioo-icons/edit.svg" alt=""> </a>

    <a  class="cursor-pointer mb-8" *ngIf="user?.status !== 'BANNED'&& role !== 'CANDIDATE_RECRUITER'"><img src="./assets/icons/jobzioo-icons/sortie.svg"
      (click)="banPartner('ban' , user)"> </a>

    <a  class="cursor-pointer mb-8" *ngIf="user?.status !== 'APPROVED'&& role !== 'CANDIDATE_RECRUITER'" ><img src="./assets/icons/jobzioo-icons/check.svg"
      (click)="banPartner('approve' , user)"> </a>


  </div>

</div>