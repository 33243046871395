<div class="maria-integration  text-center p-16">
  <div matDialogTitle class="mat-accent m-0">
    <span class="title dialog-title" >{{data.action | translate}} </span>

  </div>
  <div mat-dialog-content >
       <div class="meeting mt-20">
          

        <mat-form-field appearance="outline">
          <mat-label>{{'COMMUN.PRICE' | translate}}</mat-label>
          <input matInput class="price" type="number" [(ngModel)]="data.priceData.price" [placeholder]="'COMMUN.PRICE' | translate" required>
        </mat-form-field>

     
        <mat-form-field appearance="outline">
          <mat-label>{{'COMMUN.CURRENCY' | translate}}</mat-label>
          <mat-select required class="roles" [(ngModel)]="data.priceData.currency" [placeholder]="'SETTINGS.CURRENCY' | translate">
            <mat-option *ngFor="let rl of listCurrency " [value]="rl">
              {{rl}}
            </mat-option>
          </mat-select>
        </mat-form-field>

     

     
              <mat-form-field appearance="outline">
            <mat-label>{{'COMMUN.COUNTRY' | translate}}</mat-label>
            <mat-select required class="roles" [(ngModel)]="data.priceData.country" [placeholder]="'SETTINGS.COUNTRIES' | translate">
              <mat-option *ngFor="let rl of data.countries " [value]="rl.code">
                {{rl.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>



       <mat-form-field  class="input-group" appearance="outline">
        <mat-label>{{'COMMUN.DATE_BEGIN' | translate}}</mat-label>

        <input (click)="picker.open()" required  matInput  [placeholder]="'COMMUN.DATE_BEGIN' | translate" [matDatepicker]="picker"   [(ngModel)]="data.priceData.date_begin" >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

    </mat-form-field>
    
       </div>
    
  </div>
  <div mat-dialog-actions class="mt-16" >
    <button   [ngClass]="{ 'disabled' :data.priceData.date_begin===''  && data.priceData.country===''  && data.priceData.price==='' }" 
    [disabled]=" data.priceData.date_begin===''  && data.priceData.country===''  && data.priceData.price==='' "  
     mat-raised-button class="btn-mat btn-sm active mb-16" 
 
      [mat-dialog-close]="data" cdkFocusInitial   >
      {{'COMMUN.CONFIRM' | translate}}
    </button>
    <button   mat-raised-button  class=" btn-mat btn-sm cancel-active active" (click)="onNoClick()">  {{'COMMUN.CANCEL' | translate}} </button>
  </div>

</div>

