import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.scss']
})
export class CardUserComponent implements OnInit  {
   @Input() team ;
   @Input() user='' ;

   
  constructor() { }

  ngOnInit() {
  }



}
