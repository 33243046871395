<div class="maria-integration dialog-content-wrapper text-center">
  <div matDialogTitle class="mat-accent m-0">
    <span class="title dialog-title" *ngIf="data.mode === 'new'">{{'GROUPS.NEWGROUP' | translate}} </span>
    <span class="title dialog-title" *ngIf="data.mode === 'edit'">{{'GROUPS.EDITGROUP' | translate}} </span>

  </div>
  <div mat-dialog-content>
    <form [formGroup]="groupForm" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start" 
      name="form" >

      <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1 0 0">


        <div fxLayout="row" fxFlex="100">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label> {{'COMMUN.LABELL' | translate}}</mat-label>
            <input name="label" formControlName="label" [placeholder]="'COMMUN.LABELL' | translate" matInput required>
          </mat-form-field>
        </div>

      </div>

      <div mat-dialog-actions>
        <button mat-raised-button type="submit"  (click)="addGroup()" [disabled]="groupForm.invalid"  class="btn-mat btn-sm active mb-16" [mat-dialog-close]="data" cdkFocusInitial 
        aria-label="SAVE">
         {{ 'COMMUN.SAVE' | translate}}
      </button>

       <br>

        <button  mat-raised-button  class="btn-mat btn-cancel btn-sm" (click)="onNoClick()"> cancel </button>

       
      </div>


    </form>


  </div>


</div>