import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecrutmentService } from 'app/features/integration/recrutment/recrutmentIntegration/services/recrutment.service';
import * as moment from 'moment';
@Component({
  selector: 'app-getCommentStep',
  templateUrl: './getCommentStep.component.html',
  styleUrls: ['./getCommentStep.component.scss']
})
export class GetCommentStepComponent implements OnInit {


  constructor(
    private serviceRecrutement: RecrutmentService ,
    private dialogRef: MatDialogRef<GetCommentStepComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {


  }


  returnDate(date) {
    return  moment(date).format('MM/Do/YYYY');
   }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
