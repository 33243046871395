<div class=" text-center dialog">
  <img mat-dialog-title [src]="data.image"  />
  <div mat-dialog-content>
    <p>{{data.msg | translate}} </p>

    <div  class="mt-36" *ngIf="data.type === 'partner'">
      <mat-form-field class="input-group" appearance="outline" fxFlex>
        <mat-label> {{'COMMUN.MAKEARAISON' | translate}}</mat-label>
        <input name="code"  required [(ngModel)]="data.description"  [placeholder]="'COMMUN.MAKEARAISON' | translate" matInput   />
      </mat-form-field>
    </div>

    <div class="mt-36" *ngIf="data.type === 'unpublish'">
      <mat-form-field class="input-group" appearance="outline" fxFlex>
        <mat-label> {{'COMMUN.MAKEARAISON' | translate}}</mat-label>

        <input name="code" required [(ngModel)]="data.commentary"  [placeholder]="'COMMUN.MAKEARAISON' | translate" matInput   />
      </mat-form-field>
    </div>


    <div class="mt-36" *ngIf="data.type === 'preparing'">
        <mat-form-field class="input-group" appearance="outline" fxFlex>
          <mat-label> {{'COMMUN.MAKEARAISON' | translate}}</mat-label>
  
          <input name="code" required [(ngModel)]="data.commentary"  [placeholder]="'COMMUN.MAKEARAISON' | translate" matInput   />
        </mat-form-field>
      </div>


    </div>

    <div mat-dialog-actions>
      <button  [disabled]=" (data.type === 'partner'  && data.description===''  && data.mode === 'active' ) || (data.type === 'unpublish'  && data.commentary===''  && data.mode === 'active' ) " mat-raised-button class="btn-mat mb-16" 
      [ngClass]="{
       'publish' :data.type === 'jobOffre' ,  'reset' :data.type === 'reset' ,  'inactive': (data.mode === 'active' && data.type === 'jobOffre' )
        || data.mode === 'active'   , 'active': data.mode === 'inactive'   ,
       'disabled' : (data.type === 'partner' &&  data.description===''   && data.mode === 'active' )
         || (data.type === 'unpublish'  && data.commentary===''  && data.mode === 'active' ) 
       ||  (data.type === 'canidateRaison' && data.description.commentary==='' && data.description.reason===''  && data.mode === 'active' )} "
        [mat-dialog-close]="data" cdkFocusInitial   >
        {{'COMMUN.YES' | translate}}
      </button>
      <br>
      <button  class="btn-mat btn-cancel" mat-raised-button [ngClass]="{'cancel-inactive inactive': data.mode === 'active'  ,  'reset' :data.type === 'reset','expired' :data.type === 'expired' ,   'publish' :data.type === 'jobOffre' , 'cancel-active active': data.mode === 'inactive'  }" (click)="onNoClick()"> {{'COMMUN.NO'| translate}}</button>
     
    </div>
    </div>