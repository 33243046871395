

import { TablePath } from '@fuse/components/acces-header/table.path';


export class UpdateSelectedPath {
    static readonly type = '[Path] Update Selected url';

    constructor(public path: TablePath ) {}
}

