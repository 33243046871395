import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';

@Component({
  selector: 'app-popSettings',
  templateUrl: './popSettings.component.html',
  styleUrls: ['./popSettings.component.scss']
})
export class PopSettingsComponent implements OnInit {
  @ViewChild('flag') flag: ElementRef;
  districtList = []
  cities = []
  srcResult; uploadFileName
  constructor(
    public dialogRef: MatDialogRef<PopSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        mode: string;
        jobtypes: any;
        domainList: any;
        item: any; action: string;
        type: string; countries: any
      },
  ) {


  }

  ngOnInit(): void {
    this.srcResult = this.data.item.flag
    //console.log( this.data);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(e: any) {

    if (e.target.files && e.target.files[0]) {

      this.uploadFileName = '';
      Array.from(e.target.files).forEach((file: File) => {
        this.uploadFileName += file.name + ',';
      });

      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = res => {

          this.srcResult = e.target.result;
          this.data.item.flag = this.srcResult;

        };
      };
      fileReader.readAsDataURL(e.target.files[0]);

      const file = (e.target as HTMLInputElement).files[0];


    }
  }
}
