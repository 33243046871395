<div class="card job m-8 p-24" fxLayout="row">

  <div class="details " fxLayout="column" *ngIf="servicee=== ''">




    <span class="creator"> <span class="text-bold"> {{'SERVICES.VERSION' | translate}} : </span> {{ terms?.version}}  </span>

    <span class="creator"> <span class="text-bold"> {{'SERVICES.RECIPIENT' | translate}} : </span> {{ terms?.recipient}}  </span>
    
    <span class="creator"> <span class="text-bold"> {{'SERVICES.DESCRIPTION' | translate}} : </span>   {{returnDomain(terms?.content)}} <span *ngIf="terms?.content?.length>40" >...</span>  </span>
 
    <span class="creator"> <span class="text-bold"> {{'COMMUN.SUBMITTEDDATE' | translate}} : </span>   {{ returnDate(terms.created_at)}} </span>

    


</div>



<div class="details " fxLayout="column" *ngIf="servicee!== ''">




  <span class="creator"> <span class="text-bold"> {{'SERVICES.VERSION' | translate}} : </span> {{ terms?.version}}  </span>
  
  <span class="creator"> <span class="text-bold"> {{'SERVICES.DESCRIPTION' | translate}} : </span>   {{returnDomain(terms?.description)}} <span *ngIf="terms?.description?.length>40" >...</span>  </span>

  <span class="creator"> <span class="text-bold"> {{'COMMUN.SUBMITTEDDATE' | translate}} : </span>   {{ returnDate(terms.created_at)}} </span>

  
  <!---  
  
  
   <td  class="gray-text">
                    {{order?.version}}
                 </td>
      
                 
                 <td  >
                  {{returnDomain(order.description)}} <span *ngIf="order?.description?.length>40" >...</span>
                 </td>
              
                <td class="gray-text">
                  {{ returnDate(order.created_at)}}
                </td>
              
      
  
  
  -->

</div>



<!---  

  <tr>
              <th>{{'SERVICES.VERSION' | translate}}</th>
              <th>{{'SERVICES.RECIPIENT' | translate}}</th>
    
              <th>{{'SERVICES.DESCRIPTION' | translate}}  </th>
           
              <th>{{'COMMUN.SUBMITTEDDATE' | translate}}</th>
    
          
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of  termsList ; let index = index">
    
               <td  class="gray-text">
                  {{order?.version}}
               </td>
    
               <td  >
                {{order?.recipient}}
               </td>
    
               <td  >
                {{returnDomain(order?.content)}} <span *ngIf="order?.content?.length>40" >...</span>
               </td>
            
              <td class="gray-text">
                {{ returnDate(order.created_at)}}
              </td>
            
    
            </tr>



-->