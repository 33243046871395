import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CandidateModel } from 'app/features/integration/candidateIntegration/models/candidate.model';
import { CandidateService } from 'app/features/integration/candidateIntegration/services/candidate.service';

@Component({
  selector: 'app-documentCandi',
  templateUrl: './document_candi.component.html',
  styleUrls: ['./document_candi.component.scss']
})
export class Document_candiComponent implements OnInit , OnChanges {
    @Input() code;
    @Input() candidateInfo = new CandidateModel({});
    @Input()cv=''
    documents :{recognition:[] ,education:[] ,intership :[]  ,work_experience:[],certification:[]  ,languages:[] } ;

    constructor(  private serviceCandidate: CandidateService,
      ) { }

    ngOnInit() {
    }

    ngOnChanges(): void {

      if (this.code) {
          this.getCandidate()

        }

    }


    getCandidate() {
      this.serviceCandidate.getCandidateInfo(this.code).subscribe(res => {

         this.documents = res.results.data.documents;
      }
  );

    }

}
