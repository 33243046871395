import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-emptyData',
  templateUrl: './emptyData.component.html',
  styleUrls: ['./emptyData.component.scss']
})
export class EmptyDataComponent implements OnInit {
  @Input() msg=''

  constructor() { }

  ngOnInit() {
  }

}
