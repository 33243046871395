
  <div  class="setting-item p-20" *ngIf="!action">
     <span class="label" > {{setting?.label}}</span>
     <span class="label" > {{setting?.country_label}}</span>
     <span class=" label label-city"  *ngIf="type === 'city'" > <span style="color: #ED0D6B;">   {{setting?.shortlabel}} </span>     </span>

     <span class="statelabel " *ngIf="setting?.statelabel" > {{setting?.statelabel}}</span>
     <span class="statelabel " *ngIf="setting?.shortcode" > {{setting?.shortcode}}</span>

     <img *ngIf="!setting?.flag &&  type !== 'city'" [src]="icon" class="setting-icon mt-8"  alt="">
     <img *ngIf="setting?.flag" [src]="setting?.flag" class="setting-icon flag mt-8"  alt="">
     <div class="footer" *ngIf="type !== 'service'">
      <mat-slide-toggle     [ngClass]="{'active': setting.active , 'inactive' : !setting.active}"
      [checked]="setting.active" (click)="changeState(setting)"></mat-slide-toggle>

      <div class="icon-action settings" (click)="manageSetting('edit' , setting )">
        <img class="cursor-pointer" [src]="'./assets/integration/Desktop01/edit.svg'" />
    </div>
     </div>
     <div  *ngIf="type === 'service'">
      <span>  {{setting?.price}} </span>


     </div>

    </div>



  <div  class="setting-item action p-36 cursor-pointer"    *ngIf="action" (click)="manageSetting('new')">
    <img *ngIf="!setting?.flag" [src]="'./assets/integration/Desktop9/Add.svg'" class="setting-icon"  alt="">
    <span class="label mt-24" class="add" > {{'COMMUN.ADD' | translate}} </span>

   </div>
