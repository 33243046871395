import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    private groupUrl = `${environment.apiUrl}back/backGroups`;
    private groupUrlOne = `${environment.apiUrl}back/backGroup`;


    constructor(private http: HttpClient) {
    }

    GetGroups(params?): Observable<any> {

        return this.http.get<any>(`${this.groupUrl}`, {params});
    }


    getUsersByGroup(code): Observable<any> {
        return this.http.get<any>(`${this.groupUrlOne}/${code}/users`, {});
    }


    GetGroup(code): Observable<any> {        
        return this.http.get<any>(`${this.groupUrlOne}/${code}`);
    }
    
    AddGroup(group: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}back/backGroup`, group);
    }


    EditGroup(params, code): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}back/backGroup/${code}`, params);
    }
   
    setStateTeams(code): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}back/backGroup/${code}`, {});
    }

    GetGroupsChoice(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}back/backGroups/choices`, {});
    }

}
