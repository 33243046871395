import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';
import { AlertifyService } from 'app/_services/alertify.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopSettingsComponent } from '../popSettings/popSettings.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Output() changed = new EventEmitter<string>();
  @Input() setting;
  @Input() icon;
  @Input() action;
  @Input() type;
  countries = [];
  cities = []
  item = {
    label: "",
    statelabel: '',
    stateLabel: '',
    flag: '',
    active: true,
    code: '',
    country: '',
    shortcode: '',
    address: '',
    city: '',
    zip_code: '',
    federal: '', district: '', type: ''

  };



  payload;
  msg = ''

  constructor(private alertify: AlertifyService, private _matDialog: MatDialog, private serviceSetting: SettingsService) { }


  manageSetting(mode, setting?) {


    if (setting && this.type === 'country') {
      this.msg = 'SETTINGS.UPDATE_COUNTRY'
    }
    if (!setting && this.type === 'country') {
      this.msg = 'SETTINGS.ADD_COUNTRY'
    }
    /////:
    if (setting && this.type === 'city') {
      this.msg = 'SETTINGS.UPDATE_CITY'
    }
    if (!setting && this.type === 'city') {
      this.msg = 'SETTINGS.ADD_CITY'
    }
    //////
    if (setting && this.type === 'language') {
      this.msg = 'SETTINGS.UPDATE_LANGUAGE'
    }
    if (!setting && this.type === 'language') {
      this.msg = 'SETTINGS.ADD_LANGUAGE'
    }
    //////////
    if (setting && this.type === 'agency') {
      this.msg = 'SETTINGS.UPDATE_AGENCY'
    }
    if (!setting && this.type === 'agency') {
      this.msg = 'SETTINGS.ADD_AGENCY'
    }

    //////////
    if (setting && this.type === 'jobtype') {
      this.msg = 'SETTINGS.UPDATE_JOBTYPE'
    }
    if (!setting && this.type === 'jobtype') {
      this.msg = 'SETTINGS.ADD_JOBTYPE'
    }

    //////////
    if (setting && this.type === 'experience') {
      this.msg = 'SETTINGS.UPDATE_EXPERIENCE'
    }
    if (!setting && this.type === 'experience') {
      this.msg = 'SETTINGS.ADD_EXPERIENCE'
    }

    //////////
    if (setting && this.type === 'degree') {
      this.msg = 'SETTINGS.UPDATE_DEGREE'
    }
    if (!setting && this.type === 'degree') {
      this.msg = 'SETTINGS.ADD_DEGREE'
    }

    //////////
    if (setting && this.type === 'domain') {
      this.msg = 'SETTINGS.UPDATE_DOMAIN'
    }
    if (!setting && this.type === 'domain') {
      this.msg = 'SETTINGS.ADD_DOMAIN'
    }

    const dialogRef = this._matDialog.open(PopSettingsComponent, {
      data: {
        mode: mode,
        item: setting ? setting : this.item,
        type: this.type, action: this.msg,
        countries: this.countries,
        cities: this.cities
      }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.type === 'country' || this.type === 'language') {
          this.payload = {
            label: result.item.label,
            shortcode: result.item.shortcode,
            flag: result.item.flag
          }
        }
        else if (this.type === 'city') {

          this.payload = {
            name: result.item.name,
            lat: result.item.lat,
            zipCode: result.item.zip_code,
            long: result.item.long,
          }
        }
        else if (this.type === 'agency') {
          this.payload = {
            label: result.item.label,
            address: result.item.address,
            city: result.item.city,
            zip_code: result.item.zip_code,
            country: result.item.country,

          }
        }
        else {

          this.payload = {
            label: result.item.label,
            color: result.item.color,

          }
        }


        if (setting) {
          this.serviceSetting.editSetting(this.payload, setting.code, this.type).subscribe(res => {
            if (res.status === 'success') {
              // this.alertify.success('done') ;
              this._matDialog.open(PopApiComponent, {
                data: {
                  mode: 'active',
                  msg: 'EMPTY.DONE',
                  image: "./assets/icons/jobzioo-icons/check.svg"
                }
              })
              this.changed.emit(this.type);
              result.item = null
            }
          })
        } else {
          this.serviceSetting.addSetting(this.payload, this.type).subscribe(res => {
            if (res.status === 'success') {
              //   this.alertify.success('done') ;
              this._matDialog.open(PopApiComponent, {
                data: {
                  mode: 'active',
                  msg: 'EMPTY.DONE',
                  image: "./assets/icons/jobzioo-icons/check.svg"
                }
              })
              this.changed.emit(this.type);

            }
          })
        }
      }

    })

    for (const key in this.item) {
      delete this.item[key];
    }
  }

  ngOnInit() {
    this.getCountiesList();
  }

  getCountiesList() {
    this.serviceSetting.getCountriesChoice().subscribe(res => {
      this.countries = res.results.data;
    })
  }

  changeState(item) {
    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data: item.active ? { mode: "active", item: item, msg: 'COMMUN.SURE_INABLE', image: "./assets/icons/jobzioo-icons/stop.svg" } :
        { mode: "inactive", item: item, msg: 'COMMUN.SURE_ABLE', image: "./assets/icons/jobzioo-icons/check.svg" },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceSetting.changeStatus(item.code, this.type).subscribe(res => {
          if (res.status === 'success') {
            // this.alertify.success('done') ;
            this._matDialog.open(PopApiComponent, {
              data: {
                mode: 'active',
                msg: 'EMPTY.DONE',
                image: "./assets/icons/jobzioo-icons/check.svg"
              }
            })
            this.changed.emit(this.type);
          }
        })


      }
    })
  }

}
