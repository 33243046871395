import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-recrutment',
  templateUrl: './card-recrutment.component.html',
  styleUrls: ['./card-recrutment.component.scss']
})
export class CardRecrutmentComponent implements OnInit {
  @Input() card ;
  @Input() from=''

  constructor() { }

  ngOnInit() {
  }


  returnDomain(domain) {
    return domain.substring(0, 16);
   }
  
   onImgError(event){
    event.target.src = './assets/icons/jobzioo-icons/no-picture.svg';
  }


}
