import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stateDialog',
  templateUrl: './stateDialog.component.html',
  styleUrls: ['./stateDialog.component.scss']
})
export class StateDialogComponent implements OnInit {

   
  constructor(
    private dialogRef: MatDialogRef<StateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
