import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeamService } from 'app/features/integration/teamIntegration/services/team.service';
import { AlertifyService } from 'app/_services/alertify.service';
import * as moment from 'moment';
import { EditGroupComponent } from '../edit-group/edit-group.component';
import { PopApiComponent } from '../popApi/popApi.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  @Input() group ;
  @Output() groupChanged = new EventEmitter<string>();
  constructor( private _matDialog: MatDialog,   private alertify: AlertifyService,  private teamService :TeamService   ) { }

  ngOnInit() {
  }

  returnDate(date) {    
    return  moment(date).format('MMMM Do , YYYY'); 
   }
 
   manageGroup( mode , item? ) {
     const dialogRef = this._matDialog.open(EditGroupComponent, {
       data: item ? {mode:mode ,  item : item  } :  {mode:mode  }
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if(result) {
         ////console.log(result);
         this._matDialog.open(PopApiComponent ,{
          data: {
           mode:'active',
           msg: 'EMPTY.DONE' ,
           image :"./assets/icons/jobzioo-icons/check.svg"
         }  
        })
        // this.alertify.success('done') ;
        // this.getGroups() ;
        this.groupChanged.emit('done')
       }
 
     })
   }
   changeState(item ) {
     const dialogRef = this._matDialog.open(StateDialogComponent, {
       data: item.active ? {mode:"active" ,  item : item ,  msg:'Are you sure to disable this ? ' , image :"./assets/icons/jobzioo-icons/stop.svg"  } :  { mode:"inactive" , item : item ,   msg:'Are you sure to able this ? ' , image :"./assets/icons/jobzioo-icons/check.svg"  }   ,
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if(result) {
         this.teamService.setStateTeams(item.code).subscribe(res => {
           if(res.status === 'success') {
            // this.alertify.success('done') ;
            this._matDialog.open(PopApiComponent ,{
              data: {
               mode:'active',
               msg: 'EMPTY.DONE' ,
               image :"./assets/icons/jobzioo-icons/check.svg"
             }  
            })
             this.groupChanged.emit('done')

           }
         })
     
          
       }
     })
   }

}
