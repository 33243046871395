<div class=" maria-integration ">

  <div  class="auth login" fxLayout="row wrap" fxFlex="100%" >
   <div fxFlex="30%" fxFlex.md="30%"  fxFlex.xs="100%"   fxHide.xs fxHide.sm>
      <div class="login"  style="background-image: url('./assets/integration/Desktop57/business.png');">
        <div class="details-log">
         
            <div class="logo">
              <img src="./assets/integration/Desktop57/JobziooLogo.svg" alt="">
            </div>
            <span class="footer">  {{'COMMUN.PARAGRAPHE' | translate}} </span> 
       
        </div>
      </div>
   </div>

   <div fxFlex="70%"    fxFlex.md="70%" fxFlex.sm="100%" fxFlex.xs="100%" fxFlexAlign="center center">
    <div class="forms"   >
      <div class="details-form"  fxLayout="column" >
        <span class="login-form-title mb-36">   
          {{'COMMUN.LOGIN' | translate}}
        </span>
        <div class="log-title-sm mb-36 text-center">
           <img src="./assets/integration/Desktop01/logo-sm.svg" alt="" srcset="">
           <span>  {{'COMMUN.MY_JOBZIOO' | translate}}</span>
        </div>
        <form   fxLayout="row wrap"  fxLayout="column"fxFlex="100%"  [formGroup]="loginForm" class="filter last login-form "fxFlexAlign="center center" >
          
            <mat-form-field appearance="outline" fxFlex="50%" fxFlex.md="50%" fxFlex.sm="100%" fxFlex.xs="100%"    >    
                <input  formControlName="email" [type]="'text'" [placeholder]=" 'COMMUN.MAIL' | translate" matInput  >
                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                  {{'EMPTY.EMAIL' | translate}}
              </mat-error>
              <mat-error
              *ngIf="!loginForm.get('email').hasError('required') &&
                      loginForm.get('email').hasError('email')">
               {{'EMPTY.VALIDMAIL' | translate}}
          </mat-error>
  
            </mat-form-field>

              <mat-form-field fxFlex="50%" fxFlex.md="50%" fxFlex.sm="100%" fxFlex.xs="100%"  appearance="outline"   >    
                <input formControlName="password"  [type]="hide ? 'password' : 'text'" [placeholder]=" 'COMMUN.PASSWORD' | translate"   matInput  >
                <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button> -->
                <mat-error *ngIf="loginForm.get('password').hasError('required')">
                  {{'EMPTY.PASSWORD' | translate}}
              </mat-error>
    
              </mat-form-field>
    
              <button  mat-raised-button  [disabled]="loginForm.invalid" class="btn-mat bnt-mail " type="submit" (click)="login()">
                {{'COMMUN.LOGIN' | translate}}
              </button>
          
              <a routerLink="/forget-password" class="link my-16"> {{'COMMUN.FORGET_PASSWORD' | translate}}</a>


          </form>
    </div>
    </div>
  
  </div>
  </div>
    <!-- <div class="container-login" fxLayout="row wrap" fxFlex="100%" >
      <div class="login-more" fxFlex="30%" fxFlex.md="30%" fxFlex.sm="30%"  fxHide.xs  style="background-image: url('./assets/integration/Desktop57/business.png');">
         <div class="details">
           <div class="logo">
             <img src="./assets/integration/Desktop57/JobziooLogo.svg" alt="">
           </div>
           <span class="footer">  {{'COMMUN.PARAGRAPHE' | translate}} </span> 
         </div>
      </div>
  
      <div class="wrap-login text-center" fxFlex="70%" fxFlex.md="70%"   >
  
        <form [formGroup]="loginForm" class="filter last login-form " fxLayout="column" >
          <span class="login-form-title mb-36">   
            {{'COMMUN.LOGIN' | translate}}
          </span>
  
          <div fxFlex="100%"  class="mb-36" >
            <mat-form-field appearance="outline"  >    
                <input  formControlName="email" [type]="'text'" [placeholder]=" 'COMMUN.MAIL' | translate" matInput  >
                <mat-error *ngIf="loginForm.get('email').hasError('required')">
                  {{'EMPTY.EMAIL' | translate}}
              </mat-error>
              <mat-error
              *ngIf="!loginForm.get('email').hasError('required') &&
                      loginForm.get('email').hasError('email')">
               {{'EMPTY.VALIDMAIL' | translate}}
          </mat-error>
  
            </mat-form-field>
        </div>
      
        <div fxFlex="100%"  class="mb-36"  >
          <mat-form-field appearance="outline"  >
              <input formControlName="password"  [type]="hide ? 'password' : 'text'" [placeholder]=" 'COMMUN.PASSWORD' | translate"   matInput  >
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                {{'EMPTY.PASSWORD' | translate}}
            </mat-error>
          </mat-form-field>
      </div>
    
       
          <div class="container-login-form-btn">
            <button mat-raised-button  [disabled]="loginForm.invalid" class="btn-mat bnt-mail mr-12" type="submit" (click)="login()">
              {{'COMMUN.LOGIN' | translate}}
            </button>
          </div>
        </form>
      </div>
    </div> -->
  </div>