import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from 'app/features/integration/settingIntegration/service/settings.service';
import * as moment from 'moment';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopSettingsComponent } from '../popSettings/popSettings.component';

@Component({
  selector: 'app-card-terms',
  templateUrl: './card-terms.component.html',
  styleUrls: ['./card-terms.component.scss']
})
export class CardTermsComponent implements OnInit  {
  @Input() terms ;
  @Output() changed = new EventEmitter<string>();
  @Input() servicee=''
  constructor(private _matDialog: MatDialog,private settingService :SettingsService) { }

  ngOnInit() {
  }



  returnDomain(domain) {
    return domain.substring(0, 40);
   } 

   returnDate(date) {    
    return  moment(date).format('DD/MM/YYYY'); 
  }




}
