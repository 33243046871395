import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobOffersService } from 'app/features/integration/jobOffreIntegration/services/jobOffers.service';
import { AlertifyService } from 'app/_services/alertify.service';
import * as moment from 'moment';
import { PopApiComponent } from '../popApi/popApi.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-jobOffre',
  templateUrl: './jobOffre.component.html',
  styleUrls: ['./jobOffre.component.scss']
})
export class JobOffreComponent implements OnInit , OnChanges {
  @Output() changed = new EventEmitter<string>();
  @Input() jobOffre;
  @Input() role='';
  @Input() from

  copyJobOffre
  @Input()  from_partner = false ;

  constructor(private _matDialog: MatDialog, private alertify: AlertifyService,private jobOffreService:JobOffersService ,
     private router:Router ,     ) { }

  ngOnInit() {

  }

ngOnChanges(changes: SimpleChanges): void {
  //console.log(this.role);
  
}
  returnDo(domain) {
    if(domain){
      return domain.substring(0, 10);
    }
  }
  
  returnD(partner) {
    return partner.substring(0, 9);

  }
  returnDomain(domain?) {
    if(domain){
      return domain.substring(0, 20);
    }
     
    
   }
   
  onImgError(event){
    event.target.src = './assets/icons/jobzioo-icons/no-picture.svg';
  }

  returnDate(date) {    
    return  moment(date).format('MMMM Do YYYY'); 
   }
  
   copy(jobOffre ){
      this.jobOffreService.copyJobOffers(jobOffre.code).subscribe(res => {
        if(res.status === 'success') {
        //  this.alertify.success('copy done')
        this._matDialog.open(PopApiComponent ,{
          data: {
           mode:'active',
           msg: 'EMPTY.DONE' ,
           image :"./assets/icons/jobzioo-icons/check.svg"
         }  
        })
        
          this.copyJobOffre = res.results.data.code
          if(this.copyJobOffre) {
            this.router.navigate([`/joboffers/datasheet/${this.copyJobOffre}/read`]);
          }
          
        }
      })
   }

   makeExpired(jobOffre) {
    const dialogRef = this._matDialog.open(StateDialogComponent, { 
      data:  {mode:"active_expired" ,type:'expired' , description:'' , item : jobOffre ,  msg:'COMMUN.SURE_EXPIRED_JOB'  , image :"./assets/integration/Desktop36/time.png"  }  ,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.jobOffreService.makeExpiredJobOffre(jobOffre.code).subscribe(res => {
          if(res.status === 'success') {
               // this.alertify.success('success') ;
               this._matDialog.open(PopApiComponent ,{
                data: {
                 mode:'active',
                 msg: 'EMPTY.DONE' ,
                 image :"./assets/icons/jobzioo-icons/check.svg"
               }  
              })
                this.changed.emit('expired')
            }
        })
      }
      })
   }
 
   cancelJob(jobOffre) {
    const dialogRef = this._matDialog.open(StateDialogComponent, { 
      data:  {mode:"active" ,type:'jobOffree' , description:'' , item : jobOffre ,  msg:'COMMUN.SURE_CANCEL_JOB'  , image :"./assets/icons/jobzioo-icons/stop.svg"  }  ,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.jobOffreService.cancelJobOffre(jobOffre.code).subscribe(res => {
          if(res.status === 'success') {
              //  this.alertify.success('success') ;
              this._matDialog.open(PopApiComponent ,{
                data: {
                 mode:'active',
                 msg: 'EMPTY.DONE' ,
                 image :"./assets/icons/jobzioo-icons/check.svg"
               }  
              })
                this.changed.emit('cancel')
            }
        })
      }
      })
   }

   unpublishJob(jobOffre) {
    const dialogRef = this._matDialog.open(StateDialogComponent, { 
      data:  {mode:"active" ,type:'unpublish' , commentary:'' , item : jobOffre ,   msg:'COMMUN.SUR_UNPUBLISH'  , image :"./assets/integration/Desktop36/unpublish.png"  }  ,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        const commentary = { commentary : result.commentary }
        this.jobOffreService.unpublishJobOffre(jobOffre.code , commentary).subscribe(res => {
          if(res.status === 'success') {
               // this.alertify.success('success') ;
               this._matDialog.open(PopApiComponent ,{
                data: {
                 mode:'active',
                 msg: 'EMPTY.DONE' ,
                 image :"./assets/icons/jobzioo-icons/check.svg"
               }  
              })
                this.changed.emit('unpublish')
            }
        })
      }
      })
   }
   publishJob(job)  {

    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data: {mode:"publish" ,type:'jobOffre' , description:'' , item : job ,  msg:'COMMUN.SUR_PUBLISH'  , image :"./assets/integration/Desktop36/send.png"  }

      });
  
      dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.jobOffreService.publishJobOffre(job.code).subscribe(res => {
              if(res.status === 'success') {
                  //  this.alertify.success('success') ;
                  this._matDialog.open(PopApiComponent ,{
                    data: {
                     mode:'active',
                     msg: 'EMPTY.DONE' ,
                     image :"./assets/icons/jobzioo-icons/check.svg"
                   }  
                  })
                    this.changed.emit('done')
                }
            })
          }
        })
        

}




}
