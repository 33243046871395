<div class="card job my-8 p-24" fxLayout="row">
  <div class="logo mr-20">
    <div class="name flex-sm-sm">
      <img [src]="user?.logo" (error)="onImgError($event)" alt="">
    </div>
  </div>
  <div class="details " fxLayout="column">
    <span class="fullName ">
      <div class="text-bold">{{user?.joalias}} </div>
      {{user?.jobtitle}}
    </span>
    <span class="creator"> <span class="text-bold"> {{'JOBOFFERS.CREATEDBY' | translate}} : </span> {{
      returnDomain(user.partner)}}... </span>

    <span class="creator"> <span class="text-bold">{{'COMMUN.DOMAINE' | translate}} : </span> {{user.domain_label}}
    </span>

    <span class="line"> <span class="creator"> {{'COMMUN.JOBTYPE' | translate}} : </span> {{user.jobtype_label}}
    </span>


  </div>
  <div class="btn-actions  " fxLayoutAlign=" end end" fxLayout="column">

    <a *ngIf="user.code" [routerLink]="['/joboffers/datasheet', user.code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" title="{{'ICON.VIEW_JO' | translate}}" class="cursor-pointer" alt=""
        class=" cursor-pointer  mb-8"> </a>
    <a *ngIf="user.jo_code" [routerLink]="['/joboffers/datasheet', user.jo_code , 'read']"><img
        src="./assets/icons/jobzioo-icons/eye.svg" title="{{'ICON.VIEW_JO' | translate}}" class=" cursor-pointer  mb-8"
        alt=""> </a>


    <a *ngIf=" (role !== 'LOCAL_ASSISTANCE' &&   role !== 'CANDIDATE_RECRUITER' )  && ( user?.status ==='PENDING' ||  user?.status ==='DRAFT' ||  user?.status ==='UNPUBLISHED' )"
      [routerLink]="['/joboffers/manage', user.code , 'edit']"><img src="./assets/icons/jobzioo-icons/edit.png"
        title="{{'ICON.EDIT_JO' | translate}}" class=" cursor-pointer  mb-8" alt=""> </a>


    <a *ngIf="  (role !== 'LOCAL_ASSISTANCE' &&   role !== 'CANDIDATE_RECRUITER' )    && (user?.status ==='DRAFT' ||  user?.status ==='PENDING' )  "
      (click)="cancelJob(user)"> <img src="./assets/icons/jobzioo-icons/sortie.svg"
        title="{{'ICON.CANCEL_JO' | translate}}" class="  cursor-pointer  mb-8" alt=""> </a>

    <a (click)="copy(user )"> <img
        *ngIf=" user?.status !=='PENDING'  &&  role !== 'LOCAL_ASSISTANCE'  &&  role !== 'CANDIDATE_RECRUITER'"
        class="cursor-pointer  mb-8" src="./assets/icons/jobzioo-icons/copie.svg"
        title="{{'ICON.COPY_JO' | translate}}"> </a>



    <a *ngIf=" user?.status ==='PENDING' &&  role !== 'LOCAL_ASSISTANCE' &&  role !== 'CANDIDATE_RECRUITER'"
      (click)="unpublishJob(user)"> <img class="  cursor-pointer iconn publish   mb-8"
        src="./assets/integration/Desktop36/publish.svg" title="{{'ICON.PUBLISH_JO' | translate}}"></a>


    <a *ngIf=" user?.status ==='PUBLISHED'&&  role !== 'LOCAL_ASSISTANCE'&&  role !== 'CANDIDATE_RECRUITER' "
      (click)="makeExpired(user)"> <img src="./assets/integration/Desktop36/time.svg"
        class=" iconn expried cursor-pointer  mb-8" title="{{'ICON.EXPIRE_JO' | translate}}"></a>


  </div>

</div>