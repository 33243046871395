import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobOffer } from 'app/features/integration/jobOffreIntegration/models/jobOffer.model';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';


@Component({
  selector: 'app-proposeCandidate',
  templateUrl: './proposeCandidate.component.html',
  styleUrls: ['./proposeCandidate.component.scss']
})
export class ProposeCandidateComponent implements OnInit {

  value1 = '';


  constructor(
    public dialogRef: MatDialogRef<ProposeCandidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
    action:string ,
    item: JobOffer ,
    candidate :'', 
    type:'',
    //mode:mode ,
    listCandidates:Select2Data ,
    dataPreparing ,
    codePrep
  } ,
  ) {}

  ngOnInit(): void {
    
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


      open(key: string, event: Event) {
    }

    close(key: string, event: Event) {
    }

    focus(key: string, event: Event) {
    }

    blur(key: string, event: Event) {
    }

    change(key: string, event: Event) {
    }

    search(key: string, event: Event) {
        //console.log(key, event);
    }

    update(key: string, event: Select2UpdateEvent<any>) {
     // //console.log(event.value);
     //console.log(event);
     
      this[key] = event?.value;
      this.data.candidate = this[key] 
      this.data.codePrep = this[key] 

  }



}
