import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JobOffersService } from 'app/features/integration/jobOffreIntegration/services/jobOffers.service';
import { PopApiComponent } from '../popApi/popApi.component';
import { PopVedioComponent } from '../popVedio/popVedio.component';
import { StateDialogComponent } from '../stateDialog/stateDialog.component';

@Component({
  selector: 'app-card-candid',
  templateUrl: './card-candid.component.html',
  styleUrls: ['./card-candid.component.scss']
})
export class CardCandidComponent implements OnInit  {
  @Input() user ;
  @Output() changed = new EventEmitter<string>();
  @Input() candidateStatus = '';


  constructor( private _matDialog: MatDialog,    private jobOffreService:JobOffersService,
    ) { }

  ngOnInit() {
  }


  onImgError(event){
    event.target.src = './assets/icons/jobzioo-icons/no-picture.svg';
  }


     showPresent(url) {
          const dialogRef = this._matDialog.open(PopVedioComponent, { 
            data: {mode:"active" ,type:'candidate' , presentation:url } ,
          });
        }



  cancelPropose(code) {
    const dialogRef = this._matDialog.open(StateDialogComponent, {
      data:{mode:"active" , type:"partner" ,  description :'' ,  msg:'COMMUN.SURE_CANCEL_PROPOSE' , image :"./assets/icons/jobzioo-icons/stop.svg"  }  ,

    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
       this.jobOffreService.cancelProposed(code , result.description ).subscribe(res => {
         if(res.status === 'success') {
        //   this.alertify.success('done') ;
        this._matDialog.open(PopApiComponent ,{
          data: {
           mode:'active',
           msg: 'EMPTY.DONE' ,
           image :"./assets/icons/jobzioo-icons/check.svg"
         }  
        })
          
           this.changed.emit('cancel');
         }
       })
        
      }
    })
  }
}
